<script setup lang="ts">
import { ref, onUnmounted, onMounted } from 'vue';
import resourceHelper from '@/Utils/resourceHelper';
import KeyboardTrap from '@/VueComponents/Modals/keyboardTrap';

defineProps<{
    title: string,
    isDanger?: boolean,
    isWide?: boolean
}>();
const emit = defineEmits<{(e: 'close'): void}>();

const root = ref<HTMLElement | null>(null);
let keyboardTrap: KeyboardTrap | null = null;
const modalBodyId: string = 'modalBodyId';
const modalTitleId: string = 'modalTitleId';
const closeTitle: string = resourceHelper.getString('Close');

function close() {
  emit('close');
}

onUnmounted(() => {
  document.body.classList.remove('modal-opened');
  keyboardTrap?.dispose();
});

onMounted(() => {
  document.body.classList.add('modal-opened');
  if (root.value) {
    keyboardTrap = new KeyboardTrap(root.value);
  }
});
</script>

<template>
  <!-- eslint-disable vue/require-toggle-inside-transition -- TODO: Review this usage of transition -->
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      class="modal-mask"
    >
      <div
        ref="root"
        class="modal show"
        role="dialog"
        :aria-labelledby="modalTitleId"
        :aria-describedby="modalBodyId"
      >
        <div
          :class="isWide ? 'modal-dialog modal-lg' : 'modal-dialog'"
        >
          <div class="modal-content">
            <div
              :id="modalTitleId"
              :class="isDanger ? 'modal-header-danger' : 'modal-header'"
            >
              <slot name="header">
                <button
                  class="close"
                  :aria-label="closeTitle"
                  :title="$localize('Close')"
                  @click="close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 class="modal-title">
                  {{ title }}
                </h3>
              </slot>
            </div>
            <div
              :id="modalBodyId"
              class="modal-body"
            >
              <slot>No modal body content. Default slot has not been used.</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button
                  class="btn btn-default"
                  @click="close"
                >
                  {{ closeTitle }}
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <!-- eslint-enable vue/require-toggle-inside-transition -->
</template>

<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 12;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s ease;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>