<template>
  <div
    class="results-status text-center"
  >
    <img
      src="Content/images/preloaderSmall1.gif"
      width="32"
      height="32"
      class="loader-image"
      alt="Loading animation"
    >
    &nbsp; <span>{{ $localize('LoadingPleaseWait') }}</span>
  </div>
</template>
