<script setup lang="ts">
import { onMounted } from 'vue';
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
import resourceHelper from '@/Utils/resourceHelper';
import { UploadOption } from '@/Types/Enums/uploadOption';
import FileSizeFormatter from '@/Utils/fileSizeFormatter';
import { UploadContentType } from '@/Types/Enums/uploadContentType';

const props = defineProps<{
    uploadOption: string,
    fileList: File[],
    foldersCount: number | undefined
}>();

onMounted(() => {
  radioCheck = UploadContentType.generalContent;
});

const emit = defineEmits<{ 'cancel', 'upload' }>();
const filesToRender: File[] = props.fileList;
const selectedFileFolder = (props.uploadOption === UploadOption.selectFolder || props.uploadOption === UploadOption.selectFiles);
const selectedZip = props.uploadOption === UploadOption.selectZipFiles;
let uploadModalTitle = '';
let typeOfZipContent = '';
let generalContentZipDescription = '';
let deviceBackupDescription = '';
let cameraSystemExportDescription = '';
let generalContentDescription = '';
let typeOfFolderContent = '';
let radioCheck = UploadContentType.generalContent;

if (selectedFileFolder) {
  if (props.foldersCount && props.foldersCount > 1) {
    uploadModalTitle = resourceHelper.getString('UploadFoldersTitle', { foldersCount: props.foldersCount, filesCount: props.fileList.length });
    typeOfFolderContent = resourceHelper.getString('TypeOfFoldersContent');
    generalContentDescription = resourceHelper.getString('GeneralContentFoldersDescription');
    deviceBackupDescription = resourceHelper.getString('DeviceBackupsDescription');
    cameraSystemExportDescription = resourceHelper.getString('CameraSystemExportsDescription');
  } else {
    uploadModalTitle = resourceHelper.getString('UploadFolderTitle', { count: props.fileList.length });
    typeOfFolderContent = resourceHelper.getString('TypeOfFolderContent');
    generalContentDescription = resourceHelper.getString('GeneralContentDescription');
    deviceBackupDescription = resourceHelper.getString('DeviceBackupDescription');
    cameraSystemExportDescription = resourceHelper.getString('CameraSystemExportDescription');
  }
} else {
  if (props.fileList.length > 1) {
    uploadModalTitle = resourceHelper.getString('UploadZipFilesTitle', { filesCount: props.fileList.length, size: formatFileSize(totalFileSize()) });
    typeOfZipContent = resourceHelper.getString('TypeOfZipFilesContent');
    generalContentZipDescription = resourceHelper.getString('GeneralContentZipFilesDescription');
    deviceBackupDescription = resourceHelper.getString('DeviceBackupsDescription');
    cameraSystemExportDescription = resourceHelper.getString('CameraSystemExportsDescription');
  } else {
    uploadModalTitle = resourceHelper.getString('UploadZipFileTitle', { size: formatFileSize(totalFileSize()) });
    typeOfZipContent = resourceHelper.getString('TypeOfZipContent');
    generalContentZipDescription = resourceHelper.getString('GeneralContentZipDescription');
    deviceBackupDescription = resourceHelper.getString('DeviceBackupDescription');
    cameraSystemExportDescription = resourceHelper.getString('CameraSystemExportDescription');
  }
}

function onModalClose() {
  emit('cancel');
}

function onModalUpload() {
  emit('upload', radioCheck);
}

function totalFileSize() {
  let fileSize = 0;
  if (filesToRender !== null) {
    Object.values(filesToRender).forEach(file => {
      fileSize += file.size;
    });
  }
  return fileSize;
}

function formatFileSize(bytes: number): string {
  return FileSizeFormatter.getFormattedFileSize(bytes);
}

</script>

<template>
  <ModalDialog
    :title="uploadModalTitle"
    @close="onModalClose"
  >
    <template #default>
      <p v-if="selectedFileFolder">
        {{ typeOfFolderContent }}
      </p>
      <p v-if="selectedZip">
        {{ typeOfZipContent }}
      </p>
      <input
        id="generalContent"
        v-model="radioCheck"
        type="radio"
        name="ContentType"
        value="GeneralContent"
      > {{ $localize('GeneralContent') }}
      <br>
      <span
        v-if="selectedFileFolder"
        for="generalContent"
        class="contentDescription"
      >  {{ generalContentDescription }} </span>
      <span
        v-if="selectedZip"
        for="generalContent"
        class="contentDescription"
      >  {{ generalContentZipDescription }} </span>
      <br>
      <br>
      <input
        id="cctv"
        v-model="radioCheck"
        type="radio"
        name="ContentType"
        value="Cctv"
      > {{ $localize('CameraSystemExport') }}
      <br>
      <span
        for="cctv"
        class="contentDescription"
      > {{ cameraSystemExportDescription }} </span>
      <br>
      <br>
      <input
        id="deviceBackup"
        v-model="radioCheck"
        type="radio"
        name="ContentType"
        value="DeviceBackup"
      > {{ $localize('DeviceBackup') }}
      <br>
      <span
        for="deviceBackup"
        class="contentDescription"
      > {{ deviceBackupDescription }} </span>
    </template>
    <template #footer>
      <button
        ref="closeBtn"
        class="btn btn-default"
        @click="onModalClose"
      >
        {{ $localize('Cancel') }}
      </button>
      <button
        ref="uploadBtn"
        class="btn btn-primary"
        @click="onModalUpload"
      >
        {{ $localize('Upload') }}
      </button>
    </template>
  </ModalDialog>
</template>

<style lang="scss" scoped>
    .contentDescription {
        margin-left: 17px;
    }
</style>