import ko from 'knockout';

// Base components
import BaseSortDropdown from '@/BaseComponents/baseSortDropdown/baseSortDropdown';

// Standard components
import AddressLookup from '@/Components/addressLookup/addressLookup';
import AssociatedFileList from '@/Components/associatedFileList/associatedFileList';
import FileUploadSelector from '@/Components/fileUploadSelector/fileUploadSelector';
import FileUploadsListItem from '@/Components/fileUploadsListItem/fileUploadsListItem';
import CctvFolderUploadsListItem from '@/Components/cctvFolderUploadsListItem/cctvFolderUploadsListItem';
import LeftMenu from '@/Components/leftMenu/leftMenu';
import TabMenu from '@/Components/tabMenu/tabMenu';
import MyAccountMenu from '@/Components/myAccountMenu/myAccountMenu';
import CameraList from '@/VueComponents/Cameras/CamerasList/CameraList.vue';
import ProgressBar from '@/Components/progressBar/progressBar';
import SelectOrTextInput from '@/Components/selectOrTextInput/selectOrTextInput';
import UploadsList from '@/Components/uploadsList/uploadsList';
import DynamicRequestDetails from '@/Components/dynamicRequestDetails/dynamicRequestDetails';
import RequestDetails from '@/Components/requestDetails/requestDetails';
import RequestHeader from '@/Components/requestHeader/requestHeader';
import BusinessUserDetails from '@/Components/businessUserDetails/businessUserDetails';
import CollapsedList from '@/Components/collapsedList/collapsedList';
import FilterPanel from '@/Components/filterPanel/filterPanel';
import CheckboxFilter from '@/Components/filterPanel/checkBoxFilter/checkboxFilter';
import TextFilter from '@/Components/filterPanel/textFilter/textFilter';
import CreateReportModal from '@/Components/createReportModal/createReportModal';
import CctvUploadConfirmationModal from '@/Components/cctvUploadConfirmationModal/cctvUploadConfirmationModal';
import VuePageWrapper from '@/PageComponents/Portal/vuePageWrapper/vuePageWrapper';

// Debug components
import ContextDataInfo from '@/DebugComponents/contextDataInfo/contextDataInfo';
import InactivityInfo from '@/DebugComponents/inactivityInfo/inactivityInfo';
import RouteInfo from '@/DebugComponents/routeInfo/routeInfo';
import ViewModelInfo from '@/DebugComponents/viewModelInfo/viewModelInfo';

// Page components
import Home from '@/PageComponents/Portal/home/home';
import LoadingData from '@/PageComponents/Portal/loadingData/loadingData';
import Misc from '@/PageComponents/Portal/misc/misc';

import MyAccount from '@/PageComponents/Portal/myAccount/myAccount';
import AccountDetails from '@/PageComponents/Portal/myAccount/accountDetails/accountDetails';
import ChangePassword from '@/PageComponents/Portal/myAccount/changePassword/changePassword';
import UserDetails from '@/PageComponents/Portal/myAccount/userDetails/userDetails';

import PageNotAvailable from '@/PageComponents/Portal/pageNotAvailable/pageNotAvailable';
import RequestFormSwitcher from '@/PageComponents/Portal/requestFormSwitcher/requestFormSwitcher';
import Users from '@/PageComponents/Portal/users/users';
import GroupDetails from '@/PageComponents/Portal/groupDetails/groupDetails';
import GroupUsersTab from '@/PageComponents/Portal/groupDetails/usersTab/usersTab';
import GroupLocationTab from '@/PageComponents/Portal/groupDetails/locationTab/locationTab';
import Groups from '@/PageComponents/Portal/groups/groups';

import AuthorisationError from '@/PageComponents/PortalError/authorisationError';
import BusinessDisabled from '@/PageComponents/PortalError/businessDisabled';
import UnknownErrorGettingUserData from '@/PageComponents/PortalError/unknownErrorGettingUserData';
import UnknownUser from '@/PageComponents/PortalError/unknownUser';

import Registration from '@/PageComponents/Registration/registration/registration';
import ResetPasswordNotAvailable from '@/PageComponents/ResetPassword/notAvailable/notAvailable';

// Site components
import HelpNavItem from '@/SiteComponents/helpNavItem/helpNavItem';
import LanguageNavItem from '@/SiteComponents/languageNavItem/languageNavItem';
import LanguageNavItemPortal from '@/SiteComponents/languageNavItemPortal/languageNavItemPortal';
import TopNav from '@/SiteComponents/topNav/topNav';
import UploadingNavItem from '@/SiteComponents/uploadingNavItem/uploadingNavItem';
import UserNavItem from '@/SiteComponents/userNavItem/userNavItem';
import SessionExpiredOverlay from '@/SiteComponents/sessionExpiredOverlay/sessionExpiredOverlay';
import HeaderAccountType from '@/SiteComponents/headerAccountType/headerAccountType';
import BusinessMap from '@/VueComponents/AccountDetails/BusinessMap.vue';
import GroupLocationMap from '@/VueComponents/Groups/GroupLocationMap.vue';
import VueMap from '@/VueComponents/SharedComponents/Map/VueMap.vue';

import ListOfDuplicatedUploadsModal from '@/VueComponents/Modals/ListOfDuplicatedUploadsModal';
import UploadLimitModal from '@/VueComponents/Modals/UploadLimitModal.vue';
import RequestUploads from '@/VueComponents/Uploads/RequestUploads.vue';
import RequestDisclaimer from '@/VueComponents/RequestDisclaimer/RequestDisclaimer.vue';
import GroupSaveWarningModal from '@/VueComponents/Modals/GroupSaveWarningModal.vue';
import DiscussionMessaging from '@/VueComponents/DiscussionMessaging/DiscussionMessaging';
import DisableElement from '@/VueCore/directives/disableElement';
import GroupFilterArea from '@/VueComponents/GroupFilterArea/GroupFilterArea.vue';
import UserFilterArea from '@/VueComponents/UserFilterArea/UserFilterArea.vue';
import primeVueTooltip from '@/VueCore/directives/primeVueTooltip';
import DualListBox from '@/VueComponents/DualListBox/DualListBox.vue';

export default class ComponentRegistration {

  static registerAllComponents() {
    ComponentRegistration.registerBaseComponents();
    ComponentRegistration.registerStandardComponents();
    ComponentRegistration.registerDebugComponents();
    ComponentRegistration.registerPageComponents();
    ComponentRegistration.registerSiteComponents();
    ComponentRegistration.registerVueComponents();
  }

  static registerVueComponents() {
    ko.vueComponents.register('duplicated-uploads-modal', ListOfDuplicatedUploadsModal);
    ko.vueComponents.register('upload-limit-modal', UploadLimitModal);
    ko.vueComponents.register('request-uploads', RequestUploads);
    ko.vueComponents.register('request-disclaimer', RequestDisclaimer);
    ko.vueComponents.register('group-save-warning-modal', GroupSaveWarningModal);
    ko.vueComponents.register('discussion-messaging', DiscussionMessaging);
    ko.vueComponents.register('camera-list', CameraList, {
      directives: [DisableElement]
    });
    ko.vueComponents.register('vue-business-map', BusinessMap);
    ko.vueComponents.register('vue-group-location-map', GroupLocationMap);
    ko.vueComponents.register('vue-map', VueMap);
    ko.vueComponents.register('group-filter-area', GroupFilterArea);
    ko.vueComponents.register('user-filter-area', UserFilterArea, {
      directives: [primeVueTooltip]
    });
    ko.vueComponents.register('dual-list-box', DualListBox, {
      directives: [primeVueTooltip]
    });
  }

  static registerBaseComponents() {
    ko.components.register('base-sort-dropdown', BaseSortDropdown);
  }

  static registerStandardComponents() {
    ko.components.register('address-lookup', AddressLookup);
    ko.components.register('associated-file-list', AssociatedFileList);
    ko.components.register('file-upload-selector', FileUploadSelector);
    ko.components.register('file-uploads-list-item', FileUploadsListItem);
    ko.components.register('cctv-folder-uploads-list-item', CctvFolderUploadsListItem);
    ko.components.register('left-menu', LeftMenu);
    ko.components.register('group-users-tab', GroupUsersTab);
    ko.components.register('group-location-tab', GroupLocationTab);
    ko.components.register('tab-menu', TabMenu);
    ko.components.register('my-account-menu', MyAccountMenu);
    ko.components.register('progress-bar', ProgressBar);
    ko.components.register('select-or-text-input', SelectOrTextInput);
    ko.components.register('uploads-list', UploadsList);
    ko.components.register('dynamic-request-details', DynamicRequestDetails);
    ko.components.register('request-details', RequestDetails);
    ko.components.register('request-header', RequestHeader);
    ko.components.register('business-user-details', BusinessUserDetails);
    ko.components.register('collapsed-list', CollapsedList);
    ko.components.register('filter-panel', FilterPanel);
    ko.components.register('checkbox-filter', CheckboxFilter);
    ko.components.register('text-filter', TextFilter);

    ko.components.register('create-report-modal', CreateReportModal);
    ko.components.register('cctv-upload-confirmation-modal', CctvUploadConfirmationModal);
  }

  static registerDebugComponents() {
    ko.components.register('context-data-info', ContextDataInfo);
    ko.components.register('inactivity-info', InactivityInfo);
    ko.components.register('route-info', RouteInfo);
    ko.components.register('view-model-info', ViewModelInfo);
  }

  static registerPageComponents() {
    ko.components.register('users', Users);
    ko.components.register('group-details', GroupDetails);
    ko.components.register('groups', Groups);
    ko.components.register('home', Home);
    ko.components.register('loading-data', LoadingData);
    ko.components.register('misc', Misc);
    ko.components.register('vue-page-wrapper', VuePageWrapper);

    ko.components.register('my-account', MyAccount);
    ko.components.register('account-details', AccountDetails);
    ko.components.register('change-password', ChangePassword);
    ko.components.register('user-details', UserDetails);

    // These components are displayed based on the "PortalStartupErrorType" enum value
    ko.components.register('authorisation-error', AuthorisationError);
    ko.components.register('business-disabled', BusinessDisabled);
    ko.components.register('unknown-error-getting-user-data', UnknownErrorGettingUserData);
    ko.components.register('unknown-user', UnknownUser);

    ko.components.register('page-not-available', PageNotAvailable);

    ko.components.register('request-form-switcher', RequestFormSwitcher);
    ko.components.register('registration', Registration);
    ko.components.register('reset-password-not-available', ResetPasswordNotAvailable);
  }

  static registerSiteComponents() {
    ko.components.register('help-nav-item', HelpNavItem);
    ko.components.register('language-nav-item', LanguageNavItem);
    ko.components.register('language-nav-item-portal', LanguageNavItemPortal);
    ko.components.register('top-nav', TopNav);
    ko.components.register('uploading-nav-item', UploadingNavItem);
    ko.components.register('user-nav-item', UserNavItem);
    ko.components.register('session-expired-overlay', SessionExpiredOverlay);
    ko.components.register('header-account-type', HeaderAccountType);
  }
}
