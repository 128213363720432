<template>
  <slot />
</template>
<script lang="ts" setup>
import mapStylingHelper from '@/Utils/mapStylingHelper';
import { inject, onUnmounted, Ref, toRaw } from 'vue';
import MapPointMarker from '@/VueComponents/SharedComponents/Map/Models/mapPointMarker';
import MapPopup from '@/VueComponents/SharedComponents/Map/Models/mapPopup';
import { MapInjectionKeys } from '@/VueComponents/SharedComponents/Map/Enums/mapInjectionKeys';

const props = defineProps<{
  id: string,
  markers: MapPointMarker[],
  zoomToMarkers: boolean
}>();

const map: Ref<any> | undefined = inject(MapInjectionKeys.map);
const popUp: Ref<MapPopup | undefined> | undefined = inject(MapInjectionKeys.popup);

if (!map) {
  throw new Error('Markers should be used within a VueMap component');
}

const unmountCallbacks: (() => void)[] = [];
onUnmounted(() => {
  for (const callback of unmountCallbacks) {
    callback();
  }
});

import(/* webpackChunkName: "open-layers" */ '@/Utils/openLayersDynamicModule') // Dynamically import large OpenLayers dependencies
    .then(olModule => {
      initMarkers(olModule, map.value, popUp?.value);
    });

function initMarkers(olModule: any, map: any, popUp: any) {
  const Feature = olModule.Feature;
  const Point = olModule.Point;
  const VectorLayer = olModule.VectorLayer;
  const VectorSource = olModule.VectorSource;
  const fromLonLat = olModule.fromLonLat;

  // If there are no items then exit as the array has not yet been populated
  if (props.markers.length === 0) {
    return;
  }

  const layer = createNewLayer(map);
  map.addLayer(layer);

  let markerFeature;
  for (let i = 0; i < props.markers.length; i++) {
    markerFeature = createMarkerFeature(props.markers[i], i);
    layer.getSource().addFeature(markerFeature);
  }

  if (props.zoomToMarkers) {
    const extent = layer.getSource().getExtent();
    map.getView().fit(extent, map.getSize(), { maxZoom: 19 }); // Set max zoom to ensure we don't zoom too far in
  }

  // If the map popup enabled
  if (popUp) {
    const singleClickEventKey = map.on('click', function (e) {

      let feature;
      let marker;

      map.forEachFeatureAtPixel(e.pixel, function (clickedFeature, clickedLayer) {
        if (toRaw(clickedLayer) === layer) {
          feature = clickedFeature;
        }
      });

      if (feature) {
        e.stopPropagation(); // Prevent the click event from being handled anywhere else

        marker = props.markers[feature.get('index')];
        popUp.show(feature, marker.markerHtmlPopupText);
      }
    });

    unmountCallbacks.push(function () {

      // Remove map click event listener
      map.on('click', singleClickEventKey);
    });
  }


  function createMarkerFeature(item, index) {

    const coordinate = item.coordinate;
    const markerLonLat = [coordinate.longitude, coordinate.latitude];
    const markerPoint = new Point(fromLonLat(markerLonLat));

    const markerFeature = new Feature(markerPoint);
    const markerStyle = mapStylingHelper.createPointMarkerStyle(item.label);
    markerFeature.setStyle(markerStyle);

    markerFeature.set('index', index);

    return markerFeature;
  }

  function createNewLayer(map) {

    const layers = map.getLayers();

    if (props.id) {
      // If the layer already exists then remove it
      for (let i = 0; i < layers.getLength(); i++) {
        if (layers.item(i).get('name') === props.id) {
          map.removeLayer(layers.item(i));
        }
      }
    }

    return new VectorLayer({
      source: new VectorSource(),
      name: props.id
    });
  }
}
</script>