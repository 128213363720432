import resourceHelper from '@/Utils/resourceHelper';
import localStorageHelper, { LocalStorageKeyType } from '@/Utils/localStorageHelper';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';

export default class UploadsTableConfiguration {
  tableFields: { name: string, label: string }[];
  sortOptions: { field: string, label: string }[];
  pageSize: number;
  parentType: UploadParentItemType;

  constructor(type: UploadParentItemType) {
    this.parentType = type;
    this.tableFields = [
      {
        name: 'select',
        label: ''
      },
      {
        name: 'fileName',
        label: resourceHelper.getString('FileName')
      },
      {
        name: 'status',
        label: resourceHelper.getString('Status')
      },
      {
        name: 'size',
        label: resourceHelper.getString('Size')
      },
      {
        name: 'dateAdded',
        label: resourceHelper.getString('DateCreated')
      },
      {
        name: 'addedBy',
        label: resourceHelper.getString('CreatedBy')
      },
      {
        name: 'path',
        label: resourceHelper.getString('Path')
      }
    ];
    this.sortOptions = [
      {
        field: 'addedTimestamp',
        label: resourceHelper.getString('DateCreated')
      },
      {
        field: 'fileName',
        label: resourceHelper.getString('FileName')
      },
      {
        field: 'size',
        label: resourceHelper.getString('Size')
      },
      {
        field: 'statusName',
        label: resourceHelper.getString('Status')
      },
      {
        field: 'userName',
        label: resourceHelper.getString('CreatedBy')
      },
      {
        field: 'path',
        label: resourceHelper.getString('Path')
      }
    ];

    this.pageSize = this.getPageSize();
  }

  getPageSize() {
    const defaultPageSize = 10;

    let pageSize;
    switch (this.parentType) {
      case UploadParentItemType.selfResponse:
        pageSize = localStorageHelper.getSessionValue(LocalStorageKeyType.PaginationUploadFolderUploadsPageSize);
        break;
      case UploadParentItemType.partnerRequest:
        pageSize = localStorageHelper.getSessionValue(LocalStorageKeyType.PaginationPartnerRequestUploadsPageSize);
        break;
    }

    return pageSize ? Number(pageSize) : defaultPageSize;
  }
}