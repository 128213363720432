<template>
  <div class="sort-by-row">
    <SortDropdown
      :sorting-options="sortingOptions"
      :default-value="sortingDefaultValue"
      :default-order="sortingDefaultOrder"
      @sort="onSort"
    />
  </div>
  <ItemsLoadingSpinner v-if="loading && items.length === 0" />
  <LoadingErrorDisclaimer v-else-if="displayLoadingError">
    {{ loadingErrorMessage }}
  </LoadingErrorDisclaimer>
  <LoadingResultStatus v-else-if="items.length === 0">
    {{ $localize('NoResultsFound') }}
  </LoadingResultStatus>
  <div
    v-else
    v-disable-element="loading && !!items.length /* Do not disable when ItemsLoadingSpinner is displayed */"
  >
    <table class="table table-investigate">
      <thead>
        <tr class="visually-hidden">
          <th
            v-for="(column, index) in columns"
            :key="index"
            class="sr-only"
          >
            {{ column.title }}
          </th>
          <th
            v-if="!!actions?.length"
            class="sr-only"
          />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in items"
          :key="index"
          :class="{ highlight: highlightedIndex === index }"
        >
          <td
            v-for="(column, colIndex) in columns"
            :key="colIndex"
            :class="column.cssClass"
          >
            <slot
              :name="column.name"
              :item="item"
            />
          </td>
          <td
            v-if="!!actions?.length"
          >
            <PaginatedListActionsPopup
              :actions="actions"
              :payload="{ item: item }"
              @action-clicked="actionClicked"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <TablePageNavigation
    v-if="items.length > 0"
    v-model="selectedPage"
    v-model:page-size="pageSize"
    :total-items="paginationTotalItems"
    :pages-link-range="2"
    :page-size-options="pageSizeOptions"
    @page-changed="onPageChanged"
    @page-size-changed="onPageSizeChanged"
  />
</template>
<script lang="ts" setup>
import PaginatedListColumn from '@/VueComponents/SharedComponents/PaginatedList/types/paginatedListColumn';
import PaginatedListActionsPopup from '@/VueComponents/SharedComponents/PaginatedList/PaginatedListActionsPopup.vue';
import PaginatedListAction from '@/VueComponents/SharedComponents/PaginatedList/types/PaginatedListAction';
import SortDropdown from '@/VueComponents/SharedComponents/SortDropdown/SortDropdown';
import TablePageNavigation from '@/VueComponents/PaginatedTable/TablePageNavigation/TablePageNavigation';
import ItemsLoadingSpinner from '@/VueComponents/Loading/ItemsLoadingSpinner.vue';
import LoadingErrorDisclaimer from '@/VueComponents/Loading/LoadingErrorDisclaimer.vue';
import LoadingResultStatus from '@/VueComponents/Loading/LoadingResultStatus.vue';

defineProps<{
  columns: PaginatedListColumn[],
  highlightedIndex: number,
  items: any[],
  actions?: PaginatedListAction[],
  sortingOptions: any[],
  sortingDefaultValue: any,
  sortingDefaultOrder: any,
  paginationTotalItems: number,
  loading: boolean,
  loadingErrorMessage: string,
  displayLoadingError: boolean,
  pageSizeOptions?: number[]
}>();

const selectedPage = defineModel<number>('selectedPage', { default: 1 });
const pageSize = defineModel<number>('pageSize', { default: 10 });

const emit = defineEmits(['actionClicked', 'onSort', 'pageChanged', 'pageSizeChanged']);

function actionClicked(eventName, payload) {
  emit('actionClicked', eventName, payload);
}

function onSort(data) {
  emit('onSort', data);
}

function onPageChanged() {
  emit('pageChanged', selectedPage.value);
}

function onPageSizeChanged() {
  emit('pageSizeChanged', pageSize.value);
}
</script>
<style>
.sort-by-row {
  padding-top: 6px;
  text-align: right;
}
</style>