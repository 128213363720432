<template>
  <div class="form-container">
    <h2>{{ $localize('AddCameraTitle') }}</h2>
    <CameraEditForm
      ref="editForm"
      v-model:camera-name="cameraModel.name"
      v-model:internal-name="cameraModel.internalName"
      v-model:camera-make="cameraModel.make"
      v-model:camera-model="cameraModel.model"
      v-model:camera-notes="cameraModel.notes"
      v-model:camera-coordinate="cameraModel.coordinate"
      :server-errors="serverErrors"
      :validation-rules="validationRules"
    />

    <div class="row">
      <div class="col-xs-12 text-right col-xs-margin-top">
        <VueButton
          :type="VueButtonTypes.primary"
          :loading="saving"
          @click="onAddCameraClicked"
        >
          {{ $localize('AddCamera') }}
        </VueButton>
      </div>
    </div>
  </div>

  <ModalDialog
    v-if="showModal"
    :title="$localize('CameraCoordinateCheckModalTitle')"
    @close="closeModalDialog"
  >
    <template #default>
      <div>{{ $localize('CameraCoordinateCheckModalMessage') }}</div>
      <br>
      <div>{{ $localize('CameraCoordinateCheckModalQuestion') }}</div>
    </template>
    <template #footer>
      <VueButton
        :type="VueButtonTypes.default"
        @click="closeModalDialog"
      >
        {{ $localize('Cancel') }}
      </VueButton>
      <VueButton
        :type="VueButtonTypes.primary"
        @click="addCamera"
      >
        {{ $localize('Yes') }}
      </VueButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import CameraEditForm from '@/VueComponents/Cameras/CameraDetails/CameraEditForm.vue';
import { reactive, ref } from 'vue';
import CameraEditFormValidationRules from '@/VueComponents/Cameras/Models/cameraEditFormValidationRules';
import contextData from '@/contextData';
import VueButton from '@/VueComponents/SharedComponents/Buttons/VueButton.vue';
import { VueButtonTypes } from '@/VueComponents/SharedComponents/Buttons/Enums/VueButtonTypes';
import cameraRepository from '@/Repositories/cameraRepository';
import logger from '@/Utils/logger';
import redirectHelper from '@/Utils/redirectHelper';
import localStorageHelper, { LocalStorageKeyType } from '@/Utils/localStorageHelper';
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';

const props = defineProps<{
  validationRules?: CameraEditFormValidationRules,
  lat?: number,
  long?: number
}>();

const business = contextData.userData.business;
const initialCoordinate = getInitialMapCoordinate();

const showModal = ref(false);

const cameraModel = reactive({
  name: '',
  internalName: '',
  make: '',
  model: '',
  notes: '',
  coordinate: initialCoordinate
});

const editForm = ref(null);
const saving = ref(false);
const serverErrors = ref([]);

async function onAddCameraClicked() {
  if (!(await editForm.value.isValid())) {
    return;
  }

  if ((!props.lat || !props.long) && !cameraCoordinateHasChanged(initialCoordinate, cameraModel.coordinate)) {
    showModalDialog();
    return;
  }

  addCamera();
}

function addCamera() {
  closeModalDialog();

  saving.value = true;
  serverErrors.value = [];

  const request = {
    businessId: business.businessId,
    cameraName: cameraModel.name,
    internalName: cameraModel.internalName,
    cameraMake: cameraModel.make,
    cameraModel: cameraModel.model,
    description: cameraModel.notes,
    coordinate: cameraModel.coordinate
  };

  cameraRepository.addNewCamera(request)
      .then(function () {

        logger.success('CameraAddedSummary');

        localStorageHelper.setSessionValue(LocalStorageKeyType.CamerasPage, String(1));

        redirectHelper.redirectToHash('#cameras');
      })
      .catch(function (jqXhr) {
        saving.value = false;

        if (jqXhr.serverErrorMessages) {
          serverErrors.value = jqXhr.serverErrorMessages;
          return;
        }

        if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnhandledError', 'An unexpected error occurred while attempting to add a new camera.', jqXhr);
        }
      });
}

function cameraCoordinateHasChanged(originalCameraCoordinate, cameraCoordinate) {

  if (originalCameraCoordinate.latitude !== cameraCoordinate.latitude) {
    return true;
  }

  if (originalCameraCoordinate.longitude !== cameraCoordinate.longitude) {
    return true;
  }

  return false;
}

function getInitialMapCoordinate() {
  if (props.lat != undefined && props.long != undefined) {
    return {
      longitude: props.long,
      latitude: props.lat
    };
  }

  if (business.coordinate) {
    return business.coordinate;
  }

  return contextData.portalSettings.defaultMapView.coordinate;
}

function showModalDialog() {
  showModal.value = true;
}

function closeModalDialog() {
  showModal.value = false;
}
</script>