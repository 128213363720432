import ko from 'knockout';
import { Observable } from 'knockout';
import addressLookupRepository from '@/Repositories/addressLookupRepository';
import logger from '@/Utils/logger';
import template from '@/PageComponents/Portal/groupDetails/locationTab/locationTab.html';
import ReactiveGroupMapProps, {
  GroupMapProps
} from '@/PageComponents/Portal/groupDetails/locationTab/models/groupMapProps';
import constants from '@/constants';
import { VueReactivePropsBuilder } from '@/VueCore/utils/vueAppBuilder';
import MapCoordinate from '@/VueComponents/SharedComponents/Map/Models/mapCoordinate';

const defaultZoomLevel = 17;

class LocationTabViewModel {
  private groupAddress: Observable<string>;
  private searching: Observable<boolean>;
  private isLocationAssigned: Observable<boolean>;
  private coordinate: Observable<MapCoordinate>;
  private mapCenterCoordinate: Observable<MapCoordinate>;
  private savedMapDetails: any;
  private selectedItemIdObservable: Observable<string>;
  private selectedTabItemObservable: Observable<string>;
  private tabItemSub: any;
  private tabItemIdSub: any;
  private vueMapProps: ReactiveGroupMapProps;

  constructor(params: any) {
    this.groupAddress = params.groupDetails.address;
    this.coordinate = params.groupDetails.coordinate;
    this.isLocationAssigned = params.groupDetails.isLocationAssigned;
    this.mapCenterCoordinate = params.initialCoordinate;
    this.searching = ko.observable(false);
    this.savedMapDetails = {};
    this.selectedItemIdObservable = params.selectedItemIdObservable;
    this.selectedTabItemObservable = params.selectedTabItemObservable;

    const initCord = params.initialCoordinate();
    const groupCord = params.groupDetails.coordinate();

    this.vueMapProps = new VueReactivePropsBuilder<GroupMapProps>()
      .withVModel('coordinate', initCord)
      .withVModel('groupCoordinate', groupCord)
      .withVModel('zoom', defaultZoomLevel)
      .on('cameraMove', newCoordinate => {
        this.coordinate(newCoordinate);
      })
      .build() as ReactiveGroupMapProps;

    this.coordinate.subscribe(c => {
      this.vueMapProps.groupCoordinate = c;
    });
    this.mapCenterCoordinate.subscribe(c => {
      this.vueMapProps.coordinate = c;
    });

    if (this.selectedTabItemObservable) {
      this.tabItemSub = this.selectedTabItemObservable.subscribe(newTab => {
        this.selectedTabChangedHandler(newTab);
      });
    }

    if (this.selectedItemIdObservable) {
      this.tabItemIdSub = this.selectedItemIdObservable.subscribe(() => {
        this.savedMapDetails = {};
      });
    }
  }

  selectedTabChangedHandler(tabItem: any): void {
    if (tabItem.name === constants.tabNames.locationTab) {
      this.restoreMapDetails();
    } else {
      this.saveMapDetails();
    }
    this.tabItemSub?.dispose();
    this.tabItemIdSub?.dispose();
  }

  updateMarkerFromAddress(): void {

    // lookup address
    if (!this.groupAddress() || !this.groupAddress().trim()) {
      return;
    }

    logger.debug('looking up address for \'%s\'', this.groupAddress());

    this.searching(true);

    addressLookupRepository.lookupAddress(this.groupAddress(), false) // orderByDistanceToCenter is false
      .then(addressSearchResults => {

        this.searching(false);

        logger.debug('received %d results', addressSearchResults.length);

        if (!addressSearchResults.length) {
          logger.info('AddressSearchNoResultsMessage');
          return;
        }

        this.coordinate(addressSearchResults[0].coordinate);
        this.mapCenterCoordinate(addressSearchResults[0].coordinate);

        this.vueMapProps.coordinate = addressSearchResults[0].coordinate;
        this.vueMapProps.groupCoordinate = addressSearchResults[0].coordinate;
        this.vueMapProps.zoom = defaultZoomLevel;

      })
      .catch(jqXhr => {

        this.searching(false);

        if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnexpectedErrorWhileRetrievingAddressLookup', null, jqXhr);
        }
      });
  }

  // saving/restoring user's map changes in case the checkbox is re-enabled
  checkboxClickHandler(): true {
    if (!this.isLocationAssigned()) {
      this.saveMapDetails();
    } else {
      this.restoreMapDetails();
    }
    return true;
  }

  saveMapDetails(): void {
    this.savedMapDetails = {
      coordinate: this.vueMapProps.groupCoordinate,
      groupAddress: this.groupAddress()
    };
    this.coordinate(this.savedMapDetails.coordinate);
    this.groupAddress(this.groupAddress());
    this.mapCenterCoordinate(this.savedMapDetails.coordinate);

    this.vueMapProps.coordinate = this.savedMapDetails.coordinate;
    this.vueMapProps.groupCoordinate = this.savedMapDetails.coordinate;
  }

  restoreMapDetails(): void {
    if (this.savedMapDetails.groupAddress) {
      this.coordinate(this.savedMapDetails.coordinate);
      this.groupAddress(this.savedMapDetails.groupAddress);
      this.mapCenterCoordinate(this.savedMapDetails.coordinate);

      this.vueMapProps.coordinate = this.savedMapDetails.coordinate;
      this.vueMapProps.groupCoordinate = this.savedMapDetails.coordinate;
    }
    this.savedMapDetails = {};
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: LocationTabViewModel, template: template };
