import ko from 'knockout';
import { GroupMemberDto } from '@/Models/groupManagement/groupMemberDto';
import AccountSpecificLabelsProvider from '@/Utils/accountSpecificLabelsProvider';
import { GroupDetailsDto } from '@/Models/groupManagement/groupDetailsDto';
import { VueReactivePropsBuilder } from '@/VueCore/utils/vueAppBuilder';

import template from './groups.html';

export function GroupsViewModel(routeParams) {

  const self = this;
  self.allGroups = ko.observableArray(routeParams.allGroups);
  self.totalCount = ko.pureComputed(() => self.allGroups().length);
  self.groupDetailsValidationRules = routeParams.validationRules;

  self.selectedItemId = ko.observable(null);
  self.isItemSelected = ko.pureComputed(() => self.selectedItemId() !== null);
  self.groupsTabInfoRowText = AccountSpecificLabelsProvider.getAccountLabelKey('GroupsTabInfoRowText');

  // Updates the group members and group details for an item
  self.updateItem = itemSummaryDto => {
    const items = self.allGroups();
    const updateIndex = items.findIndex(x => x.groupId === itemSummaryDto.groupId);
    let itemToUpdate = items[updateIndex];
    const newGroupMembers = itemSummaryDto.assignedGroupMembers
        .map(x => new GroupMemberDto(x.memberPersona.personaId, x.memberPersona.personaDisplayName, x.isGroupPrimaryContact));
    itemToUpdate.groupMembers(newGroupMembers);

    const newGroupDetails = createNewGroupDetails(itemSummaryDto);

    itemToUpdate = {
      ...itemToUpdate,
      groupDetails: newGroupDetails
    };

    items.splice(updateIndex, 1, itemToUpdate);
    self.allGroups(items);
  };

  // Is used for updating a group summary list when single group is removed
  self.deleteItem = itemId => {
    const items = self.allGroups();

    const deleteIndex = items.findIndex(x => x.groupId === itemId);
    items.splice(deleteIndex, 1);

    self.allGroups(items);
    self.selectedItemId(null);
  };

  self.groupFilterAreaProps = new VueReactivePropsBuilder()
      .withVModel('groups', self.allGroups())
      .withProp('selectedItemId', self.selectedItemId())
      .on('selectedItemIdUpdated', newValue => {
        self.selectedItemId(newValue);
      })
      .build();

  // Get updated groups in GroupFilterArea
  self.allGroups.subscribe(newValue => {
    self.groupFilterAreaProps.groups = [...newValue];
  });

  function createNewGroupDetails(itemSummaryDto) {
    return new GroupDetailsDto(
        itemSummaryDto.groupDetails.groupName,
        itemSummaryDto.groupDetails.address,
        itemSummaryDto.groupDetails.isLocationAssigned,
        itemSummaryDto.groupDetails.coordinate
    );
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: GroupsViewModel, template: template };
