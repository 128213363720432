import { ref, Ref } from 'vue';
import { FilterItemDto } from '@/Components/filterPanel/Models/filterItemDto';
import { FilterItemValueDto } from '@/Components/filterPanel/Models/filterItemValueDto';
import { FilterItemValue } from '@/VueComponents/Filter/Models/filterItemValue';
export class FilterItem {
  public name: string;
  public position: number;
  public title: string;
  public type: string;
  public values: FilterItemValue[];
  public isExpanded: Ref<boolean>;

  constructor(dto: FilterItemDto, isExpanded: boolean, filterValueChangeHandler: () => void) {
    this.name = dto.name;
    this.position = dto.position;
    this.title = dto.title;
    this.type = dto.type;
    this.values = this.mapToValues(dto.values, filterValueChangeHandler);
    this.isExpanded = ref(isExpanded);
  }

  public mergeValues = (
    existedValues: FilterItemValue[],
    receivedValues: FilterItemValueDto[],
    filterValueChangeHandler: () => void): void => {
    existedValues.forEach(existedValue => {
      existedValue.resetCounter();
    });
    const valuesToAdd = new Array<FilterItemValue>();
    receivedValues.forEach(receivedValue => {
      const existedValue = existedValues.find(value => {
        return receivedValue.value === value.value;
      });
      if (existedValue) {
        existedValue.setCounter(receivedValue.counter);
      } else {
        const newReceivedValue = new FilterItemValue(receivedValue, filterValueChangeHandler);
        valuesToAdd.push(newReceivedValue);
      }
    });

    const values = existedValues.concat(valuesToAdd);
    this.sort(values);
    this.values = values;
  };

  public syncCheckedValues = (valuesToSync: FilterItemValue[]): void => {
    valuesToSync.forEach(selectedValue => {
      const valueToUpdate = this.values.find(value => {
        return value.value === selectedValue.value;
      });
      if (valueToUpdate) {
        valueToUpdate.updateCheckedValueSilently(selectedValue.isChecked.value);
      }
    });
  };

  public syncTextValues = (valuesToSync: FilterItemValue[]): void => {
    valuesToSync.forEach(selectedValue => {
      const valueToUpdate = this.values.find(value => {
        return value.value !== selectedValue.value;
      });
      if (valueToUpdate) {
        valueToUpdate.updateTextValueSilently(valuesToSync[0].value);
      }
    });
  };

  public resetCheckedValues = (): void => {
    if (this.type && this.type == 'Text') {
      if (this.values.length > 0) {
        this.values[0].value = '';
        this.values[0].currentValue.value ='';
        this.values[0].initialValue.value = '';
      }
    } else {
      this.values.forEach(value => value.resetChecked());
    }
  };

  public toggleIsExpanded = (): void => {
    this.isExpanded.value = !this.isExpanded.value;
  };

  private mapToValues = (items: FilterItemValueDto[], filterValueChangeHandler: any): FilterItemValue[] => {
    const values = items.map((value: FilterItemValueDto): FilterItemValue => {
      return new FilterItemValue(value, filterValueChangeHandler);
    });
    this.sort(values);
    return values;
  };

  private sort = (values: FilterItemValue[]): void => {
    if (this.type === 'PositionedList') {
      values.sort(this.sortByPosition);
      return;
    }
    if (this.type === 'ListWithSortPriority') {
      values.sort(this.sortWithPositionPriority);
      return;
    }
    values.sort(this.sortByTitle);
  };

  private sortByPosition = (a: FilterItemValue, b: FilterItemValue): number => (a.position - b.position);

  private sortByTitle = (a: FilterItemValue, b: FilterItemValue): number => ((a.title < b.title) ?
    -1 :
    (a.title > b.title) ? 1 : 0);

  private sortWithPositionPriority = (a: FilterItemValue, b: FilterItemValue): number => {
    // values with negative positions go to the top of the list
    if (a.position < 0 && b.position < 0) {
      return a.position - b.position;
    }

    if (a.position < 0) {
      return -1;
    }

    if (b.position < 0) {
      return 1;
    }

    // values with >= 0 positions are sorted alphabetically
    return (a.title < b.title ?
      -1 :
      (a.title > b.title) ? 1 : 0);
  };
}