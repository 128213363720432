<template>
  <VueMap
    id="camerasMap"
    v-model:coordinate="coordinate"
    v-model:zoom="zoom"
    :pop-up-enabled="true"
    class="camera-map"
  >
    <VueMapPointMarkers
      id="camerasMarkers"
      :zoom-to-markers="true"
      :markers="markers"
    />
  </VueMap>
</template>
<script lang="ts" setup>
import VueMap from '@/VueComponents/SharedComponents/Map/VueMap.vue';
import MapCoordinate from '@/VueComponents/SharedComponents/Map/Models/mapCoordinate';
import MapCameraPoint from '@/VueComponents/Cameras/Models/mapCameraPoint';
import VueMapPointMarkers from '@/VueComponents/SharedComponents/Map/VueMapPointMarkers.vue';
import { computed } from 'vue';
import MapPointMarker from '@/VueComponents/SharedComponents/Map/Models/mapPointMarker';
import resourceHelper from '@/Utils/resourceHelper';

const props = defineProps<{
  cameras: MapCameraPoint[]
}>();
const coordinate = defineModel<MapCoordinate>('coordinate', { required: true });
const zoom = defineModel<number>('zoom', { required: true });

const markers = computed<MapPointMarker[]>(() => {
  return props.cameras.map(c => {
    return {
      label: c.name,
      coordinate: c.coordinate,
      markerHtmlPopupText: `<a href="#cameras/${c.id}"><strong>${c.name}</strong></a><br />` +
        `<div class="heading">${resourceHelper.getString('Added')}</div>${c.addedDateTime}`
    };
  });
});
</script>
<style scoped>
.camera-map {
  height: 370px;
}
</style>