import ko from 'knockout';
import router from '../../Utils/router';
import contextData from '../../contextData';
import template from './topNav.html';
import { AccountType } from '@/Types/Enums/accountType';
import PortalSettingsProvider from '@/Utils/portalSettingsProvider';

export function TopNavViewModel() {

  const self = this;

  self.currentRoute = router.currentRoute;
  self.inReleaseMode = contextData.inReleaseMode;
  self.isBusinessAdminUser = contextData.userData && contextData.userData.isBusinessAdminUser;
  self.isBusinessAccountType = PortalSettingsProvider.getAccountType() === AccountType.Business;
  self.isPartnerAccountType = PortalSettingsProvider.getAccountType() === AccountType.Partner;
  self.isSelfResponsesAvailable = PortalSettingsProvider.getSelfResponseEnabled();

  self.requestsItemActive = ko.computed(function () {
    return router.currentRoute().page.substring(0, 7) === 'request';
  });

  self.camerasItemActive = ko.computed(function () {
    return router.currentRoute().page.substring(0, 6) === 'camera';
  });

  self.groupsItemActive = ko.computed(function () {
    return router.currentRoute().page.substring(0, 5) === 'group';
  });

  self.usersItemActive = ko.computed(function () {
    const page = router.currentRoute().page;
    return page === 'users' || page === 'business-user-details';
  });

  self.uploadFoldersActive = ko.computed(function () {
    const page = router.currentRoute().page;
    return page === 'uploadfolders' || page === 'upload-folder-details';
  });
}

// The default export returns the component details object to register with KO
export default { viewModel: TopNavViewModel, template: template };


