import uploadRepository from '@/Repositories/uploadRepository';
import uploadManager from '@/Utils/uploadManager';
import template from './uploadingNavItem.html';

export function UploadingNavItemViewModel() {

  const self = this;

  // Observables
  self.uploads = uploadManager.uploads;
  self.uploadsCount = uploadManager.uploadsCount;
  self.allUploadsProgress = uploadManager.allUploadsProgress;
  self.allUploadsComplete = uploadManager.allUploadsComplete;

  // Public functions
  self.cancelFileUpload = function (upload) {

    uploadRepository.deleteUpload(upload);
    // TODO: Add error handling / logging here in case of failure
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: UploadingNavItemViewModel, template: template };
