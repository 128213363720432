<template>
  <div :class="['filter-area', filterAreaClass]">
    <div class="filter-area-list">
      <div>
        <Listbox
          v-model="selectedItemIdLocal"
          :tabindex="0"
          :options="entities"
          :option-label="itemLabel"
          :filter="true"
          :empty-message="noFilterResult"
          :empty-filter-message="noFilterResult"
        >
          <template #header>
            <label class="listbox-label">
              <strong>{{ labelText }}</strong>
            </label>
          </template>
          <template #option="slotProps">
            <slot
              name="option-content"
              :slot-props="slotProps"
            />
          </template>
        </Listbox>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import Listbox from 'primevue/listbox';
import resourceHelper from '@/Utils/resourceHelper';
import { FilterAreaType } from '@/VueComponents/SharedComponents/FilterArea/Enums/filterAreaType';

const props = defineProps<{
  entities: any,
  selectedItemId: string | null,
  itemType: string,
  filterAreaClass: string,
  itemLabel: any
}>();

const emit = defineEmits<{(e: 'selectedItemIdUpdated', selectedItemId: string | null): void}>();
const noFilterResult = resourceHelper.getString('NoFilterResult');
const labelText = props.itemType === FilterAreaType.user ?
  resourceHelper.getString('FilterUsersLabel') : resourceHelper.getString('FilterGroupsLabel');

const selectedItemIdLocal = ref(props.selectedItemId);

watch(selectedItemIdLocal, newVal => {
  emit('selectedItemIdUpdated', newVal);
});

watch(() => props.selectedItemId, newVal => {
  selectedItemIdLocal.value = newVal;
});
</script>

<style>
.listbox-label {
  padding-top: 1rem;
  padding-left: 1rem;
}
</style>