import template from './associatedFileList.html';
import associatedFileRepository from '../../Repositories/associatedFileRepository';

export function MediaFileListViewModel(params) {

  const self = this;

  self.isReadOnly = params.isReadOnly;
  self.associatedFiles = params.associatedFiles;

  self.downloadFile = associatedFile => {
    associatedFileRepository.downloadAssociatedFileById(associatedFile.id);
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: MediaFileListViewModel, template: template };
