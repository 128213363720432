import selfResponseSummaryRepository from '@/Repositories/selfResponseSummaryRepository';
import { FilterItemDto } from '@/Components/filterPanel/Models/filterItemDto';
import PageResultModel from '@/Models/pageResultModel';

let requestCounter = 0;

export function load(
  filterCriteria: FilterItemDto[],
  sortField: string,
  sortOrder: string,
  currentPage: number,
  pageSize: number,
  successCallback: (data: PageResultModel) => void,
  errorCallback: (jqXhr: any) => void) {
  requestCounter++;
  const currentRequestCounter = requestCounter;
  selfResponseSummaryRepository.getSelfResponseSummaries(filterCriteria, sortField, sortOrder, currentPage, pageSize)
    .then(data => {
      // If the data was requested again during the current request processing, ignore the current results and wait for the
      // latest request to be processed.
      if (currentRequestCounter !== requestCounter) {
        return;
      }

      successCallback(data);
    })
    .catch(jqXhr => {
      // If the data was requested again during the current request processing, ignore the current results and wait for the
      // latest request to be processed.
      if (currentRequestCounter !== requestCounter) {
        return;
      }

      errorCallback(jqXhr);
    });
}