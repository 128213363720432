<template>
  <div class="float-end text-small sort-control-container">
    <label :id="id + 'Label'">
      {{ $localize('SortBy') }}:
    </label>
    <select
      v-model="selectModel"
      class="form-control form-select form-select-sm"
      :aria-labelledby="id + 'Label'"
      @change="onValueChanged"
    >
      <option
        v-for="(option, index) in sortingOptions"
        :key="index"
        :value="option.field"
      >
        {{ option.label }}
      </option>
    </select>
    <button
      class="btn btn-sm btn-sort-order"
      :title="ascOrder ? $localize('Ascending') : $localize('Descending')"
      @click="switchOrder"
    >
      <span class="sr-only">{{ $localize('SortingOrder') }}</span>
      <i
        class="far"
        :class="{ 'fa-arrow-up-short-wide': ascOrder, 'fa-arrow-down-wide-short': !ascOrder }"
        aria-hidden="true"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

const props = defineProps({
  id: { type: String, default: 'sortDropDown' },
  sortingOptions: { type: Array, default: () => [] },
  defaultValue: { type: String, required: false, default: '' },
  defaultOrder: { type: String, required: false, default: 'Asc' }
});
const selectModel = ref('');
const ascOrder = ref(true);
const emit = defineEmits(['sort']);

onMounted(() => {
  if (props.defaultValue) {
    selectModel.value = props.defaultValue;
    ascOrder.value = props.defaultOrder === 'Asc';
  }
});

function onValueChanged(event: Event) {
  emitSortEvent((event.target as HTMLSelectElement).value, ascOrder.value);
}

function switchOrder() {
  ascOrder.value = !ascOrder.value;
  emitSortEvent(selectModel.value, ascOrder.value);
}

function emitSortEvent(field: string, isAscOrder: boolean) {
  emit('sort', {
    field: field,
    asc: isAscOrder
  });
}
</script>

<style scoped>
select {
  display: inline;
  width: fit-content;
  margin-left: 0.5em;
  font-weight: normal;
}
.btn-sort-order {
  margin-top: -4px;
}
.sort-control-container {
  padding-bottom: 5px;
}
</style>