import template from './vuePageWrapper.html';
import VueAppBuilder from '@/VueCore/utils/vueAppBuilder';
import PrimeVue from 'primevue/config';
import DisableElement from '@/VueCore/directives/disableElement';
import PrimeVueSettingsManager from '@/VueCore/utils/primeVueSettingsManager';
import { Field } from 'vee-validate';
import PrimeVueTooltip from '@/VueCore/directives/primeVueTooltip';

export function VuePageWrapperViewModel(routeParams) {

  let appBuilder: VueAppBuilder;

  PrimeVueSettingsManager.getSettings().then(settings => {
    appBuilder = new VueAppBuilder(routeParams.vuePage, routeParams)
      .use(PrimeVue, settings)
      .withDirective(DisableElement)
      .withComponent('vee-field', Field)
      .withDirective(PrimeVueTooltip);
    appBuilder.build('#vuePage');
  });

  this.dispose = () => {
    appBuilder.unmount();
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: VuePageWrapperViewModel, template: template };
