<template>
  <div class="row dual-listbox-filter-title">
    <div class="col-md-4 col-xs-12" />
    <FilterItems
      id="filterInput"
      v-model:filterCriteriaValue="filterCriteriaValue"
      :filter-data-models="itemsForFilter"
      :filtered-ids="filteredIds"
      label-text-key="GroupUserDuallistBoxFilterUserLists"
      :is-label-hidden="false"
      @filter-ids-updated="handleFilteredIdsUpdate"
    />
  </div>
  <PickList
    v-model="items"
    data-key="id"
    :show-source-controls="false"
    :show-target-controls="false"
    @update:model-value="onItemsUpdate"
  >
    <template #item="{ item }">
      <span :title="item.name">{{ item.name }}</span>
      <i
        v-if="item.iconDetails && !isItemInAssignedItemsList(item)"
        v-tooltip.top="{
          class: 'request-list__tooltip',
          value: $localize(item.iconDetails.titleKey),
          pt: {
            text: 'request-list__tooltip-text',
            arrow: 'request-list__tooltip-arrow'
          }
        }"
        :class="['fas pull-right', item.iconDetails.iconClass]"
        aria-hidden="true"
      />
      <div
        v-if="isItemInAssignedItemsList(item)"
        class="icons-container pull-right"
      >
        <i
          v-if="item.iconDetails"
          v-tooltip.top="{
            class: 'request-list__tooltip',
            value: $localize(item.iconDetails.titleKey),
            pt: {
              text: 'request-list__tooltip-text',
              arrow: 'request-list__tooltip-arrow'
            }
          }"
          :class="['fas', item.iconDetails.iconClass]"
          aria-hidden="true"
        />
        <i
          v-tooltip.top="{
            class: 'request-list__tooltip',
            value: $localize('GroupPrimaryContactCheckboxTooltipKey'),
            pt: {
              text: 'request-list__tooltip-text',
              arrow: 'request-list__tooltip-arrow'
            }
          }"
          class="far fa-envelope fa-fw icon-tooltip"
          aria-hidden="true"
        />
        <input
          type="checkbox"
          class="item-checkbox"
          :checked="item.isChecked()"
          @change="event => emit('isCheckedUpdated', item.id, event.target.checked)"
        >
      </div>
    </template>
  </PickList>
</template>

<script setup lang="ts">
import { watch, ref, computed } from 'vue';
import PickList from 'primevue/picklist';
import DualListBoxItem from '@/Components/dualListBox/types/dualListBoxItem';
import FilterItems from '@/VueComponents/SharedComponents/FilterItems/FilterItems.vue';

const availableItems = defineModel<DualListBoxItem[]>('availableItems', { required: true });
const assignedItems = defineModel<DualListBoxItem[]>('assignedItems', { required: true });

const filteredIds = ref<string[]>([]);
const filterCriteriaValue = ref<string>('');

const items = ref([availableItems.value, assignedItems.value]);

const itemsForFilter = computed(() => {
  return [...availableItems.value, ...assignedItems.value].map(item => ({
    id: item.id,
    isMultipleFilteringMode: false,
    primaryFilteredField: item.name,
    secondaryFilteredField: item.iconDetails?.titleKey
  }));
});

function handleFilteredIdsUpdate(newFilteredIds: string[], newFilterCriteriaValue: string) {
  filteredIds.value = newFilteredIds;
  // Return all items if filter criteria is null
  if (newFilterCriteriaValue === '' || newFilterCriteriaValue.length === 0) {
    items.value[0] = availableItems.value;
    items.value[1] = assignedItems.value;
  } else {
    // Update items with the new filtered IDs
    items.value[0] = filterItems(availableItems.value);
    items.value[1] = filterItems(assignedItems.value);
  }
}

// Filter items based on criteria
function filterItems(items: DualListBoxItem[]): DualListBoxItem[] {
  return items.filter(item => filteredIds.value.includes(item.id));
}

const emit = defineEmits<{(e: 'availableItemsUpdated', items: DualListBoxItem[]): void,
  (e: 'assignedItemsUpdated', items: DualListBoxItem[]): void,
  (e: 'isCheckedUpdated', itemId: string, isChecked: boolean): void
}>();

// Get values from ko component
watch(availableItems, newVal => {
  items.value[0] = newVal;
  items.value[0].sort(sortItems);
});
watch(assignedItems, newVal => {
  items.value[1] = newVal;
  items.value[1].sort(sortItems);
});

function onItemsUpdate(newValue: DualListBoxItem[][]) {
  const [filteredAvailableItems, filteredAssignedItems] = newValue;

  // Update availableItems by adding new filtered items and removing any that are moved to assignedItems
  const updatedAvailableItems = [
    ...availableItems.value.filter(item => !filteredAssignedItems.some(filteredItem => filteredItem.id === item.id)),
    ...filteredAvailableItems
  ];

  // Update assignedItems by adding new filtered items and removing any that are moved to availableItems
  const updatedAssignedItems = [
    ...assignedItems.value.filter(item => !filteredAvailableItems.some(filteredItem => filteredItem.id === item.id)),
    ...filteredAssignedItems
  ];

  // Remove duplicates
  const uniqueAvailableItems = mergeItems(updatedAvailableItems, filteredAvailableItems);
  const uniqueAssignedItems = mergeItems(updatedAssignedItems, filteredAssignedItems);

  emit('availableItemsUpdated', uniqueAvailableItems);
  emit('assignedItemsUpdated', uniqueAssignedItems);
}

// Helper function to merge arrays and remove duplicates
function mergeItems(originalItems: DualListBoxItem[], newItems: DualListBoxItem[]): DualListBoxItem[] {
  const combined = [...originalItems, ...newItems];
  const unique = combined.reduce((acc, item) => {
    if (!acc.some(existingItem => existingItem.id === item.id)) {
      acc.push(item);
    }
    return acc;
  }, [] as DualListBoxItem[]);
  return unique;
}

// Check if item is in assignedItems to show icons
function isItemInAssignedItemsList(item: DualListBoxItem) {
  return assignedItems.value.some(i => i.id === item.id);
}

// Sort items
const sortItems = (left: DualListBoxItem, right: DualListBoxItem): number => {
  return left.name === right.name ? 0 : (left.name < right.name ? -1 : 1);
};
</script>

<style scoped lang="scss">
.dual-listbox-filter-title {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.p-picklist-item {
  .icons-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3px;
  }

  .item-checkbox {
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
  }

  .icon-tooltip {
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
  }
}
</style>