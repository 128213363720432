<template>
  <InputFieldForm
    :id="id"
    :tooltip="tooltip"
    :label="label"
    :required="isFieldRequired(validationRules)"
    :validation-message="errors[0]"
  >
    <input
      :id="id"
      v-model="value"
      :aria-invalid="errors[0] ? errors[0] : undefined"
      type="text"
      class="form-control"
      :aria-describedby="validationSpanId"
      :required="isFieldRequired(validationRules)"
      v-bind="$attrs"
    >
  </InputFieldForm>
</template>

<script lang="ts" setup>
import InputFieldForm from '@/VueComponents/SharedComponents/InputFields/InputFieldForm.vue';
import InputFieldElementIdGenerator from '@/VueComponents/SharedComponents/InputFields/utils/inputFieldElementIdGenerator';
import { createValidator, DtoValidatorRule, isFieldRequired } from '@/Validation/dtoVeeValidator';
import { useField } from 'vee-validate';

const props = withDefaults(defineProps<{
  id: string,
  label?: string,
  tooltip?: string,
  validationRules?: DtoValidatorRule[]
}>(), { required: false, label: undefined, tooltip: undefined, validationRules: undefined });

if (!props.id) {
  console.error(`Unique 'id' should be provided.`);
}

const validationSpanId = InputFieldElementIdGenerator.getValidationMessageId(props.id);

const { errors, value, validate } = useField(props.id ?? '', createValidator(props.validationRules));

async function isValid() {
  const result = await validate();
  return result.valid;
}

defineExpose({
  isValid
});
</script>
