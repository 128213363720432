<template>
  <PaginatedList
    v-model:selected-page="selectedPage"
    v-model:page-size="pageSize"
    :columns="[
      { name: 'Name', title: resourceHelper.getString('CameraName') },
      { name: 'DateCreated', title: resourceHelper.getString('DateCreated') }]"
    :items="cameras"
    :highlighted-index="highlightedIndex"
    :loading="loading"
    :display-loading-error="loadingError"
    :loading-error-message="$localize('FailedToRetrieveCameras')"
  >
    <template #Name="opts">
      <PaginatedListLabeledField
        :label="$localize('CameraName')"
      >
        <PaginatedListLinkField
          :href="`#cameras/${opts.item.cameraId}`"
          :text="opts.item.cameraName"
          font-size="1em"
        />
      </PaginatedListLabeledField>
    </template>
    <template #DateCreated="opts">
      <PaginatedListLabeledField :label="$localize('DateCreated')">
        {{ moment(opts.item.addedDateTime).format('llll') }}
      </PaginatedListLabeledField>
    </template>
  </PaginatedList>
</template>
<script lang="ts" setup>
import resourceHelper from '@/Utils/resourceHelper';
import PaginatedList from '@/VueComponents/SharedComponents/PaginatedList/PaginatedList.vue';
import PaginatedListLabeledField from '@/VueComponents/SharedComponents/PaginatedList/PaginatedListLabeledField.vue';
import PaginatedListLinkField from '@/VueComponents/SharedComponents/PaginatedList/PaginatedListLinkField.vue';
import moment from 'moment';
import { computed } from 'vue';
import CameraSummary from '@/Types/cameraSummary';

const props = defineProps<{
  cameras: CameraSummary[],
  loading: boolean,
  loadingError: boolean
}>();

const selectedPage = defineModel('selectedPage', { type: Number, required: true });
const pageSize = defineModel('pageSize', { type: Number, required: true });

const highlightedIndex = computed(() => (props.cameras as any[]).findIndex(c => c.isRequestLastAccessed));
</script>