<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import ConfigurableField from '@/Types/configurableField';
import ValidationResultManager from '@/VueComponents/ConfigurableFields/validationResultManager';
import { Field } from 'vee-validate';

const props = defineProps<{
        configurableField: ConfigurableField,
        fieldValue: string,
        disabled: boolean,
        showMultiplePlaceholder: boolean
    }>();
const emit = defineEmits<{(e: 'updated', fieldId: string, value: string, isValid: boolean, invalidReason: string): void}>();

const multipleOption = 'multiple';
const options = props.configurableField.options?.map(option => option.value);
const internalFieldValue = ref(getInternalFieldValue(props.fieldValue));

watch(() => props.fieldValue, newValue => {
  internalFieldValue.value = getInternalFieldValue(newValue);
});

watch(() => props.showMultiplePlaceholder, newValue => {
  if (newValue) {
    internalFieldValue.value = multipleOption;
  } else {
    // Multiple option and empty option come from parent as empty value
    // but internal value differs. To cover case: when value changed
    // from multiple to empty
    if (internalFieldValue.value === multipleOption) {
      internalFieldValue.value = '';
    }
  }
});

const select = ref(null);
const list = ref(null);

async function validate(): Promise<Object> {
  return await list?.value?.validate();
}

async function emitUpdatedEvent() {
  const selectValue = select?.value?.value ?? '';
  const validationResult = await validate();
  const { isValid, error } = ValidationResultManager.parseValidationResult(validationResult);
  emit('updated', props.configurableField.id, selectValue, isValid, error);
}

// value should be one of list options, multiple or null
function getInternalFieldValue(val: string) {
  if (props.showMultiplePlaceholder) {
    return multipleOption;
  }
  return props.configurableField?.options?.map(option => option.value).includes(val) ? val : null;
}

onMounted(async () => {
  await validate();
});
</script>

<template>
  <Field
    ref="list"
    v-slot="{ errors }"
    v-model="internalFieldValue"
    :name="'label-' + configurableField.name"
    :rules="{ required: configurableField.isMandatory}"
    :validate-on-change="true"
    :validate-on-input="false"
    :validate-on-blur="true"
    :validate-on-model-update="true"
  >
    <div
      :class="{ 'has-error': errors.length > 0 && !showMultiplePlaceholder}"
    >
      <label
        :for="'list-' + configurableField.id"
        :class="{'required': configurableField.isMandatory}"
      >
        {{ configurableField.displayName }}:
      </label>
      <div>
        <select
          :id="'list-' + configurableField.id"
          ref="select"
          v-model="internalFieldValue"
          class="form-control form-select"
          :disabled="disabled"
          :aria-invalid="errors.length > 0 && !showMultiplePlaceholder"
          :aria-describedby="'errorText_'+configurableField.name"
          @change="emitUpdatedEvent"
        >
          <option
            v-if="showMultiplePlaceholder"
            :value="multipleOption"
            disabled
            hidden
          >
            {{ $localize('MultipleValue') }}
          </option>
          <option
            v-for="option in options"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <div class="help-block">
          <small :id="'errorText_'+configurableField.name">{{ showMultiplePlaceholder ? '' : errors[0] }}</small>
        </div>
      </div>
    </div>
  </Field>
</template>