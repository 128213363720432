<template>
  <div
    id="uploadfolderheader"
    class="request-header sticky"
  >
    <div class="request-header__item request-header__item--title">
      <h2 class="request-header__title">
        {{ response.referenceNumber }}
      </h2>
      <div>{{ status }}</div>
    </div>

    <div
      v-if="discussionMessagingAllowed"
      class="request-header__item request-header__item--secondary"
    >
      <div
        class="request-header__label"
      >
        {{ $localize('Messages') }}
      </div>
      <div>{{ discussionMessageCount }}</div>
    </div>

    <div class="request-header__item--buttons request-header__item">
      <div
        class="request-details__divider-button-container"
        :class="{ 'report-button__container': hideActionButtons }"
      >
        <button
          :ariaLabel="$localize('Report')"
          class="btn btn-default btn-outline"
          aria-describedby="errorValidationMessages"
          @click="reportFunc"
        >
          <span
            class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block"
            aria-hidden="true"
          >
            {{ $localize('CreateReportShort') }}
          </span>
          <span class="hidden-xs hidden-sm hidden-md">{{ $localize('CreateReport') }}</span>
          <span class="sr-only">{{ $localize('CreateReport') }}</span>
        </button>
      </div>
      <div
        v-if="!hideActionButtons"
        class="request-details__divider-button-container"
      >
        <button
          :disabled="disableActionButtons"
          :ariaLabel="$localize('DeleteResponse')"
          class="btn btn-default btn-outline"
          aria-describedby="errorValidationMessages"
          @click="deleteResponseFunc"
        >
          <span
            v-if="isDeleting"
            class="far fa-circle-notch fa-spin mr-2"
            aria-hidden="true"
          />
          <span
            class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block"
            aria-hidden="true"
          >{{ $localize('Delete') }}</span>
          <span class="hidden-xs hidden-sm hidden-md">{{ $localize('DeleteResponse') }}</span>
          <span class="sr-only">{{ $localize('DeleteResponse') }}</span>
        </button>
      </div>
      <div>
        <button
          v-if="!hideActionButtons"
          :disabled="disableActionButtons"
          :ariaLabel="$localize('SaveResponse')"
          class="btn btn-primary btn-outline"
          aria-describedby="errorValidationMessages"
          @click="saveResponseFunc"
        >
          <span
            v-if="isSaving"
            class="far fa-circle-notch fa-spin mr-2"
            aria-hidden="true"
          />
          <span
            class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block"
            aria-hidden="true"
          >{{ $localize('Save') }}</span>
          <span class="hidden-xs hidden-sm hidden-md">{{ $localize('SaveResponse') }}</span>
          <span class="sr-only">{{ $localize('SaveResponse') }}</span>
        </button>
        <button
          v-if="!hideActionButtons"
          :disabled="disableActionButtons"
          :ariaLabel="$localize('SubmitResponse')"
          class="btn btn-primary ml-3"
          aria-describedby="errorValidationMessages"
          @click="submitResponseFunc"
        >
          <span
            v-if="isSubmitting"
            class="far fa-circle-notch fa-spin mr-2"
            aria-hidden="true"
          />
          <span
            class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block"
            aria-hidden="true"
          >{{ $localize('Submit') }}</span>
          <span
            class="hidden-xs hidden-sm hidden-md"
            aria-hidden="true"
          >{{ $localize('SubmitResponse') }}</span>
          <span class="sr-only">{{ $localize('SubmitResponse') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';

defineProps<{
        response: any,
        status: string,
        discussionMessagingAllowed : boolean,
        isSaving: boolean,
        isDeleting: boolean,
        saveResponseFunc:() => void,
        submitResponseFunc: () => void,
        deleteResponseFunc: () => void,
        reportFunc: () => void,
        isSubmitting: boolean,
        disableActionButtons: boolean,
        hideActionButtons: boolean,
        discussionMessageCount: number
}>();
let observer = null;
let element = null;

// This piece mimics stickyElementBinding behavior
function init() {
  element = document.getElementById('uploadfolderheader');
  const supportsIntersectionObserver = 'IntersectionObserver' in window;
  element.style.position = 'sticky'; // Set this element to use position sticky for modern browsers
  element.style.zIndex = '11'; // Raise the stack level so the sticky element is above other elements

  // If the browser supports IntersectionObserver then use a -1px top position to force an
  // intersection with the top of the browser when the header is sticky.
  element.style.top = supportsIntersectionObserver ? '-1px' : '0';

  if (supportsIntersectionObserver) {
    observer = new IntersectionObserver(
        // If less than the whole element is displayed (which happens when the element is sticky as the first
        // pixel is hidden due to 'top: -1px') then toggle the sticky class. IntersectionObserver used for
        // performance reasons.
        ([e]) => e.target.classList.toggle('is-stuck', e.intersectionRatio < 1),
        { threshold: [1] }
    );

    observer.observe(element);
  }

}

onMounted(init);
onUnmounted(()=>{
  observer?.unobserve(element);
});
</script>

<style scoped>
    .sticky {
        position: sticky;
        z-index: 11;
    }

    .report-button__container {
        border-right: none;
        margin-right: 0;
        padding-right: 0;
    }
</style>