<template>
  <AutoComplete
    v-model="model"
    :options="state.filteredCadIds"
    :dropdown="true"
    :placeholder="placeholder"
    :input-props="{ 'aria-invalid': inputValueExceedsMaxLength ? 'true' : 'false' }"
    :disabled="disabled"
    :complete-on-focus="false"
    :multiple="true"
    :overlay-z-index="overlayZIndex"
    :class="inputValueExceedsMaxLength ? 'has-error' : ''"
    @search="searchCadId"
    @item-select="itemSelectHandler"
    @item-unselect="itemUnselectHandler"
  />
  <div
    v-if="inputValueExceedsMaxLength"
    class="help-block error-text"
  >
    {{ $localize('CadIdExceedsMaxLength') }}
  </div>
  <div
    v-else
    class="help-block disclaimer"
  >
    <small>{{ $localize('CadIdsDisclaimer') }}</small>
  </div>
  <div
    v-if="warningText && !inputValueExceedsMaxLength"
    class="help-block warning-text"
  >
    <small>{{ warningText }}</small>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, reactive } from 'vue';
import AutoComplete from '@/VueComponents/SharedComponents/AutoComplete.vue';
import LinkingReferenceRepository from '@/Repositories/linkingReferenceRepository';
import resourceHelper from '@/Utils/resourceHelper';

type MatchedId = {
    id: string
    itemIsMatch: boolean
}

defineProps<{
    disabled: boolean,
    overlayZIndex?: string
}>();
defineExpose({
  clearNotMatchedItems,
  isValid
});

function clearNotMatchedItems() {
  state.matchedCadIds.forEach(x => x.itemIsMatch = true);
}

function isValid() {
  return !inputValueExceedsMaxLength.value;
}

const model = defineModel<string[]>();
watch(model, () => {
  // format entered value: clear unexpected commas, split comma-separated ids, remove duplicates
  const selectedValues: string[] = [];
  model.value.forEach((id: string) => {
    const ids = parseInputId(id);
    ids.forEach(x => {
      if (!selectedValues.includes(x)) {
        selectedValues.push(x);
      }
    });
  });
  // update values in event
  model.value.length = 0;
  model.value.push(...selectedValues);
  model.value.sort((a:string, b:string) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });
});
const state: { filteredCadIds: string[], serverCadIds: string[], matchedCadIds: string[] } =
reactive({
  filteredCadIds: [],
  serverCadIds: [],
  matchedCadIds: []
});

const placeholder = computed(() => {
  return model.value.length ? '' : resourceHelper.getString('CadRmsIdPlaceholder');
});

const warningText = computed(() => {
  const notMatchedItems = state.matchedCadIds.filter(x => !x.itemIsMatch);
  if (notMatchedItems.length > 0) {
    const str = resourceHelper.getString('NoMatchesFoundFor');
    notMatchedItems.sort((a: MatchedId, b: MatchedId) => {
      return a.id.localeCompare(b.id);
    });
    const items = notMatchedItems.map(x => x.id).join(', ');
    return `${str} ${items}`;
  } else {
    return '';
  }
});

const inputValueExceedsMaxLength = computed(() => {
  return model.value.join(',').length > 1000;
});

function searchCadId(event) {
  const query = event.query.trim();
  if (!query.length) {
    state.filteredCadIds = [];
  } else {
    LinkingReferenceRepository.getAvailableCadIds(query)
        .then((cadIds: string[]) => {
          state.serverCadIds.push(...cadIds.slice());
          if (!cadIds.includes(query)) {
            cadIds.unshift(query);
          }
          const filteredIds = cadIds.filter(id => !model.value.includes(id));
          state.filteredCadIds = filteredIds;
        });
  }
}

function itemSelectHandler(event) {
  const ids = parseInputId(event.value);
  ids.forEach(id => {
    if (!state.matchedCadIds.some(x => x.id === id)) {
      const itemIsMatch = state.serverCadIds.includes(id);
      const idModel = {
        id,
        itemIsMatch
      };
      state.matchedCadIds.push(idModel);
    }
  });
}

function itemUnselectHandler(event) {
  state.matchedCadIds = state.matchedCadIds.filter(x => x.id !== event.value);
}

function parseInputId(id: string): Set<string> {
  const ids = id.split(',')
      .map(x => x.trim())
      .filter(x => x.length > 0);
  return new Set(ids);
}
</script>

<style lang="scss" scoped>
@import "sass/site/_colours.scss";
@import "sass/site/_forms.scss";

.warning-text {
  color: $alert-warning-text-color;
  margin-top: 0px;
  margin-bottom: 0px;
}
.error-text {
    color: $validation-error-color;
    margin-bottom: 0px;
}
.disclaimer {
  margin-bottom: 0px;
}

small {
    word-wrap: break-word;
}
</style>