<template>
  <ModalDialog
    :title="$localize('GroupSaveModalTitle')"
    :is-wide="false"
    @close="emit('close')"
  >
    <template
      #default
    >
      <div>
        {{ modalText }}
      </div>
      <br>
      <div>
        {{ $localize('GroupSaveModalQuestion') }}
      </div>
    </template>
    <template #footer>
      <button
        ref="closeButton"
        class="btn btn-default"
        @click="emit('close')"
      >
        {{ $localize('Cancel') }}
      </button>
      <button
        ref="saveButton"
        class="btn btn-primary"
        @click="emit('Save')"
      >
        {{ $localize('Save') }}
      </button>
    </template>
  </ModalDialog>
</template>

<script setup lang="ts">
import ModalDialog from '@/VueComponents/Modals/ModalDialog';
import resourceHelper from '@/Utils/resourceHelper';

const props = defineProps<{
  preRegisteredUsersAssignedCount: number
}>();
const emit = defineEmits<{ 'close', 'Save' }>();
const modalText = resourceHelper.getString('GroupSaveModalText', { '0': props.preRegisteredUsersAssignedCount });

</script>

<style scoped>
</style>
