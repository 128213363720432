<template>
  <VueMap
    v-model:coordinate="coordinate"
    v-model:zoom="zoom"
  >
    <VueMapDraggableMarker
      v-model:coordinate="businessCoordinate"
      :marker-style="MapMarkerStyle.business"
    />
  </VueMap>
</template>
<script lang="ts" setup>
import VueMap from '@/VueComponents/SharedComponents/Map/VueMap.vue';
import { Ref } from 'vue';
import MapCoordinate from '@/VueComponents/SharedComponents/Map/Models/mapCoordinate';
import VueMapDraggableMarker from '@/VueComponents/SharedComponents/Map/VueMapDraggableMarker.vue';
import { MapMarkerStyle } from '@/VueComponents/SharedComponents/Map/Models/mapMarkerStyle';

const coordinate: Ref<MapCoordinate> = defineModel<MapCoordinate>('coordinate', { required: true });
const businessCoordinate: Ref<MapCoordinate> = defineModel<MapCoordinate>('businessCoordinate', { required: true });
const zoom: Ref<number> = defineModel<number>('zoom', { required: true });
</script>