import { ref, Ref, toRaw } from 'vue';
import PartnerUploadModel from '@/Models/partnerUploadModel';
import PartnerZipFolderUploadModel from '@/Models/partnerZipFolderUploadModel';
import uploadRepository from '@/Repositories/uploadRepository';
import PartnerUploadModelAdapter from '@/Utils/partnerUploadModelAdapter';
import logger from '@/Utils/logger';
import DynamicRequestDetailsErrorMessagingAdapter from '@/Utils/dynamicRequestDetailsErrorMessagingAdapter';
import contextData from '@/contextData';
import { UploadType } from '@/Types/Enums/uploadType';
import UploadModel from '@/Models/uploadModel';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';

class UploadListManager {
  private readonly _uploadParentId: string;
  private readonly _uploadParentType: UploadParentItemType;
  private _uploadsRef: Ref<(PartnerUploadModel | PartnerZipFolderUploadModel)[]>;
  private _loading: Ref<boolean>;
  private _loadingError: Ref<boolean>;
  private _uploadsUpdatedCallback: (uploads: UploadModel[]) => void;

  public get loadingError() {
    return this._loadingError.value;
  }

  public get loading() {
    return this._loading.value;
  }

  public get uploads() {
    return this._uploadsRef.value;
  }

  public set uploads(value) {
    this._uploadsRef.value = value;
  }

  constructor(requestId: string, uploadParentItemType: UploadParentItemType, uploadsUpdatedCallback: (uploads: UploadModel[]) => void) {
    this._uploadParentId = requestId;
    this._uploadParentType = uploadParentItemType;

    this._uploadsRef = ref([]);
    this._loading = ref(false);
    this._loadingError = ref(false);
    this._uploadsUpdatedCallback = uploadsUpdatedCallback;
  }

  public load() {
    this._loading.value = true;
    this._loadingError.value = false;
    uploadRepository.getUploadsByRequestId(this._uploadParentId).then(data => {
      this._uploadsRef.value = [];

      const folders = this.getFolderUploads(data);
      const files = this.getSingleFileUploads(data);

      if (folders.length > 0) {
        this._uploadsRef.value.push(...folders);
      }
      if (files.length > 0) {
        this._uploadsRef.value.push(...files);
      }

      this.triggerUploadsUpdated();
    }).catch(() => {
      this._loadingError.value = true;
      logger.error('FailedToRetrieveUploads');
    }).finally(() => {
      this._loading.value = false;
    });
  }

  public loadForSelfResponse() {
    this._loading.value = true;
    this._loadingError.value = false;
    uploadRepository.getUploadsByResponseId(this._uploadParentId).then(data => {
      this._uploadsRef.value = [];

      const folders = this.getFolderUploads(data);
      const files = this.getSingleFileUploads(data);

      if (folders.length > 0) {
        this._uploadsRef.value.push(...folders);
      }
      if (files.length > 0) {
        this._uploadsRef.value.push(...files);
      }

      this.triggerUploadsUpdated();
    }).catch(() => {
      this._loadingError.value = true;
      logger.error('FailedToRetrieveUploads');
    }).finally(() => {
      this._loading.value = false;
    });
  }

  public sort(field: string, asc = true) {
    this._uploadsRef.value = this._uploadsRef.value.sort((a, b) => {
      const ascMultiplier = asc ? 1 : -1;

      let aValue = a.getSortingValue(field);
      let bValue = b.getSortingValue(field);

      if (!aValue) {
        aValue = '';
      }
      if (!bValue) {
        bValue = '';
      }

      if (typeof aValue === 'string') {
        return this.compareStrings(aValue, bValue) * ascMultiplier;
      }
      if (aValue < bValue) {
        return -1 * ascMultiplier;
      }
      if (aValue > bValue) {
        return 1 * ascMultiplier;
      }
      if (aValue === bValue) {
        return 0;
      }
    });
  }

  public addToRepository(fileList: FileList | File[]) {
    const errorFunc = e => {
      if (e.serverErrorMessages) {
        DynamicRequestDetailsErrorMessagingAdapter.setServerErrors(e.serverErrorMessages);
        return;
      }
      logger.error('FailedAttemptToAddFileUpload');
    };

    const newUploads = uploadRepository.addUploads(fileList, this._uploadParentId, this._uploadParentType, errorFunc);

    newUploads.forEach(u => {
      // Upload manager creates upload model before it's actually created on the backend so
      // some data can be missing. Put personaDisplayName manually for the correct displaying uploads in table.
      if (!u.personaDisplayName) {
        u.personaDisplayName = contextData.userData.displayName();
      }
    });

    const folders = this.getFolderUploads(newUploads);
    const files = this.getSingleFileUploads(newUploads);

    if (folders.length > 0) {
      this._uploadsRef.value.unshift(...folders);
    }
    if (files.length > 0) {
      this._uploadsRef.value.unshift(...files);
    }

    this.triggerUploadsUpdated();
  }

  public triggerUploadsUpdated() {
    this._uploadsUpdatedCallback(this.getAllUploadModels());
  }

  public getById(uploadId: string): PartnerUploadModel | PartnerZipFolderUploadModel | undefined {
    return this.uploads.find(u => u.uploadId === uploadId);
  }

  private getSingleFileUploads(uploads: UploadModel[]) {
    return uploads.filter(upload => upload.type === UploadType.file ||
      upload.type === UploadType.folderFile ||
      upload.type === UploadType.deviceBackup ||
      upload.type === UploadType.cctv ||
      upload.type === UploadType.zipGeneralContent ||
      upload.type === UploadType.zipCctv ||
      upload.type === UploadType.zipDeviceBackup).map(u => PartnerUploadModelAdapter.getPartnerUploadModel(u));
  }

  private getFolderUploads(uploads: UploadModel[]): PartnerZipFolderUploadModel[] {
    const achiveFolders = this.mapFolderUploads(uploads, UploadType.deviceBackupFile);
    const cctvFolders = this.mapFolderUploads(uploads, UploadType.cctvFile);
    return cctvFolders.concat(achiveFolders);
  }

  private mapFolderUploads(uploads: UploadModel[], requiredType: UploadType): PartnerZipFolderUploadModel[] {
    const folders = uploads.filter(upload => {
      return upload.type === requiredType;
    }).reduce(function (rv: any, x: UploadModel) {
      (rv[x.rootFolderName] = rv[x.rootFolderName] || []).push(x);
      return rv;
    }, {});

    const models = [];
    for (const key in folders) {
      if (Object.prototype.hasOwnProperty.call(folders, key)) {
        const partnerUploads = folders[key].map((u: UploadModel) => PartnerUploadModelAdapter.getPartnerUploadModel(u));
        const folder = new PartnerZipFolderUploadModel(partnerUploads);
        models.push(folder);
      }
    }
    return models;
  }

  private compareStrings(string1: string, string2: string) {
    return string1.localeCompare(string2, undefined, { numeric: true });
  }

  private getAllUploadModels(): UploadModel[] {
    let uploads = this.uploads.filter(upload => upload instanceof PartnerUploadModel)
      .map(upload => toRaw(upload.uploadManagerUploadModel));

    const folderFiles = this.uploads.filter(upload => upload instanceof PartnerZipFolderUploadModel);
    folderFiles.forEach(folder => {
      uploads = uploads.concat(folder.uploads.map(x => toRaw(x.uploadManagerUploadModel)));
    });

    return uploads;
  }
}

export default UploadListManager;
