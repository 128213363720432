<template>
  <li
    :class="{ active: selected }"
  >
    <a
      href="#"
      @click="onClick"
    >
      <slot>{{ pageNumber }}</slot>
    </a>
  </li>
</template>

<script setup lang="ts">
const props = defineProps({
  pageNumber: { type: Number, default: 0 },
  selected: { type: Boolean, default: false }
});
const emit = defineEmits(['click']);

function onClick(event: Event) {
  event.preventDefault();
  emit('click', props.pageNumber);
}
</script>
