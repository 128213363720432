<script setup lang="ts">
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
const emit = defineEmits < { 'close', 'onDelete' }>();

function onModalClose() {
  emit('close');
}

</script>

<template>
  <ModalDialog
    :title="$localize('DeleteUploadFolder')"
    :is-danger="true"
    @close="onModalClose"
  >
    <template #default>
      <div>
        {{ $localize('DeleteUploadFolderText') }}
      </div>
      <div class="mt-3">
        {{ $localize('DeleteUploadFolderQuestion') }}
      </div>
    </template>
    <template #footer>
      <button
        ref="closeBtn"
        class="btn btn-default"
        @click="onModalClose"
      >
        {{ $localize('Cancel') }}
      </button>
      <button
        ref="deleteBtn"
        class="btn btn-danger"
        @click="emit('onDelete')"
      >
        {{ $localize('Continue') }}
      </button>
    </template>
  </ModalDialog>
</template>
