import ko from 'knockout';
import currentUserRepository from '@/Repositories/currentUserRepository';
import logger from '@/Utils/logger';
import redirectHelper from '@/Utils/redirectHelper';
import languageHelper from '@/Utils/languageHelper';
import resourceHelper from '@/Utils/resourceHelper';
import localStorageHelper from '@/Utils/localStorageHelper';
import contextData from '@/contextData';
import template from './languageNavItemPortal.html';

export function LanguageNavItemViewModel() {

  const self = this;

  self.availableLanguages = contextData.availableLanguages();
  self.currentLanguageResourceKey = languageHelper.getCurrentLanguageResourceKey();
  self.flagIcon = contextData.flagIcon;
  self.languageCode = contextData.languageCode();

  self.anchorAriaLabel = ko.pureComputed(() =>
    `${resourceHelper.getString(self.currentLanguageResourceKey)} ${resourceHelper.getString('SelectYourLanguage')}`);

  self.changeLanguage = function (languageData) {
    if (contextData.languageCode() === languageData.languageCode) {
      return;
    }

    currentUserRepository.updateLanguageCode(languageData.languageCode)
        .then(function () {
          localStorageHelper.setLanguageDetails(languageData.languageCode);
          redirectHelper.reload();
        })
        .catch(function (jqXhr) {

          if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnexpectedErrorWhileUpdatingUserLanguage', null, jqXhr);
          }
        });
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: LanguageNavItemViewModel, template: template };


