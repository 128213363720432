import resourceHelper from '@/Utils/resourceHelper';

class ReportDownloader {
  public static getDefaultReportTitle = (itemReferenceNumber: string): string => {
    const currentDate = new Date();

    let month = `${currentDate.getMonth() + 1}`;
    month = month.length < 2 ? `0${month}` : month;

    let day = `${currentDate.getDate()}`;
    day = day.length < 2 ? `0${day}` : day;

    let hours = `${currentDate.getHours()}`;
    hours = hours.length < 2 ? `0${hours}` : hours;

    let minutes = `${currentDate.getMinutes()}`;
    minutes = minutes.length < 2 ? `0${minutes}` : minutes;

    let seconds = `${currentDate.getSeconds()}`;
    seconds = seconds.length < 2 ? `0${seconds}` : seconds;

    return `${itemReferenceNumber} ${resourceHelper.getString('Report')} - ${currentDate.getFullYear()}${month}${day} ${hours}${minutes}${seconds}`;
  };

  public static downloadReport = (data: any, reportTitle: string): void => {
    const file = new Blob([data], { type: 'application/pdf' });
    const url = URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${reportTitle}.pdf`);
    document.body.appendChild(link);
    link.click();
  };
}
export default ReportDownloader;
