import ko from 'knockout';
import contextData from '../../contextData';
import template from './contextDataInfo.html';

export function ViewModelInfoViewModel(params) {

  const self = this;

  self.expanded = params.expanded;
  self.json = ko.pureComputed(() => ko.toJSON(contextData, null, 2));
}

// The default export returns the component details object to register with KO
export default { viewModel: ViewModelInfoViewModel, template: template };
