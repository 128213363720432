<script setup lang="ts">
import { FilterItemValue } from '@/VueComponents/Filter/Models/filterItemValue';

const props = defineProps<{
     filterItemValues: FilterItemValue[],
     name: string,
     isExpanded: boolean
}>();

const textFilterValue = props.filterItemValues[0];

</script>
<template>
  <Transition>
    <ul
      v-show="isExpanded"
      :id="'filter-collapse-' + name"
      class="filter-panel__values--scrollable filter-panel__list--no-bullet filter-panel__text-filter--indented"
      :aria-expanded="String(isExpanded)"
    >
      <li>
        <form action="">
          <div class="filter_panel__text-filter-form">
            <label
              class="sr-only"
              :for="'textFilterItem-'+ name"
            >
              {{ $localize('EnterFilterTerms') }}
            </label>
            <input
              :id="'textFilterItem-'+ name"
              v-model="textFilterValue.currentValue"
              class="text form-control filter_panel__text-filter-form--text"
              type="text"
              :placeholder="$localize('EnterFilterTerms')"
            >
            <button
              class="btn btn-primary filter_panel__text-filter-button"
              :disabled="textFilterValue.hasNoChanges"
              :aria-label="$localize('ApplyFilter')"
              @click="textFilterValue.applyFilter"
            >
              <i
                class="fas fa-filter"
                aria-hidden="true"
              />
            </button>
          </div>
        </form>
      </li>
    </ul>
  </Transition>
</template>

<style>
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
</style>