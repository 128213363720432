<script setup lang="ts">
import ListItem from '@/VueComponents/List/Models/listItem';

defineProps<{
  modelValue: ListItem[]
}>();
const emit = defineEmits<{'itemSelectionChanged'}>();

function itemContainerClasses(item: ListItem): string {
  let className = 'item-container ';
  if (item.isSelected) {
    className += 'selected ';
  }
  if (!item.subName) {
    className += 'smaller';
  }

  return className;
}
</script>

<template>
  <div role="listbox">
    <div
      v-for="item in modelValue"
      :key="item.id"
      role="option"
      :class="itemContainerClasses(item)"
    >
      <label
        class="item-label ellipsis"
        :for="'checkbox_'+item.id"
        :title="item.name"
      >
        <input
          :id="'checkbox_'+item.id"
          v-model="item.isSelected"
          type="checkbox"
          :aria-describedby="'error_'+item.id"
          class="item-checkbox"
          @change="emit('itemSelectionChanged')"
        >
        <div class="name-container">
          <span>{{ item.name }}</span>
          <small v-if="item.subName">{{ item.subName }}</small>
        </div>
      </label>
      <i
        v-if="!item.isValid"
        :title="item.invalidReason"
        class="far fa-circle-exclamation validation-icon"
        aria-hidden="true"
      />
      <span
        v-if="!item.isValid"
        :id="'error_'+item.id"
        class="sr-only"
      >
        {{ item.invalidReason }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
    @import "sass/site/_colours.scss";

    .item-container {
        border-bottom: 1px solid $background-light-grey;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
    }
    .item-container:before {
      content: '';
      width: 4px;
      height: 40px;
      background: white;
    }
    .item-container.smaller:before {
      height: 30px;
    }
    .item-container:hover::before {
      background: $hover-list-item-color;
    }
    .item-container.selected {
      background-color: $selected-list-item-background-color;
    }
    .item-container.selected:before {
      background: $selected-list-item-color;
    }
    .validation-icon {
      margin-right: 5px;
      margin-left: 5px;
      color: red;
    }
    .item-label {
      display: flex;
      flex: auto;
      margin-left: 5px;
      margin-bottom: 0px;
      font-weight: 100;
    }
    .name-container {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
    .item-checkbox {
      margin: 0;
    }
</style>