import { Observable } from 'knockout';
import ko from 'knockout';
import { SortOption } from './sortOption';
import { BaseSortDropdownOptions } from './baseSortDropdownOptions';

import template from './baseSortDropdown.html';
import resourceHelper from '@/Utils/resourceHelper';

class BaseSortDropdown {
  id: string;
  labelResourceKey: string;
  options: SortOption[];
  selectedSortValue: Observable<string>;

  isAscOrder: KnockoutComputed<boolean>;
  selectedDropdownValue: KnockoutComputed<string>;
  orderLocalizedString: KnockoutComputed<string>;

  constructor(params: { options: BaseSortDropdownOptions }) {
    this.id = params.options.id || 'sortBy';
    this.labelResourceKey = params.options.labelResourceKey || 'SortBy';
    this.options = params.options.options;
    this.selectedSortValue = params.options.selectedSortValue;

    this.isAscOrder = ko.computed(() => {
      if (!this.selectedSortValue()) {
        return true;
      }
      const selectedSortValueParameters = this.selectedSortValue().split('|');
      if (selectedSortValueParameters.length < 2) {
        throw Error('Incorrect selected sorting value provided');
      }
      return this.selectedSortValue().split('|')[1] === 'Asc';
    });

    this.selectedDropdownValue = ko.computed(() => {
      if (!this.selectedSortValue()) {
        return this.options[0].sortField;
      }
      return this.selectedSortValue().split('|')[0];
    });

    this.orderLocalizedString = ko.computed(() => {
      return this.isAscOrder() ? resourceHelper.getString('Ascending') : resourceHelper.getString('Descending');
    });
  }

  getSelectValueFunc(field: string, isAsc: boolean): string {
    return `${field}|${isAsc ? 'Asc' : 'Desc'}`;
  }

  sortOrderClicked() {
    this.selectedSortValue(this.getSelectValueFunc(this.selectedDropdownValue(), !this.isAscOrder()));
  }

  sortItemSelected(viewModel: BaseSortDropdown, event: any) {
    this.selectedSortValue(this.getSelectValueFunc(event.target.value, this.isAscOrder()));
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: BaseSortDropdown, template: template };
