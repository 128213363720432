import ko from 'knockout';
import ValidationRulesService from '@/Validation/validationRulesService';
import businessRepository from '@/Repositories/businessRepository';
import addressLookupRepository from '@/Repositories/addressLookupRepository';
import logger from '@/Utils/logger';
import contextData from '@/contextData';
import { AccountType } from '@/Types/Enums/accountType';
import PortalSettingsProvider from '@/Utils/portalSettingsProvider';

import template from './accountDetails.html';
import { VueReactivePropsBuilder } from '@/VueCore/utils/vueAppBuilder';

export function AccountDetailsViewModel(params) {
  const self = this;
  const defaultZoomLevel = 17;

  let initialCoordinate = contextData.portalSettings.defaultMapView.coordinate;

  const businessData = contextData.userData.business;
  const validationRules = params.validationRules;

  if (businessData.coordinate) {
    initialCoordinate = businessData.coordinate;
  }

  self.businessId = businessData.businessId;
  self.businessName = ko.observable(businessData.businessName);
  self.businessAddress = ko.observable(businessData.businessAddress);

  self.vueMapProps = new VueReactivePropsBuilder()
      .withVModel('coordinate', initialCoordinate)
      .withVModel('zoom', defaultZoomLevel)
      .withVModel('businessCoordinate', initialCoordinate)
      .build();

  self.formSubmitted = ko.observable(false);
  self.clientErrors = ko.validation.group(self, { deep: true });
  self.serverErrors = ko.observableArray([]);

  self.searching = ko.observable(false);
  self.showLocation = PortalSettingsProvider.getAccountType() === AccountType.Business;

  const validationRulesService = new ValidationRulesService(self);
  validationRulesService.applyValidation(validationRules);

  self.updateMarkerFromAddress = function () {

    // lookup address
    if (!self.businessAddress().length) {
      return;
    }

    logger.debug('looking up address for \'%s\'', self.businessAddress());

    self.searching(true);

    addressLookupRepository.lookupAddress(self.businessAddress(), false) // orderByDistanceToCenter is false
        .then(function (addressSearchResults) {

          self.searching(false);

          logger.debug('received %d results', addressSearchResults.length);

          if (!addressSearchResults.length) {
            logger.info('AddressSearchNoResultsMessage');
            return;
          }

          self.vueMapProps.coordinate = addressSearchResults[0].coordinate;
          self.vueMapProps.businessCoordinate = addressSearchResults[0].coordinate;
          self.vueMapProps.zoom = defaultZoomLevel;

        })
        .catch(function (jqXhr) {

          self.searching(false);

          if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnexpectedErrorWhileRetrievingAddressLookup', null, jqXhr);
          }
        });
  };

  self.saveChanges = function () {

    if (self.clientErrors().length > 0) {
      self.clientErrors.showAllMessages(true);
      return;
    }

    self.formSubmitted(true);

    const businessData = ko.toJS(self);

    businessData.coordinate = self.vueMapProps.businessCoordinate;

    businessRepository.updateBusinessData(businessData)
        .then(function (businessData) {

          self.formSubmitted(false);

          contextData.userData.business.businessName = businessData.businessName;
          contextData.userData.business.businessAddress = businessData.businessAddress;
          contextData.userData.business.coordinate = businessData.coordinate;

          logger.success('CurrentBusinessUpdatedSummary');
        })
        .catch(function (jqXhr) {
          self.formSubmitted(false);

          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
            return;
          }

          if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnexpectedErrorWhileUpdatingBusinessDetails', null, jqXhr);
          }
        });
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: AccountDetailsViewModel, template: template };

