<template>
  <div
    :class="{ 'drop-zone-active': dropZoneActive }"
    @dragover.stop.prevent
    @dragenter.stop.prevent="onDragEnter"
    @dragleave.stop.prevent="onDragLeave"
    @drop.stop.prevent="onDrop"
  >
    <slot />
    <UploadOptionsWarningModal
      v-if="showUploadOptionsWarningModal"
      :zip-files-count="zipFilesCount"
      :non-zip-files-count="nonZipFilesCount"
      :file-list="fileList"
      :upload-option="uploadOptionSelected"
      @cancel="closeUploadOptionsWarningModal"
      @continue="continueWithContentTypeSelectionModal"
    />
    <ContentTypeSelectionModal
      v-if="showContentTypeSelectionModal"
      :upload-option="uploadOptionSelected"
      :file-list="fileList"
      :folders-count="foldersCount"
      @cancel="closeContentSelectionModal"
      @upload="upload"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import logger from '@/Utils/logger';
import dropZoneUploadProvider from '@/Bindings/helpers/dropZoneUploadProvider';
import UploadOptionsWarningModal from '@/VueComponents/Modals/UploadOptionsWarningModal.vue';
import requestUploadValidation from '@/Validation/requestUploadValidation';
import { UploadOption } from '@/Types/Enums/uploadOption';
import { UploadContentType } from '@/Types/Enums/uploadContentType';
import ContentTypeSelectionModal from '@/VueComponents/Modals/ContentTypeSelectionModal.vue';

const props = defineProps({
  uploadOption: { type: String, default: UploadOption.selectFiles },
  uploadParentItemIsCompleting: { type: Boolean, default: false },
  uploadParentItemType: { type: String, default: '' },
  uploadParentItemIsDiscarding: { type: Boolean, default: false }
});
const emit = defineEmits(['addedFileUploads', 'addToZipUploads']);

let counter = 0;
const dropZoneActive = ref(false);
const showUploadOptionsWarningModal = ref(false);
const showContentTypeSelectionModal = ref(false);
let uploadOptionSelected: string;
let fileList: File[];
let foldersCount: number;
let nonZipFilesCount: number;
let zipFilesCount: number;

function onDragEnter() {
  counter++;
  dropZoneActive.value = true;
}

function onDragLeave() {
  counter--;

  if (counter === 0) {
    dropZoneActive.value = false;
  }
}

function onDrop(event: any) {
  counter = 0;
  dropZoneActive.value = false;
  uploadOptionSelected = props.uploadOption;

  if (requestUploadValidation.showItemIsCompletingWarning(props.uploadParentItemIsCompleting, props.uploadParentItemType)) {
    return;
  }

  if (requestUploadValidation.showItemIsDiscardingWarning(props.uploadParentItemIsDiscarding, props.uploadParentItemType)) {
    return;
  }

  const uploadEntries = dropZoneUploadProvider.parseFilesToEntries(event.dataTransfer.items);
  const hasFile = uploadEntries.some(entry => entry.isFile);
  const hasFolder = uploadEntries.some(entry => entry.isDirectory);
  if (hasFile && hasFolder) {
    logger.warning('SelectFilesOrFolders');
    return;
  }

  if (!requestUploadValidation.isZipOptionSelected(uploadOptionSelected) && hasFolder) {
    uploadOptionSelected = UploadOption.selectFolder;
  }
  dropZoneUploadProvider.getFilesFromEventItems(event.dataTransfer.items, requestUploadValidation.isFolderOptionSelected(uploadOptionSelected), false)
      .then(function (files) {
        fileList = files;
        if (fileList && fileList.length > 0) {
          const zipFiles = requestUploadValidation.getZipFiles(fileList);
          const nonZipFiles = requestUploadValidation.getNonZipFiles(fileList);
          nonZipFilesCount = nonZipFiles.length;
          zipFilesCount = zipFiles.length;
          if (requestUploadValidation.isZipOptionSelected(uploadOptionSelected)) {
            handleZipFilesUpload(nonZipFiles, zipFiles);
          } else {
            handleNonZipFilesUpload(uploadEntries.length, nonZipFiles, zipFiles);
          }
        } else if (fileList) {
          logger.warning('EmptyFolderUploadWarningMessage');
        }
      }).catch(function (error) {
        const cctvErrorMessageKey = 'UnexpectedErrorWhileUploadingCctvOrDeviceBackup';
        const fileErrorMessageKey = 'UnexpectedErrorWhileAddingFileOrFolderUpload';
        logger.error(requestUploadValidation.isFolderOptionSelected(uploadOptionSelected) ? cctvErrorMessageKey : fileErrorMessageKey, null, error);
      });
}

function upload(uploadContentType: UploadContentType | undefined) {
  showContentTypeSelectionModal.value = false;
  const isCctv = requestUploadValidation.isFolderOptionSelected(uploadOptionSelected) && (uploadContentType === UploadContentType.cctv ||
    uploadContentType === UploadContentType.deviceBackup);
  const isZip = requestUploadValidation.isZipOptionSelected(uploadOptionSelected);
  if (isZip) {
    fileList = requestUploadValidation.getZipFiles(fileList);
    if (fileList.length === 0) {
      logger.warning('NoZipUploadWarningMessage');
      return;
    }
  }

  Array.from(fileList)
      .forEach(file => {
        Object.defineProperty(file, isCctv ? 'isCctv' : isZip ? 'isZip' : 'isFile', {
          value: true
        });
        if (uploadContentType !== undefined) {
          Object.defineProperty(file, 'uploadContentType', {
            value: uploadContentType
          });
        }
      });

  if (isCctv) {
    emit('addToZipUploads', fileList.filter(f => f.webkitRelativePath.substr(0, f.webkitRelativePath.indexOf('/')) !== ''));
  } else {
    emit('addedFileUploads', fileList);
  }
}

function closeContentSelectionModal() {
  showContentTypeSelectionModal.value = false;
  // Set to empty string to reset and allow same file to be selected again
  fileList = [];
  foldersCount = 0;
}

function closeUploadOptionsWarningModal() {
  showUploadOptionsWarningModal.value = false;
  fileList = [];
}

function continueWithContentTypeSelectionModal() {
  showUploadOptionsWarningModal.value = false;

  if (showContentSelectionModal()) {
    showContentTypeSelectionModal.value = true;
    return;
  }

  upload(undefined);
  // Set to empty string to reset and allow same file to be selected again
  fileList = [];
}

function showContentSelectionModal() {
  if (requestUploadValidation.showContentTypeSelectionModal(uploadOptionSelected, fileList)) {
    if (requestUploadValidation.isFileOptionSelected(uploadOptionSelected)) {
      uploadOptionSelected = UploadOption.selectZipFiles;
    }
    return true;
  }
  return false;
}

// Handle upload for 'Zip option'. Only zip files are allowed.
function handleZipFilesUpload(nonZipFiles: File[], zipFiles: File[]) {
  if (requestUploadValidation.isZipOptionSelected(uploadOptionSelected)) {
    fileList = zipFiles;
    // When only non-zip file(s) selected - show the warning toaster
    if (fileList.length === 0) {
      logger.warning('NoZipUploadWarningMessage');
      return;
    }
    // When mix of zip and non-zip files selected - show the warning modal
    if (requestUploadValidation.showContainsNonZipWarning(uploadOptionSelected, nonZipFiles.length)) {
      showUploadOptionsWarningModal.value = true;
      return;
    } else if (showContentSelectionModal()) {
      showContentTypeSelectionModal.value = true;
      return;
    }
  }
}

// Handle upload for 'Files and folders'. All formats are allowed.
function handleNonZipFilesUpload(uploadEntries: number, nonZipFiles: File[], zipFiles: File[]) {
  foldersCount = uploadEntries;
  // When more than 1 zip file selected - show the warning toaster
  if (nonZipFiles.length === 0 && zipFiles.length > 1) {
    logger.warning('ZipUploadWarningMessage');
    return;
  }
  // When files or folders contains zip files - show the warning modal
  if (requestUploadValidation.showContainsZipWarning(uploadOptionSelected, fileList)) {
    fileList = nonZipFiles;
    showUploadOptionsWarningModal.value = true;
    return;
  // When only 1 zip file selected - proceed with Content Selection modal
  } else if (showContentSelectionModal()) {
    showContentTypeSelectionModal.value = true;
    return;
  }
  // Proceed with direct upload for files
  upload(undefined);
}

</script>
<style>
.drop-zone-active {
  background-color: #FDFFB7;
  border-style: dashed;
}
</style>