<template>
  <div>
    <h4>{{ $localize(uploadParentItemIsCompleted ? 'UploadedFiles' : 'Upload') }}</h4>
    <div v-if="!uploadParentItemIsCompleted">
      <p>{{ $localize('ChooseItemTypeToUpload') }}</p>
      <p>
        {{
          $localize('MaximumUploadsSizeExplanatoryText', {
            maximumUploadSize: FileSizeFormatter.getFormattedFileSize(PortalSettingsProvider.getMaxUploadSize()),
            maximumCctvFolderUploadSize: FileSizeFormatter.getFormattedFileSize(PortalSettingsProvider.getMaxCctvFolderUploadSize()),
            limitNumberOfUploads : UploadFileLimitResolver.getUploadFileLimitByRequestType()})
        }}
      </p>
      <p>{{ $localize('CCTVFolderExportExplanatoryDefaultText') }}</p>
      <p v-if="uploadGuidance">
        {{ uploadGuidance }}
      </p>
      <FileUploadSelector
        :upload-parent-item-is-completing="uploadParentItemIsCompleting"
        :upload-parent-item-type="uploadParentItemType"
        :upload-parent-item-is-discarding="uploadParentItemIsDiscarding"
        @to-zip-uploads-added="addFolderAsZipUploads"
        @file-uploads-added="addFileUploads"
      />
    </div>
    <div
      v-if="uploadsListManager.loading"
      class="results-status text-center"
    >
      <img
        src="Content/images/preloaderSmall1.gif"
        width="32"
        height="32"
        class="loader-image"
        alt="Loading animation"
      >
      &nbsp; <span>{{ $localize('LoadingPleaseWait') }}</span>
    </div>
    <div
      v-if="uploadsListManager.loadingError"
      class="request-details__uploads-loading-error-container"
    >
      <i
        aria-hidden="true"
        class="far fa-exclamation-circle text-danger"
      />
      <div>{{ $localize('FailedToRetrieveUploads') }}</div>
    </div>
    <PaginatedTable
      v-if="!uploadsListManager.loadingError && !uploadsListManager.loading && uploadsListManager.uploads.length > 0"
      ref="pTable"
      :fields="uploadsTableConfiguration.tableFields"
      :items="tableData"
      :default-page-size="uploadsTableConfiguration.pageSize"
      :caption="$localize('Uploads')"
      @page-size-changed="onPageSizeChanged"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-summary">
            <h5 class="uploads-table-label">
              <span v-if="statistics.foldersCount === 1">{{ $localize('OneFolder') }}, </span>
              <span v-if="statistics.foldersCount > 1">{{ $localize('NumberOfFolders', { number: statistics.foldersCount }) }}, </span>
              <span>{{ uploadsTableHeaderTotal }}</span>
              <span v-if="uploadsTableHeaderUploading || uploadsTableHeaderFailed">
                (<span>{{ uploadsTableHeaderUploading }}</span>
                <span v-if="uploadsTableHeaderUploading && uploadsTableHeaderFailed">, </span>
                <a
                  href="#"
                  @click="onFailedClick"
                >{{ uploadsTableHeaderFailed }}</a>)
              </span>
            </h5>
            <span class="small">
              {{
                $localize('UploadsTableHeaderUploadedSize', {
                  uploaded: FileSizeFormatter.getFormattedFileSize(statistics.uploadedSize),
                  total: FileSizeFormatter.getFormattedFileSize(statistics.uploadsTotalSize) })
              }}
            </span>
          </div>
          <div class="table-header-selector">
            <SelectUploadsDropdown
              class="table-header-button mr-2"
              :overlay-z-index="constants.dropdownZIndex"
              :failed-count="statistics.failedUploadItemsCount"
              :available-for-selection-count="statistics.availableForSelection"
              :selected-count="statistics.selectedCount"
              @select-all="selectAll"
              @select-failed="selectFailed"
              @deselect-all="deselectAll"
            />
            <button
              v-if="allowToManageMetadata"
              class="btn btn-default btn-sm table-header-button mr-2"
              :disabled="uploadsRemovingManager.isRemoving"
              :title="$localize(uploadParentItemIsCompleted ? 'ViewMetadata' : 'EditMetadata')"
              @click="editMetadataClick"
            >
              <i
                class="far fa-edit"
                aria-hidden="true"
              />
              <span class="small-screen-hidden ml-1">
                {{ $localize(uploadParentItemIsCompleted ? 'ViewMetadata' : 'EditMetadata') }}
              </span>
            </button>
            <button
              v-if="!uploadParentItemIsCompleted"
              class="btn btn-default btn-sm table-header-button"
              :disabled="uploadsRemovingManager.isRemoving || statistics.selectedCount === 0"
              :title="$localize('RemoveSelected')"
              @click="removeUploads"
            >
              <i
                v-if="!uploadsRemovingManager.isRemoving"
                class="far fa-trash-can"
              />
              <i
                v-else
                class="far fa-circle-notch fa-spin"
              />
              <span class="small-screen-hidden ml-1">
                {{ $localize('RemoveSelected') }}
              </span>
            </button>
          </div>
          <div class="table-header-sort">
            <SortDropdown
              :sorting-options="uploadsTableConfiguration.sortOptions"
              :default-value="uploadsTableConfiguration.sortOptions[0].field"
              @sort="onSort"
            />
          </div>
        </div>
      </template>
      <template #fileName="prop">
        {{ prop.data.fileName }}
        <div>
          <small>
            {{ getUploadDetails(prop.data) }}
          </small>
        </div>
      </template>
      <template #select="prop">
        <input
          type="checkbox"
          :disabled="prop.data.status === constants.uploadStatuses.authorising || prop.data.status === constants.uploadStatuses.initializing"
          :checked="uploadsSelectionManager.isItemSelected(prop.data.id)"
          @change="event => onItemSelectionChanged(prop.data.id, event.target.checked)"
        >
      </template>
      <template #status="prop">
        <i
          v-if="prop.data.status === constants.uploadStatuses.authorising || prop.data.status === constants.uploadStatuses.initializing"
          class="far fa-circle-notch fa-spin"
        />
        {{ $localize(prop.data.status) }}
        <div v-if="prop.data.status === constants.uploadStatuses.uploaded && prop.data.isFolder">
          <small>
            {{ $localize('FilesCount', { number: prop.data.numberOfUploads }) }}
          </small>
        </div>
        <span v-if="prop.data.status === constants.uploadStatuses.uploading">
          ({{ getUploadedPercent(prop.data.bytesUploaded, prop.data.bytesTotal) }}%)
        </span>
        <UploadStatusProgressBar
          v-if="prop.data.status === constants.uploadStatuses.uploading"
          :progress="getUploadedPercent(prop.data.bytesUploaded, prop.data.bytesTotal)"
        />
      </template>
    </PaginatedTable>
    <UploadLimitModal
      v-if="showUploadLimitModal"
      :limit-number-of-uploads="limitNumberOfUploads"
      :existed-uploads="statistics.totalUploadsCount"
      :new-uploads="newUploads"
      :total-uploads="totalUploads"
      :upload-parent-item-type="uploadParentItemType"
      @close="showUploadLimitModal = false"
    />
    <ListOfDuplicatedUploadsModal
      v-if="showDuplicatesModal"
      :duplicated-files="sortedUploads.duplicates"
      @close="showDuplicatesModal = false"
      @continue="onContinueDuplicatesModal"
      @skip-duplicates="onSkipDuplicatesModal"
    />
    <EditUploadsMetadataModal
      v-if="showEditMetadataModal"
      :uploads="uploadsForEditingMetadata"
      :upload-parent-id="getRequestId()"
      :upload-parent-item-type="uploadParentItemType"
      :upload-parent-item-is-completed="uploadParentItemIsCompleted"
      :is-danger="false"
      :is-wide="true"
      @close="showEditMetadataModal = false"
      @saved="showEditMetadataModal = false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, ref } from 'vue';
import PaginatedTable from '@/VueComponents/PaginatedTable/PaginatedTable.vue';
import SelectUploadsDropdown from '@/VueComponents/Uploads/SelectUploadsDropdown.vue';
import SortDropdown from '@/VueComponents/SharedComponents/SortDropdown/SortDropdown.vue';
import UploadStatusProgressBar from '@/VueComponents/Uploads/UploadStatusProgressBar.vue';
import FileSizeFormatter from '@/Utils/fileSizeFormatter';
import FileUploadSelector from '@/VueComponents/FileUploadSelector/FileUploadSelector.vue';
import requestUploadValidation from '@/Validation/requestUploadValidation';
import UploadsTableConfiguration from '@/VueComponents/Uploads/utils/uploadsTableConfiguration';
import moment from 'moment';
import UploadsTableDataModel from '@/VueComponents/Uploads/models/uploadsTableDataModel';
import UploadsRemovingManager from '@/VueComponents/Uploads/utils/uploadsRemovingManager';
import UploadsSelectionManager from '@/VueComponents/Uploads/utils/uploadsSelectionManager';
import UploadListManager from '@/VueComponents/Uploads/utils/uploadListManager';
import PartnerZipFolderUploadModel from '@/Models/partnerZipFolderUploadModel';
import constants from '@/constants';
import dynamicRequestDetailsErrorMessagingAdapter from '@/Utils/dynamicRequestDetailsErrorMessagingAdapter';
import PortalSettingsProvider from '@/Utils/portalSettingsProvider';
import ListOfDuplicatedUploadsModal from '@/VueComponents/Modals/ListOfDuplicatedUploadsModal.vue';
import EditUploadsMetadataModal from '@/VueComponents/Modals/EditUploadsMetadataModal.vue';
import logger from '@/Utils/logger';
import FileListConverter from '@/Utils/fileListConverter';
import UploadFileLimitResolver from '@/Utils/uploadFileLimitResolver';
import UploadLimitModal from '@/VueComponents/Modals/UploadLimitModal.vue';
import LimitInfo from '@/Types/limitInfo';
import PartnerUploadModel from '@/Models/partnerUploadModel';
import FilePathFormatter from '@/Utils/filePathFormatter';
import UploadSummaryModel from '@/VueComponents/Uploads/models/uploadSummaryModel';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';
import UploadModel from '@/Models/uploadModel';
import localStorageHelper, { LocalStorageKeyType } from '@/Utils/localStorageHelper';
import UploadTypeResolver from '@/Utils/uploadTypeResolver';
import resourceHelper from '@/Utils/resourceHelper';

const emit = defineEmits<{(e: 'uploadsUpdated', uploads: UploadModel[]): void,
        (e: 'uploadFailedToAdd', errorKey: string): void,
    }>();
const props = defineProps<{
    uploadParentItemType: UploadParentItemType,
    uploadParentItemIsCompleted: boolean,
    uploadParentItemIsCompleting: boolean,
    allowToManageMetadata: boolean,
    uploadParentItemIsDiscarding: boolean,
    uploadGuidance: string | undefined
}>();

const uploadsTableConfiguration = new UploadsTableConfiguration(props.uploadParentItemType);

const uploadsListManager = new UploadListManager(getRequestId(), props.uploadParentItemType, uploads => {
  emit('uploadsUpdated', uploads);
});
const uploadsRemovingManager = new UploadsRemovingManager();
const uploadsSelectionManager = new UploadsSelectionManager();

const pTable = ref(null);
const showDuplicatesModal = ref(false);
const showEditMetadataModal = ref(false);
let sortedUploads: any;
let filesToBeAdded: File[];
const showUploadLimitModal = ref(false);
let limitNumberOfUploads: number;
let newUploads: number;
let totalUploads: number;
let uploadsForEditingMetadata: UploadSummaryModel[];

    props.uploadParentItemType === UploadParentItemType.selfResponse ?
        uploadsListManager.loadForSelfResponse() : uploadsListManager.load();

const tableData: ComputedRef<UploadsTableDataModel[]> = computed(() => {
  return uploadsListManager.uploads.map((u: any) => {
    return {
      id: u.uploadId,
      fileName: u.fileName,
      status: u.statusName,
      size: FileSizeFormatter.getFormattedFileSize(u.size),
      dateAdded: moment(u.addedTimestamp).format('L'),
      addedBy: u.personaDisplayName,
      path: getFilePathWithoutFileName(u),
      bytesUploaded: u.bytesUploaded,
      bytesTotal: u.size,
      numberOfUploads: u.uploads?.length,
      isFolder: u instanceof PartnerZipFolderUploadModel,
      type: u.type,
      uploadModel: u
    };
  });
});

function getFilePathWithoutFileName(upload: PartnerUploadModel | PartnerZipFolderUploadModel) {
  return upload instanceof PartnerUploadModel ?
            FilePathFormatter.getFilePathWithoutFileName(upload.fileRelativePath) :
            upload.rootFolderName;
}

const statistics = computed(() => {
  let uploadingCount = 0;
  let failedCount = 0;
  let failedUploadItemsCount = 0;
  let uploadsTotalSize = 0;
  let uploadedSize = 0;
  let totalUploadsCount = 0;
  const foldersCount = uploadsListManager.uploads.filter(u => u instanceof PartnerZipFolderUploadModel).length;
  const availableForSelection = uploadsListManager.uploads.filter(u => u.statusName !== constants.uploadStatuses.authorising).length;
  const selectedCount = uploadsSelectionManager.selectedItems.length;

  for (const upload of uploadsListManager.uploads) {
    totalUploadsCount += getUploadsNumber(upload);
    uploadingCount += upload.statusName === constants.uploadStatuses.uploading ? getUploadsNumber(upload) : 0;
    failedCount += upload.statusName === constants.uploadStatuses.failed ||
                upload.statusName === constants.uploadStatuses.failedAuthorisation ? getUploadsNumber(upload) : 0;
    failedUploadItemsCount += upload.statusName === constants.uploadStatuses.failed ||
                upload.statusName === constants.uploadStatuses.failedAuthorisation ? 1 : 0;
    uploadsTotalSize += upload.size as number;
    uploadedSize += upload.bytesUploaded as number;
  }

  return {
    uploadingCount,
    failedCount,
    failedUploadItemsCount,
    uploadsTotalSize,
    uploadedSize,
    foldersCount,
    totalUploadsCount,
    selectedCount,
    availableForSelection
  };
});

const uploadsTableHeaderTotal = computed(() => {
  return resourceHelper.getString('UploadsTableHeaderTotal', { total: statistics.value.totalUploadsCount.toString() });
});

const uploadsTableHeaderFailed = computed(() => {
  return statistics.value.failedCount ?
    resourceHelper.getString('UploadsTableHeaderFailed', { failed: statistics.value.failedCount.toString() }) : '';
});

const uploadsTableHeaderUploading = computed(() => {
  return statistics.value.uploadingCount ?
    resourceHelper.getString('UploadsTableHeaderUploading', { uploading: statistics.value.uploadingCount.toString() }) : '';
});

function selectAll() {
  tableData.value.filter(data => data.status !== constants.uploadStatuses.authorising).forEach(data => {
    uploadsSelectionManager.selectItem(data.id);
  });
}

function onFailedClick(event: Event) {
  event?.preventDefault();
  selectFailed();
}

function selectFailed() {
  tableData.value.filter(data => data.status === constants.uploadStatuses.failed ||
            data.status === constants.uploadStatuses.failedAuthorisation)
      .forEach(data => {
        uploadsSelectionManager.selectItem(data.id);
      });
}

function deselectAll() {
  uploadsSelectionManager.deselectAll();
}

function removeUploads() {
  uploadsRemovingManager.removeUploads(uploadsSelectionManager, uploadsListManager);
}

function editMetadataClick() {
  const invalidUploadsSelected = uploadsListManager.uploads.some(upload =>
    upload.statusName === constants.uploadStatuses.failed ||
      upload.statusName === constants.uploadStatuses.failedAuthorisation ||
      upload.statusName === constants.uploadStatuses.authorising ||
      upload.statusName === constants.uploadStatuses.initializing);

  if (invalidUploadsSelected) {
    logger.warning('EditMetadataProhibitedWarning');
    return;
  }

  uploadsForEditingMetadata = uploadsListManager.uploads
      .map(upload => new UploadSummaryModel(upload.uploadId, upload.fileName, upload.type,
          uploadsSelectionManager.selectedItems.includes(upload.uploadId)));

  showEditMetadataModal.value = true;
}

function getUploadedPercent(uploaded: number, totalSize: number): number {
  return Math.floor(uploaded / totalSize * 100);
}

function onSort(options: { field: string, asc: boolean }) {
  uploadsListManager.sort(options.field, options.asc);
  (pTable.value as any)?.selectPage(1);
}

function onItemSelectionChanged(id: string, value: boolean) {
  if (!value) {
    uploadsSelectionManager.deselectItem(id);
  } else {
    uploadsSelectionManager.selectItem(id);
  }
}

function addFileUploads(files: FileList) {
  const isUploadValid = validateFileUploads(files);
  if (!isUploadValid) {
    return;
  }

  const limitInfo = getUploadLimit(files);
  if (isLimitExceeded(limitInfo)) {
    displayUploadLimitModal(limitInfo);
  } else {
    const filesArray = FileListConverter.fileListToArray(files);
    const sortedFiles = requestUploadValidation.getFileDuplicates(files, uploadsListManager.uploads, props.uploadParentItemType);
    if (sortedFiles.duplicates.length) {
      sortedUploads = sortedFiles;
      filesToBeAdded = filesArray;
      showDuplicatesModal.value = true;
    } else {
      uploadsListManager.addToRepository(files);
      (pTable.value as any)?.selectPage(1);
    }
  }
}

function addFolderAsZipUploads(fileList: FileList) {
  const isCctvFolderUploadValid = validateFileUploads(fileList);
  if (!isCctvFolderUploadValid) {
    return;
  }
  const limitInfo = getUploadLimit(fileList);
  if (isLimitExceeded(limitInfo)) {
    displayUploadLimitModal(limitInfo);
  } else {
    uploadsListManager.addToRepository(fileList);
    (pTable.value as any)?.selectPage(1);
  }
}

function validateFileUploads(fileList: FileList) {
  const addUploadsValidationFailureResourceInfo =
            requestUploadValidation.getAddUploadsValidationResourceInfo(fileList, uploadsListManager.uploads, props.uploadParentItemType);

  if (addUploadsValidationFailureResourceInfo) {
    dynamicRequestDetailsErrorMessagingAdapter.setClientError(addUploadsValidationFailureResourceInfo);
    emit('uploadFailedToAdd', addUploadsValidationFailureResourceInfo.key);
    return false;
  }
  dynamicRequestDetailsErrorMessagingAdapter.setClientError(null);
  return true;
}

function getRequestId() {
  const urlParts = window.location.href.split('/');
  const requestId = urlParts[urlParts.length - 1].split('?')[0];
  return requestId;
}

function getUploadDetails(upload: any): string {
  return UploadTypeResolver.localizeUploadType(upload.type);
}

function onContinueDuplicatesModal() {
  showDuplicatesModal.value = false;
  sortedUploads = [];
  if (filesToBeAdded) {
    uploadsListManager.addToRepository(filesToBeAdded);
  }
  filesToBeAdded = [];
  (pTable.value as any)?.selectPage(1);
}

function onSkipDuplicatesModal() {
  showDuplicatesModal.value = false;

  if (sortedUploads.notDuplicates.length) {
    uploadsListManager.addToRepository(sortedUploads.notDuplicates);
    (pTable.value as any)?.selectPage(1);
  } else {
    logger.info('UploadsAreDuplicatesAndSkipped');
  }
  sortedUploads = null;
  filesToBeAdded = [];
}

function displayUploadLimitModal(limitInfo: LimitInfo) {
  if (isLimitExceeded(limitInfo)) {
    showUploadLimitModal.value = true;
    limitNumberOfUploads = limitInfo.uploadFileLimitByRequestType;
    newUploads = limitInfo.newUploads;
    totalUploads = limitInfo.totalUploads;
  }
}

function isLimitExceeded(limitInfo: LimitInfo) {
  return limitInfo.totalUploads > limitInfo.uploadFileLimitByRequestType;
}

function getUploadLimit(files: FileList) {
  return requestUploadValidation.getUploadLimit(props.uploadParentItemType, files, statistics.value.totalUploadsCount);
}

function getUploadsNumber(uploadModel: PartnerZipFolderUploadModel | PartnerUploadModel) {
  return uploadModel instanceof PartnerZipFolderUploadModel ? (uploadModel as PartnerZipFolderUploadModel).uploadsCount : 1;
}

function onPageSizeChanged(newSize: number) {
  switch (props.uploadParentItemType) {
    case UploadParentItemType.selfResponse:
      localStorageHelper.setSessionValue(LocalStorageKeyType.PaginationUploadFolderUploadsPageSize, newSize.toString());
      break;
    case UploadParentItemType.partnerRequest:
      localStorageHelper.setSessionValue(LocalStorageKeyType.PaginationPartnerRequestUploadsPageSize, newSize.toString());
      break;
  }
}
</script>

<style scoped>
    .table-header-summary {
        flex-basis: fit-content;
    }

    .table-header-selector {
        flex-grow: 1;
    }

    .uploads-table-label {
        margin-bottom: 0;
        margin-top: 0;
    }

    .table-header {
        display: flex;
        justify-content: space-between;
    }

    .table-header-button {
        margin-left: 1em;
    }

    .table-header-sort {
        padding-top: 5px;
    }

    @media screen and (max-width: 700px) {
        .table-header {
            flex-wrap: wrap;
        }

        .table-header-summary {
            flex-basis: 100%;
        }

        .table-header-button {
            margin-left: 0;
        }
    }

    @media screen and (max-width: 1500px) {
        .small-screen-hidden {
            display: none;
        }
    }
</style>