<template>
  <div class="btn-group">
    <button
      class="request-list__ellipsis-button-group dropdown-toggle"
      data-toggle="dropdown"
      :title="$localize('MoreOptionsTooltip')"
    >
      <i
        class="fas fa-ellipsis-v"
        aria-hidden="true"
      />
      <span class="sr-only">
        {{ $localize('MoreOptionsTooltip') }}
      </span>
    </button>
    <ul class="request-list__ellipsis-dropdown-menu dropdown-menu pull-right">
      <li
        v-for="(action, index) in actions"
        :key="index"
      >
        <a
          href="#"
          class="request-list__ellipsis-dropdown-item dropdown-item"
          @click.prevent="onActionClick(action)"
        >
          <div class="request-list__ellipsis-dropdown-icon">
            <i
              class="px-1"
              :class="action.icon"
              aria-hidden="true"
            />
          </div>
          <span>
            {{ action.title }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
import PaginatedListAction from '@/VueComponents/SharedComponents/PaginatedList/types/PaginatedListAction';

const props = defineProps<{
  payload: any,
  actions: PaginatedListAction[]
}>();

const emit = defineEmits(['actionClicked']);

function onActionClick(action: PaginatedListAction) {
  emit('actionClicked', action.eventName, props.payload);
}
</script>