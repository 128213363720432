import { ref, Ref } from 'vue';

export default class UploadsSelectionManager {
  private _selectedItemsDictionary: Ref<any>;

  public get selectedItems(): string[] {
    return Object.getOwnPropertyNames(this._selectedItemsDictionary.value);
  }

  constructor() {
    this._selectedItemsDictionary = ref({});
  }


  public selectItem(id: string) {
    this._selectedItemsDictionary.value[id] = true;
  }

  public deselectItem(id: string) {
    delete this._selectedItemsDictionary.value[id];
  }

  public deselectAll() {
    this._selectedItemsDictionary.value = {};
  }

  public isItemSelected(id: string) {
    return !!this._selectedItemsDictionary.value[id];
  }
}