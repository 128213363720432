<template>
  <div class="discussion-message">
    <div>
      <div class="discussion-message__icon-container">
        <i
          :class="iconClassNames"
          class="discussion-message__icon"
          aria-hidden="true"
        />
      </div>
    </div>
    <div>
      <div
        class="discussion-message__name"
      >
        {{ discussionMessage.senderDisplayName }}
      </div>
      <div class="discussion-message__date">
        <span>{{ moment(discussionMessage.sentTimestamp).format('L') }}</span>
        -
        <span>{{ moment(discussionMessage.sentTimestamp).format('LT') }}</span>
        <div v-if="discussionMessage.localMessageAcknowledged">
          (<span>{{ $localize('Sent') }}</span>)
        </div>
      </div>
      <div
        class="discussion-message__content"
      >
        {{ discussionMessage.content }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import moment from 'moment';
import DiscussionMessageModel from '@/Models/discussionMessageModel';

const props = defineProps<{
        discussionMessage: DiscussionMessageModel,
        localMessageIconClassNames: string,
        externalMessageIconClassNames: string
    }>();

const iconClassNames = props.discussionMessage.isLocalSender ?
  props.localMessageIconClassNames :
  props.externalMessageIconClassNames;

</script>

<style lang="scss" scoped>
</style>