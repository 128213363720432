import AccountSpecificLabelsProvider from '@/Utils/accountSpecificLabelsProvider';
import PortalSettingsProvider from '@/Utils/portalSettingsProvider';

import template from '@/SiteComponents/headerAccountType/headerAccountType.html';

class HeaderAccountType {
  public accountTypeStringKey: string;
  public showAccountLabel = false;

  constructor() {
    let accountLabel = 'AccountType';
    const accountType = PortalSettingsProvider.getAccountType();
    if (accountType === undefined || accountType === null) {
      accountLabel = 'Portal';
    }
    this.accountTypeStringKey = AccountSpecificLabelsProvider.getAccountLabelKey(accountLabel);
    this.showAccountLabel = true;
  }
}

export default { viewModel: HeaderAccountType, template: template };