<template>
  <nav
    class="pagination-container"
    :aria-label="$localize('TableNavigation')"
  >
    <ul class="pagination">
      <TablePageLink
        @click="selectPage(1)"
      >
        <i
          class="far fa-chevrons-left"
          aria-hidden="true"
        />
        <span class="sr-only">{{ $localize('FirstPage') }}</span>
      </TablePageLink>
      <TablePageLink
        @click="onPreviousClicked"
      >
        <i
          class="far fa-chevron-left"
          aria-hidden="true"
        />
        <span class="sr-only">{{ $localize('PreviousPage') }}</span>
      </TablePageLink>
      <TablePageLink
        v-for="item in visibleNavigationItems"
        :key="item"
        :page-number="item"
        :selected="modelValue === item"
        @click="selectPage(item)"
      />
      <TablePageLink
        @click="onNextClicked"
      >
        <i
          class="far fa-chevron-right"
          aria-hidden="true"
        />
        <span class="sr-only">{{ $localize('NextPage') }}</span>
      </TablePageLink>
      <TablePageLink
        @click="selectPage(pagesCount)"
      >
        <i
          class="far fa-chevrons-right"
          aria-hidden="true"
        />
        <span class="sr-only">{{ $localize('LastPage') }}</span>
      </TablePageLink>
    </ul>
    <div class="pagination-size-selector-container">
      <span class="mr-2">
        {{
          $localize('PaginationShowingEntries', {
            from: showingItemsMessageParameters.firstItemOrderNumber,
            to: showingItemsMessageParameters.lastItemOrderNumber,
            total: totalItems })
        }}
      </span>
      <Dropdown
        v-model="selectedPageSizeOption"
        panel-class="pagination-size-selector-dropdown"
        :options="pageSizeDropdownOptions"
        option-label="name"
        @change="onPageSizeChanged"
      />
    </div>
  </nav>
</template>

<script setup lang="ts">
import TablePageLink from '@/VueComponents/PaginatedTable/TablePageNavigation/TablePageLink.vue';
import Dropdown from 'primevue/dropdown';
import { computed, ref, watch } from 'vue';

const props = defineProps({
  pagesLinkRange: { type: Number, default: 1 },
  modelValue: { type: Number, default: 1 },
  pageSizeOptions: { type: Array, default: () => [10, 25, 50, 100] },
  pageSize: { type: Number, default: 10 },
  totalItems: { type: Number, required: true }
});

const pageSizeDropdownOptions = computed(() => {
  return props.pageSizeOptions?.map(o => {
    return { name: o.toString(), code: o };
  });
});

const pagesCount = computed(() => {
  if (!props.totalItems) {
    return 0;
  }
  return Math.ceil(props.totalItems / props.pageSize);
});

const selectedPageSizeOption = ref(getPageSizeOption(props.pageSize));
watch(() => props.pageSize, value => {
  selectedPageSizeOption.value = getPageSizeOption(value);
});

const emit = defineEmits(['update:modelValue', 'update:pageSize', 'pageChanged', 'pageSizeChanged']);

const numberOfPossiblyVisiblePages = computed(() => props.pagesLinkRange * 2 + 1);

const showingItemsMessageParameters = computed(() => {
  const firstItemOrderNumber = (props.modelValue - 1) * props.pageSize + 1;
  const lastMaximumItemOnThePage = firstItemOrderNumber + props.pageSize - 1;
  return {
    firstItemOrderNumber,
    lastItemOrderNumber: lastMaximumItemOnThePage > props.totalItems ? props.totalItems : lastMaximumItemOnThePage
  };
});

const visibleNavigationItems = computed(() => {
  const result = [];
  let startPage = props.modelValue - props.pagesLinkRange;
  let endPage = props.modelValue + props.pagesLinkRange;

  if (pagesCount.value < numberOfPossiblyVisiblePages.value) {
    startPage = 1;
    endPage = pagesCount.value as number;
  } else if (startPage < 1) {
    endPage = numberOfPossiblyVisiblePages.value;
    startPage = 1;
  } else if (endPage > pagesCount.value) {
    startPage = pagesCount.value - numberOfPossiblyVisiblePages.value + 1;
    endPage = pagesCount.value as number;
  }

  for (let i = startPage; i <= endPage; i++) {
    result.push(i);
  }
  return result;
});

function selectPage(page: number) {
  if (page === props.modelValue) {
    return;
  }

  emit('update:modelValue', page);
  emit('pageChanged', page);
}

function onPreviousClicked() {
  if (props.modelValue > 1) {
    selectPage(props.modelValue - 1);
  }
}

function onNextClicked() {
  if (props.modelValue < pagesCount.value) {
    selectPage(props.modelValue + 1);
  }
}

function onPageSizeChanged(data: any) {
  const newPageSize = data.value.code;

  emit('update:pageSize', newPageSize);
  emit('pageSizeChanged', newPageSize);

  const newPagesCount = Math.ceil(props.totalItems / newPageSize);
  if (props.modelValue > newPagesCount) {
    selectPage(newPagesCount);
  }
}

function getPageSizeOption(pageSizeValue: number | undefined) {
  return pageSizeDropdownOptions.value.find(o => o.code === pageSizeValue);
}
</script>
