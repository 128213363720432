<template>
  <div>
    <div>
      <slot name="header" />
    </div>
    <table
      ref="table"
      class="table table-responsive"
    >
      <caption
        v-if="caption"
        class="sr-only"
      >
        {{ caption }}
      </caption>
      <thead class="table-light">
        <tr>
          <th
            v-for="(field, index) in fields"
            :key="index"
            scope="col"
          >
            {{ field.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in displayedItems"
          :key="index"
        >
          <td
            v-for="(field, fieldIndex) in fields"
            :key="fieldIndex"
            :title="item[field.name]"
          >
            <slot
              :name="field.name"
              :data="item"
            >
              {{ item[field.name] }}
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
    <TablePageNavigation
      v-model="currentPage"
      v-model:page-size="pageSize"
      :pages-link-range="2"
      :total-items="items.length"
      :page-size-options="pageSizeOptions"
      @page-size-changed="onPageSizeChanged"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import TablePageNavigation from '@/VueComponents/PaginatedTable/TablePageNavigation/TablePageNavigation.vue';
import contextData from '@/contextData';

const props = defineProps({
  items: { type: Array, default: () => [] },
  fields: { type: Object, required: true },
  defaultPageSize: { type: Number, default: 10 },
  defaultPage: { type: Number, default: 1 },
  caption: { type: String, required: false, default: undefined }
});
const emit = defineEmits(['pageSizeChanged']);
defineExpose({
  selectPage
});
const currentPage = ref(props.defaultPage);
const pageSize = ref(props.defaultPageSize);
const pageSizeOptions = ref(contextData.portalSettings.paginationPageSizeOptions as number[]);
const table = ref(null);

const displayedItems = computed(() => {
  const startIndex = pageSize.value * (currentPage.value - 1);
  const endIndex = startIndex + pageSize.value;
  return props.items!.slice(startIndex, endIndex);
});

watch(displayedItems, newValue => {
  if (newValue.length === 0) {
    const lastPage = getTotalNumberOfPages();
    currentPage.value = lastPage;
  }
});

function selectPage(pageNumber: number) {
  const lastPage = getTotalNumberOfPages();

  if (pageNumber > lastPage) {
    currentPage.value = lastPage;
    return;
  }
  currentPage.value = pageNumber;
}

function onPageSizeChanged(newSize: number) {
  emit('pageSizeChanged', newSize);
}

function getTotalNumberOfPages() {
  return Math.ceil(props.items!.length / pageSize.value);
}
</script>

<style scoped>
.table {
  border: solid 1px lightgray;
  margin-bottom: 0;
}
td {
  max-width: 100px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>