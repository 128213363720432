import template from './createReportModal.html';
import { Observable } from 'knockout';
import * as ko from 'knockout';
import RequestModel from '@/Models/requestModel';
import RequestSummaryModel from '@/Models/requestSummaryModel';
import resourceHelper from '@/Utils/resourceHelper';
import { ValidationGroup } from '@/knockout.validation';
import reportRepository from '@/Repositories/reportRepository';
import logger from '@/Utils/logger';
import reportDownloader from '@/Components/createReportModal/reportDownloader';


class CreateReportModalViewModel implements CreateReportModalParams {
  request!: Observable<RequestModel | RequestSummaryModel>;
  isVisible!: Observable<boolean>;
  isGenerating!: Observable<boolean>;
  title!: Observable<string>;
  isValid!: Observable<boolean>;
  errors!: ValidationGroup;
  clientTimezoneOffset!: string;
  clientTimezoneName!: string;
  reportLinkElement!: HTMLAnchorElement | undefined;

  reportLink!: Observable<string>;
  fireClickOnDownloadReportElement!: Observable<boolean>;

  constructor(params: CreateReportModalParams) {
    Object.assign(this, params);
    this.title = ko.observable(this.request() ? reportDownloader.getDefaultReportTitle(this.request().referenceNumber) : '')
      .extend({
        required: {
          message: resourceHelper.getString('TitleRequired')
        },
        maxLength: 200
      });

    this.clientTimezoneOffset = new Date().getTimezoneOffset().toString();
    this.clientTimezoneName = new Date().toTimeString().slice(9);

    this.isVisible.subscribe(() => {
      this.title(reportDownloader.getDefaultReportTitle(this.request().referenceNumber));
    });

    this.isValid = ko.observable(true);
    this.isGenerating = ko.observable(false);

    this.errors = ko.validation.group([this.title]);
    this.errors.subscribe(newValue => this.isValid(newValue.length === 0));

    this.reportLink = ko.observable('');
    this.fireClickOnDownloadReportElement = ko.observable(false).extend({ notify: 'always' });
  }

  reportParams: ko.PureComputed<any> = ko.pureComputed(() => {
    return {
      title: this.title(),
      requestId: this.request().requestId,
      clientTimezoneOffset: this.clientTimezoneOffset,
      clientTimezoneName: this.clientTimezoneName
    };
  });

  openRequestReportURL(): void {
    this.isGenerating(true);

    reportRepository.getRequestReport(this.reportParams())
      .then(data => {
        reportDownloader.downloadReport(data, this.title());
        this.isVisible(false);
      })
      .catch(jqXhr => {
        if (jqXhr?.serverErrorMessages?.length) {
          logger.untranslatedWarning(jqXhr.serverErrorMessages[0]);
        } else {
          logger.error('FailedToGenerateRequestReport', null, jqXhr);
        }
      })
      .finally(() => {
        this.isGenerating(false);
      });
  }

  onCreateReportButtonClick = (): void => {
    if (this.isValid()) {
      this.openRequestReportURL();
    }
  };
}

export interface CreateReportModalParams {
  request: Observable<RequestModel | RequestSummaryModel>;
  isVisible: Observable<boolean>;
  isGenerating: Observable<boolean>;
}

// The default export returns the component details object to register with KO
export default { viewModel: CreateReportModalViewModel, template: template };
