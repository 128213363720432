import constants from '@/constants';

export default class KeyboardTrap {
  private trapFunction: ((e: KeyboardEvent) => void) | null = null;
  private observer: MutationObserver;
  private readonly FocusableElementsString: string =
    ` a[href],
      area[href],
      input:not([disabled]),
      select:not([disabled]),
      textarea:not([disabled]),
      button:not([disabled]),
      iframe,
      object,
      embed,
      [tabindex="0"],
      [contenteditable]`;

  constructor(private readonly modal: HTMLElement) {
    // initial set a trap
    this.setKeyboardFocusTrapForModal();

    const focusableElements = this.modal.querySelectorAll(this.FocusableElementsString);
    let focusedElement = null;
    // check whether modal has focus
    focusableElements.forEach(element => {
      if (document.activeElement === element) {
        focusedElement = element;
      }
    });

    // set focus on the first element to catch it from general form
    // keyboard trap used in base Modal class so setting focus on first element could be overriden if nessesary in child
    if (!focusedElement) {
      const firstTabStop = focusableElements[0] as HTMLInputElement;
      firstTabStop.focus();
    }

    // run observer to keep an eye on any changes in modal dialog (actually critical for ModalDialog.Vue, because slots are filled after base dialog is mounted)
    this.observer = new MutationObserver(this.setKeyboardFocusTrapForModal.bind(this));
    this.observer.observe(modal, {
      childList: true,
      subtree: true
    });
  }

  dispose() {
    this.removeEventListener();
    this.observer.disconnect();
  }

  private setKeyboardFocusTrapForModal() {
    this.removeEventListener();

    const focusableElements = this.modal.querySelectorAll(this.FocusableElementsString);
    const firstTabStop = focusableElements[0] as HTMLInputElement;
    const lastTabStop = focusableElements[focusableElements.length - 1];

    this.trapFunction = (e: KeyboardEvent) => {
      if (e.code === 'Tab' || e.keyCode === constants.keyCodes.tab) {
        if (e.shiftKey) {
          if (document.activeElement === firstTabStop) {
            this.interceptKeyEventAndSetFocus(e, lastTabStop as HTMLInputElement);
          }
        } else {
          if (document.activeElement === lastTabStop) {
            this.interceptKeyEventAndSetFocus(e, firstTabStop as HTMLInputElement);
          }
        }
      }
    };

    this.modal.addEventListener('keydown', this.trapFunction);
  }

  private interceptKeyEventAndSetFocus(e: KeyboardEvent, element: HTMLInputElement) {
    e.preventDefault();
    element.focus();
  }

  private removeEventListener() {
    if (this.trapFunction) {
      this.modal.removeEventListener('keydown', this.trapFunction);
    }
  }
}