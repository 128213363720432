import ko from 'knockout';
import requestUploadValidation from '@/Validation/requestUploadValidation';
import uploadRepository from '@/Repositories/uploadRepository';
import requestRepository from '@/Repositories/requestRepository';
import configurableFieldRepository from '@/Repositories/configurableFieldRepository';
import logger from '@/Utils/logger';
import redirectHelper from '@/Utils/redirectHelper';
import uploadManager from '@/Utils/uploadManager';
import constants from '@/constants';
import template from '@/Components/dynamicRequestDetails/dynamicRequestDetails.html';
import RequestAssigneeFormatter from '@/Utils/requestAssigneeFormatter';
import { defineReactiveProps } from '@/VueCore/utils/vueAppBuilder';
import resourceHelper from '@/Utils/resourceHelper';

import dynamicRequestDetailsErrorMessagingAdapter from '@/Utils/dynamicRequestDetailsErrorMessagingAdapter';
import { RequestItemType } from '@/Types/Enums/requestItemType';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';
import businessRepository from '@/Repositories/businessRepository';

export function DynamicRequestDetailsViewModel(routeParams) {

  const self = this;

  self.request = routeParams.request;
  self.requestItemType = self.request.requestItemType;
  self.uploadParentItemType = self.requestItemType === RequestItemType.business ?
    UploadParentItemType.businessRequest : UploadParentItemType.partnerRequest;
  self.isBusiness = self.requestItemType === RequestItemType.business;
  self.isPartner = self.requestItemType === RequestItemType.partner;

  self.dropdownZIndex = constants.dropdownZIndex;

  self.requestUploadsProps = defineReactiveProps({
    uploadParentItemType: self.uploadParentItemType,
    uploadParentItemIsCompleting: false,
    uploadParentItemIsCompleted: self.request.hasResponse,
    allowToManageMetadata: false,
    uploadParentItemIsDiscarding: false,
    uploadGuidance: self.request.dynamicCctvFolderExportExplanatoryText
  });

  self.showRequestDisclaimer = ko.observable(false);
  self.requestDisclaimerProps = defineReactiveProps({
    mainLabel: '',
    additionalOptions: []
  });

  self.onUploadsUpdated = uploads => {
    self.uploads(uploads);
  };

  self.onMessageSent = () => {
    self.discussionMessagesCount(self.discussionMessagesCount() + 1);
  };

  if (self.isPartner) {
    const isRequestCompleted = self.request.requestStatus === constants.requestStatuses.completed ||
          self.request.requestStatus === constants.requestStatuses.rejected ||
          self.request.requestStatus === constants.requestStatuses.cancelled ||
          self.request.requestStatus === constants.requestStatuses.expired ||
          self.request.requestStatus === constants.requestStatuses.failed;
    // If completed in any way: manually, rejected, failed, etc
    if (isRequestCompleted) {
      requestRepository.requestHasUploadMetadata(routeParams.requestId)
          .then(response => {
            setUploadsComponentOptions(response, isRequestCompleted);
          });
    } else {
      configurableFieldRepository.getUploadParentConfigurableFields(routeParams.requestId, self.uploadParentItemType)
          .then(fields => {
            setUploadsComponentOptions(fields.availableConfigurableFieldIds.length > 0, isRequestCompleted);
            setRequestDisclaimerOptions(fields.newlyAddedConfigurableFieldNames, fields.deletedConfigurableFieldNames);
          });
    }
  }

  self.formSaved = ko.observable(false); // Flags if save Ajax request in progress
  self.formSubmitted = ko.observable(false); // Flags if submit Ajax request in progress
  self.formRejected = ko.observable(false); // Flags if reject Ajax request in progress
  self.disableActionButtons = ko.observable(false); // Flags if Ajax buttons for action are disabled

  // Uploads use custom validation but the reason for rejection uses standard knockout validation with
  // the outcome held on the request.rejectionClientErrors property.
  self.clientError = ko.observable(null);
  self.clientUntraslatedError = ko.observable(null);
  self.serverErrors = ko.observableArray([]);

  dynamicRequestDetailsErrorMessagingAdapter.init(self.clientError, self.serverErrors);

  self.requestFormDefinition = JSON.parse(routeParams.request.dynamicRequestFormDefinition);

  self.requestFormData = routeParams.request.dynamicRequestFormData ?
    JSON.parse(routeParams.request.dynamicRequestFormData) : null;
  self.responseFormData = routeParams.request.dynamicResponseFormData ?
    JSON.parse(routeParams.request.dynamicResponseFormData) : null;

  self.responseFormDefinition = JSON.parse(routeParams.request.dynamicResponseFormDefinition);
  self.responseFormDataChange = null;
  self.responseFormDataChangedCallback = args => {
    self.responseFormDataChange = args.data;
  };

  self.camerasLoading = ko.observable(true);

  self.cameraNameObjects = ko.observableArray([]);
  if (self.isBusiness) {
    businessRepository.getCameraNames()
        .then(cameras => {
          self.cameraNameObjects(cameras);
        })
        .finally(() => {
          self.camerasLoading(false);
        });
  }

  self.uploads = ko.observableArray().extend({ rateLimit: 500 });

  self.uploadsLoadingError = ko.observable(false);
  self.uploadsLoading = ko.observable(true);

  self.loadingUploadsData = ko.computed(() => {
    return self.uploadsLoading() || self.camerasLoading();
  });

  if (self.isBusiness && !self.request.isRejected) {
    uploadRepository.getUploadsByRequestId(routeParams.requestId).then(data => {
      self.uploads(data);
    }).catch(() => {
      logger.error('FailedToRetrieveUploads');
      self.uploadsLoadingError(true);
    }).finally(() => {
      self.uploadsLoading(false);
    });
  }

  self.discussionMessagesCount = ko.observable(routeParams.discussionMessages.length);

  self.messagingProps = defineReactiveProps({
    discussionMessages: routeParams.discussionMessages,
    localMessageIconClassNames: 'fas fa-building',
    externalMessageIconClassNames: 'fas fa-user-shield',
    discussionMessagingAllowed: self.request.allowedFeatures.discussionMessaging,
    itemType: self.requestItemType,
    itemId: self.request.requestId
  });

  self.requestAssignees = routeParams.requestAssignees;
  self.selectedRequestAssignee = ko.observable(getSelectedRequestAssignee());
  self.selectedRequestAssignee.subscribe(assignee => {
    self.request.assignedPersonaId = assignee && assignee.assigneeType === constants.requestAssigneeTypes.user ? assignee.id : null;
    self.request.assignedGroupId = assignee && assignee.assigneeType === constants.requestAssigneeTypes.group ? assignee.id : null;
  });

  self.associatedFiles = ko.observableArray(routeParams.associatedFiles);
  self.hasAssociatedFiles = ko.computed(function () {
    return self.associatedFiles() && self.associatedFiles().length > 0;
  }, this);

  self.showSubmitPrompt = ko.observable(false); // Flags if the response summary and T&Cs modal is displayed
  self.showRejectPrompt = ko.observable(false); // Flags if the reject modal is displayed
  self.reasonForRejectionHasFocus = ko.observable(false);

  // Subscribe to upload manager uploads to know when another module cancels an upload
  uploadManager.uploads.subscribe(function (changes) {

    let change;
    let upload;

    for (let i = 0; i < changes.length; i++) {
      change = changes[i];
      upload = change.value;

      if (change.status === 'deleted' && upload.statusName() === constants.uploadStatuses.cancelled) {
        self.uploads.remove(upload);
      }
    }

  }, null, 'arrayChange');

  if (self.request.statusJustChangedToInProgress) {
    logger.info('RequestStatusChangedToInProgress');
  }


  self.getDuplicatedFiles = fileList => {
    return requestUploadValidation.getFileDuplicates(fileList, self.uploads(), self.uploadParentItemType);
  };

  self.addUploadsToRepository = fileList => {
    const newUploads = uploadRepository.addUploads(fileList, self.request.requestId, self.uploadParentItemType, self.addUploadsFailed);
    ko.utils.arrayPushAll(self.uploads, newUploads);
  };

  self.getUploadLimit = fileList => {
    return requestUploadValidation.getUploadLimit(self.uploadParentItemType, fileList, self.uploads().length);
  };

  self.validateFileUploads = fileList => {
    if (self.formSubmitted()) {
      logger.warning('UploadIsNotAllowedForSubmittedRequest');
      return false;
    }

    if (self.formRejected()) {
      logger.warning('UploadIsNotAllowedForRejectedRequest');
      return false;
    }

    const addUploadsValidationFailureResourceInfo =
      requestUploadValidation.getAddUploadsValidationResourceInfo(fileList, self.uploads(), self.uploadParentItemType);

    if (addUploadsValidationFailureResourceInfo) {
      self.clientError(addUploadsValidationFailureResourceInfo);
      return false;
    }
    self.clientError(null);
    return true;
  };

  self.addUploadsFailed = function (e) {

    if (e.serverErrorMessages) {
      self.serverErrors(e.serverErrorMessages);
      return;
    }

    logger.error('FailedAttemptToAddFileUpload');
  };

  self.save = function () {
    if (self.selectedRequestAssignee()?.assigneeType === constants.requestAssigneeTypes.group &&
      !self.selectedRequestAssignee()?.isGroupContactActive) {
      logger.warning('GroupNeedsRegisteredContact');
      return;
    }

    self.serverErrors([]);

    if (self.request.commentsClientErrors().length > 0) {
      self.request.commentsClientErrors.showAllMessages(true);
      return Promise.resolve(null);
    }

    const changedUploads = self.uploads().filter(upload => upload.hasChanged() && upload.uploadId === null);

    if (changedUploads && changedUploads.length && !changedUploads.every(upload => upload.isFailedAuthorisation())) {
      // Some items can be changed while authorising but without upload id yet to prevent the failure of Save operation a user is warned instead
      if (changedUploads.some(upload => !upload.isAuthorising() || !upload.isFailedAuthorisation())) {
        // Such situation is not expected. It means that in some reason upload id was completely lost.
        logger.error('UnhandledError');
      } else if (changedUploads.some(upload => upload.isAuthorising())) {
        logger.warning('SaveWhileAuthorising');
      }
      return Promise.resolve(null);
    }

    self.disableActionButtons(true);
    self.formSaved(true);

    const requestData = ko.toJS(self.request);

    return Promise.all([
      self.setSavedDynamicFields(requestData),
      requestRepository.updateRequest(requestData),
      // failed authorisation uploads don't need to be saved
      uploadRepository.updateUploads(self.uploads().filter(u => !u.isFailedAuthorisation()))
    ])
        .then(function () {
          logger.success('RequestUpdatedSummary');

          self.disableActionButtons(false);
          self.formSaved(false);
        })
        .catch(function (jqXhr) {

          self.disableActionButtons(false);
          self.formSaved(false);

          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
          } else if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnexpectedErrorWhileUpdatingRequest', null, jqXhr);
          }

          return Promise.reject(jqXhr);
        });
  };

  self.confirmSubmit = function () {
    if (self.selectedRequestAssignee().assigneeType === constants.requestAssigneeTypes.group &&
      !self.selectedRequestAssignee().isGroupContactActive) {
      logger.warning('GroupNeedsRegisteredContact');
      return;
    }

    self.serverErrors([]);
    self.clientUntraslatedError(null);

    if (self.request.commentsClientErrors().length > 0) {
      self.request.commentsClientErrors.showAllMessages(true);
      return;
    }

    const statusValidationFailureResourceInfo = requestUploadValidation.getStatusValidationResourceInfo(self.uploads(), true);

    if (statusValidationFailureResourceInfo) {
      self.clientError(statusValidationFailureResourceInfo);
      return;
    }

    self.dynamicForm.validate().then(function (isValid) {
      if (!isValid) {
        self.clientError('DynamicFormHasErrors');
        return;
      }

      self.clientError(null);
      self.showSubmitPrompt(true);
    });
  };

  self.submit = function () {

    self.showSubmitPrompt(false);
    self.disableActionButtons(true);
    self.formSubmitted(true);
    self.requestUploadsProps.uploadParentItemIsCompleting = true;

    const requestData = getRequestDataWithUploadIds(self.uploads(), self.request);
    self.setSavedDynamicFields(requestData);

    return uploadRepository.updateUploads(self.uploads()).then(function () {
      return requestRepository.updateRequestAndSubmitResponse(requestData);
    })
        .then(function () {
          logger.success('ResponseSubmittedSummary');
          redirectHelper.redirectToHash('#requests');
        })
        .catch(function (jqXhr) {

          self.disableActionButtons(false);
          self.formSubmitted(false);
          self.requestUploadsProps.uploadParentItemIsCompleting = false;

          if (jqXhr.responseJSON?.errorMessages && jqXhr.responseJSON.errorMessages.length && jqXhr.responseJSON.isUserRecoverable) {
            self.clientUntraslatedError(jqXhr.responseJSON.errorMessages[0]);
            return;
          }

          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
          } else if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnexpectedErrorWhileSubmittingRequest', null, jqXhr);
          }

          return Promise.reject(jqXhr);
        });
  };

  self.confirmReject = function () {

    const statusValidationFailureResourceInfo = requestUploadValidation
        .getStatusValidationResourceInfo(self.uploads(), true);

    if (statusValidationFailureResourceInfo && statusValidationFailureResourceInfo.key === 'UploadsStillInProgress') {
      self.clientError({ key: 'UploadStillInProgressReject' });
      return;
    }

    self.showRejectPrompt(true);
  };

  self.setReasonForRejectionFocus = function () {
    self.reasonForRejectionHasFocus(true);
  };

  self.reject = function () {

    if (self.request.rejectionClientErrors().length > 0) {
      self.request.rejectionClientErrors.showAllMessages(true);
      return;
    }

    self.showRejectPrompt(false);
    self.disableActionButtons(true);
    self.formRejected(true);
    self.requestUploadsProps.uploadParentItemIsDiscarding = true;

    self.request.requestStatus = constants.requestStatuses.rejected;
    self.request.rejectionReason = self.request.reasonForRejection();
    const requestData = ko.toJS(self.request);
    self.setSavedDynamicFields(requestData);

    requestRepository.updateRequestAndSubmitResponse(requestData)
        .then(() => {
          logger.success('ResponseSubmittedSummary');
          redirectHelper.redirectToHash('#requests');
        }).catch(jqXhr => {

          self.disableActionButtons(false);
          self.formRejected(false);
          self.requestUploadsProps.uploadParentItemIsDiscarding = false;

          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
          } else if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnexpectedErrorWhileSubmittingRequest', null, jqXhr);
          }
          return Promise.reject(jqXhr);
        });
  };

  self.removeUpload = function (upload) {
    if (Array.isArray(upload)) {
      return uploadRepository.deleteUploads(upload)
          .then(() => {
            ko.utils.arrayForEach(upload, function (item) {
              self.uploads.remove(item);
            });
          })
          .catch(function (jqXhr) {
            if (!jqXhr.errorHasBeenLogged) {
              logger.error('FailedToDeleteFileUpload', null, jqXhr);
            }

            throw jqXhr;
          });

    } else {
      return uploadRepository.deleteUpload(upload)
          .then(() => {
            self.uploads.remove(upload);
          })
          .catch(function (jqXhr) {

            if (!jqXhr.errorHasBeenLogged) {
              logger.error('FailedToDeleteFileUpload', null, jqXhr);
            }

            throw jqXhr;
          });
    }
  };

  /**
   * Convert view model to a standard JS object and include upload Ids property as required for submit
   * @param {Array} uploadsArray
   * @param {Object} request
   * @return{Object}
   */
  function getRequestDataWithUploadIds(uploadsArray, request) {

    let uploadIds = uploadsArray.map(function (upload) {
      return upload.uploadId;
    });

    // Remove the upload Ids where they are null due to an upload failing authorisation
    uploadIds = uploadIds.filter(function (uploadId) {
      return uploadId !== null;
    });

    const requestData = ko.toJS(request);
    requestData.submittedUploadIds = uploadIds;
    return requestData;
  }

  self.setSavedDynamicFields = function (requestDataModel) {
    // Remove data that doesn't need to be submitted
    requestDataModel.dynamicRequestFormDefinition = null;
    requestDataModel.dynamicRequestFormData = null;
    requestDataModel.dynamicResponseFormDefinition = null;
    requestDataModel.dynamicResponseAllowUploads = null;
    requestDataModel.dynamicResponseUploadsIncludeCameraSelection = null;
    requestDataModel.dynamicResponseConfirmationText = null;

    // Set dynamic data for submission if there're any changes
    if (self.responseFormDataChange) {
      requestDataModel.dynamicResponseFormData = JSON.stringify(self.responseFormDataChange);
    }
  };

  self.renderObject = {
    option: (data, escape) => {
      const container = document.createElement('div');
      const templateParameters =
      {
        optionText: escape(data.name),
        iconClass: RequestAssigneeFormatter.getRequestAssigneeIconClass(data),
        title: RequestAssigneeFormatter.getRequestAssigneeTitle(data)
      };
      ko.renderTemplate('option-template', templateParameters, null, container, 'replaceChildren');
      return container.innerHTML;
    }
  };

  function getSelectedRequestAssignee() {
    if (self.request.assignedPersonaId) {
      return self.requestAssignees.find(item => {
        return item.assigneeType === constants.requestAssigneeTypes.user && item.id === self.request.assignedPersonaId;
      }) || {};
    }

    if (self.request.assignedGroupId) {
      return self.requestAssignees.find(item => {
        return item.assigneeType === constants.requestAssigneeTypes.group && item.id === self.request.assignedGroupId;
      }) || {};
    }

    return {};
  }

  function setUploadsComponentOptions(allowMetadata, isRequestCompleted) {
    self.requestUploadsProps.uploadParentItemType = self.uploadParentItemType;
    self.requestUploadsProps.uploadParentItemIsCompleted = isRequestCompleted;
    self.requestUploadsProps.uploadParentItemIsCompleting = false;
    self.requestUploadsProps.allowToManageMetadata = allowMetadata;
    self.requestUploadsProps.uploadParentItemIsDiscarding = false;
  }

  function setRequestDisclaimerOptions(newFields, deletedFields) {
    const options = newFields.map(fieldName => {
      return resourceHelper.getString('ConfigurableFieldWasAdded', { '0': fieldName });
    });
    options.push(...deletedFields.map(fieldName => {
      return resourceHelper.getString('ConfigurableFieldWasRemoved', { '0': fieldName });
    }));

    if (options.length > 0) {
      self.showRequestDisclaimer(true);
      self.requestDisclaimerProps.mainLabel = resourceHelper.getString('RequestMetadataDisclaimer', { '0': options.length });
      self.requestDisclaimerProps.additionalOptions = options;
    }
  }
}

export default { viewModel: DynamicRequestDetailsViewModel, template: template };
