<script setup lang="ts">
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
const emit = defineEmits < { 'close', 'continue' }>();
defineProps({
  numberOfUploadFiles: { type: Number, default: 0 },
  confirmationText: { type: String, default: '' }
});

function onModalClose() {
  emit('close');
}

</script>

<template>
  <ModalDialog
    :title="$localize('ConfirmResponseModalTitle')"
    @close="onModalClose"
  >
    <template #default>
      <div>
        <strong> {{ $localize('UploadFolderSubmitConfirmMessage', { numberOfUploadFiles }) }}</strong>
      </div>
      <hr>
      <div class="mt-3">
        {{ confirmationText }}
      </div>
    </template>
    <template #footer>
      <button
        ref="closeBtn"
        class="btn btn-default"
        @click="onModalClose"
      >
        {{ $localize('Cancel') }}
      </button>
      <button
        class="btn btn-primary"
        @click="emit('continue')"
      >
        {{ $localize('Continue') }}
      </button>
    </template>
  </ModalDialog>
</template>
