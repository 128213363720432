import PartnerUploadModel from '@/Models/partnerUploadModel';
import PartnerZipFolderUploadModel from '@/Models/partnerZipFolderUploadModel';
import uploadRepository from '@/Repositories/uploadRepository';
import { ref, Ref, toRaw } from 'vue';
import UploadListManager from '@/VueComponents/Uploads/utils/uploadListManager';
import logger from '@/Utils/logger';
import UploadsSelectionManager from '@/VueComponents/Uploads/utils/uploadsSelectionManager';

class UploadsRemovingManager {
  private _isRemovingRef: Ref<boolean>;

  public get isRemoving() {
    return this._isRemovingRef.value;
  }

  constructor() {
    this._isRemovingRef = ref(false);
  }

  removeUploads(selectionManager: UploadsSelectionManager, uploadsManager: UploadListManager) {
    const uploadsToDelete: PartnerUploadModel[] = [];
    const selectedUploads = uploadsManager.uploads.filter(u => selectionManager.selectedItems.some(id => id === u.uploadId));

    for (const uploadModel of selectedUploads) {
      if (uploadModel instanceof PartnerZipFolderUploadModel) {
        uploadsToDelete.push(...(uploadModel as PartnerZipFolderUploadModel).uploads);
      } else {
        uploadsToDelete.push(uploadModel);
      }
    }

    this._isRemovingRef.value = true;

    uploadRepository.deleteUploads(uploadsToDelete.map(u => toRaw(u.uploadManagerUploadModel))).then(() => {
      logger.success('UploadsSuccessfullyRemoved');
      const newArray = [];
      for (const upload of uploadsManager.uploads) {
        if (selectedUploads.indexOf(upload) === -1) {
          newArray.push(upload);
        }
      }
      uploadsManager.uploads = newArray;
      selectionManager.deselectAll();
      uploadsManager.triggerUploadsUpdated();
    }).catch(() => {
      logger.error('FailedToRemoveUploads');
    }).finally(() => {
      this._isRemovingRef.value = false;
    });
  }
}

export default UploadsRemovingManager;