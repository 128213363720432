<script setup lang="ts">
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
import resourceHelper from '@/Utils/resourceHelper';
import { UploadOption } from '@/Types/Enums/uploadOption';

const props = defineProps<{
    zipFilesCount: number | undefined,
    nonZipFilesCount: number | undefined,
    fileList: File[],
    uploadOption: string
}>();

const emit = defineEmits<{ 'cancel', 'continue' }>();

function onModalClose() {
  emit('cancel');
}

function onModalContinue() {
  emit('continue');
}

let warningTitle = '';
let warningPara1 = '';
let warningPara2 = '';

if (props.uploadOption === UploadOption.selectZipFiles && props.nonZipFilesCount && props.nonZipFilesCount > 1) {
  warningTitle = resourceHelper.getString('NonZipFilesWarningTitle', { filesCount: props.nonZipFilesCount });
  warningPara1 = resourceHelper.getString('NonZipFilesWarningPara1', { filesCount: props.nonZipFilesCount });
  warningPara2 = resourceHelper.getString('NonZipFilesWarningPara2', { filesCount: props.nonZipFilesCount });
} else if (props.uploadOption === UploadOption.selectZipFiles && props.nonZipFilesCount && props.nonZipFilesCount === 1) {
  warningTitle = resourceHelper.getString('NonZipFileWarningTitle');
  warningPara1 = resourceHelper.getString('NonZipFileWarningPara1');
  warningPara2 = resourceHelper.getString('NonZipFileWarningPara2');
} else {
  if (props.zipFilesCount && props.zipFilesCount > 1) {
    warningTitle = resourceHelper.getString('ZipFilesWarningTitle', { filesCount: props.zipFilesCount });
    warningPara1 = resourceHelper.getString('ZipFilesWarningPara1', { filesCount: props.zipFilesCount });
    warningPara2 = resourceHelper.getString('ZipFilesWarningPara2', { filesCount: props.zipFilesCount });
  } else {
    warningTitle = resourceHelper.getString('ZipFileWarningTitle');
    warningPara1 = resourceHelper.getString('ZipFileWarningPara1');
    warningPara2 = resourceHelper.getString('ZipFileWarningPara2');
  }
}
</script>

<template>
  <ModalDialog
    :title="warningTitle"
    @close="onModalClose"
  >
    <template #default>
      <div>
        {{ warningPara1 }}
      </div>
      <br>
      <div>
        {{ warningPara2 }}
      </div>
    </template>
    <template #footer>
      <button
        class="btn btn-default"
        @click="onModalClose"
      >
        {{ $localize('Cancel') }}
      </button>
      <button
        class="btn btn-primary"
        @click="onModalContinue"
      >
        {{ $localize('Continue') }}
      </button>
    </template>
  </ModalDialog>
</template>
