import ko from 'knockout';
import requestUploadValidation from '@/Validation/requestUploadValidation';
import requestRepository from '@/Repositories/requestRepository';
import uploadRepository from '@/Repositories/uploadRepository';
import logger from '@/Utils/logger';
import redirectHelper from '@/Utils/redirectHelper';
import uploadManager from '@/Utils/uploadManager';
import constants from '@/constants';
import template from '@/Components/requestDetails/requestDetails.html';
import RequestAssigneeFormatter from '@/Utils/requestAssigneeFormatter';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';
import businessRepository from '@/Repositories/businessRepository';

export function RequestDetailsViewModel(routeParams) {

  const self = this;

  self.formSaved = ko.observable(false); // Flags if save Ajax request in progress
  self.formSubmitted = ko.observable(false); // Flags if submit Ajax request in progress
  self.formRejected = ko.observable(false); // Flags if reject Ajax request in progress
  self.disableActionButtons = ko.observable(false); // Flags if Ajax buttons for action are disabled
  self.uploadParentItemType = UploadParentItemType.businessRequest;

  self.dropdownZIndex = constants.dropdownZIndex;

  // Uploads use custom validation but the reason for rejection uses standard knockout validation with
  // the outcome held on the request.rejectionClientErrors property.
  self.uploadClientError = ko.observable(null);
  self.serverErrors = ko.observableArray([]);

  self.request = routeParams.request;

  self.cameraNameObjects = ko.observableArray([]);
  businessRepository.getCameraNames()
      .then(cameras => {
        self.cameraNameObjects(cameras);
      });

  self.uploads = ko.observableArray().extend({ rateLimit: 500 });

  self.uploadsLoadingError = ko.observable(false);
  self.uploadsLoading = ko.observable(true);
  uploadRepository.getUploadsByRequestId(routeParams.requestId).then(data => {
    self.uploads(data);
  }).catch(() => {
    logger.error('FailedToRetrieveUploads');
    self.uploadsLoadingError(true);
  }).finally(() => {
    self.uploadsLoading(false);
  });

  self.requestAssignees = routeParams.requestAssignees;
  self.selectedRequestAssignee = ko.observable(getSelectedRequestAssignee());
  self.selectedRequestAssignee.subscribe(assignee => {
    self.request.assignedPersonaId = assignee && assignee.assigneeType === constants.requestAssigneeTypes.user ? assignee.id : null;
    self.request.assignedGroupId = assignee && assignee.assigneeType === constants.requestAssigneeTypes.group ? assignee.id : null;
  });

  self.associatedFiles = ko.observableArray(routeParams.associatedFiles);
  self.hasAssociatedFiles = ko.computed(function () {
    return self.associatedFiles() && self.associatedFiles().length > 0;
  }, this);

  self.showSubmitPrompt = ko.observable(false); // Flags if the response summary and T&Cs modal is displayed
  self.showRejectPrompt = ko.observable(false); // Flags if the reject modal is displayed
  self.reasonForRejectionHasFocus = ko.observable(false);
  self.showCreateReportModal = ko.observable(false);

  self.selectedRequestObservable = ko.observable(routeParams.request);

  self.onCreateReportClick = () => {
    self.showCreateReportModal(!self.showCreateReportModal());
  };

  // Subscribe to upload manager uploads to know when another module cancels an upload
  uploadManager.uploads.subscribe(function (changes) {

    let change;
    let upload;

    for (let i = 0; i < changes.length; i++) {
      change = changes[i];
      upload = change.value;

      if (change.status === 'deleted' && upload.statusName() === constants.uploadStatuses.cancelled) {
        self.uploads.remove(upload);
      }
    }

  }, null, 'arrayChange');

  if (self.request.statusJustChangedToInProgress) {
    logger.info('RequestStatusChangedToInProgress');
  }

  self.addFileUploads = fileList => {

    const isUploadValid = self.validateFileUploads(fileList);

    if (!isUploadValid) {
      return;
    }
    self.addUploadsToRepository(fileList);
  };

  self.addUploadsToRepository = fileList => {
    const newUploads = uploadRepository.addUploads(fileList, self.request.requestId, UploadParentItemType.businessRequest, self.addUploadsFailed);
    ko.utils.arrayPushAll(self.uploads, newUploads);
  };

  self.validateFileUploads = fileList => {
    if (self.formSubmitted()) {
      logger.warning('UploadIsNotAllowedForSubmittedRequest');
      return false;
    }

    if (self.formRejected()) {
      logger.warning('UploadIsNotAllowedForRejectedRequest');
      return false;
    }

    const addUploadsValidationFailureResourceInfo =
      requestUploadValidation.getAddUploadsValidationResourceInfo(fileList, self.uploads(), UploadParentItemType.businessRequest);

    if (addUploadsValidationFailureResourceInfo) {
      self.uploadClientError(addUploadsValidationFailureResourceInfo);
      return false;
    }
    self.uploadClientError(null);
    return true;
  };

  self.addUploadsFailed = function (e) {

    if (e.serverErrorMessages) {
      self.serverErrors(e.serverErrorMessages);
      return;
    }

    logger.error('FailedAttemptToAddFileUpload');
  };

  self.save = function () {
    if (self.selectedRequestAssignee().assigneeType === constants.requestAssigneeTypes.group &&
      !self.selectedRequestAssignee().isGroupContactActive) {
      logger.warning('GroupNeedsRegisteredContact');
      return;
    }

    self.serverErrors([]);

    if (self.request.commentsClientErrors().length > 0) {
      self.request.commentsClientErrors.showAllMessages(true);
      return Promise.resolve(null);
    }

    const changedUploads = self.uploads().filter(upload => upload.hasChanged() && upload.uploadId === null);

    if (changedUploads && changedUploads.length && !changedUploads.every(upload => upload.isFailedAuthorisation())) {
      // Some items can be changed while authorising but without upload id yet to prevent the failure of Save operation a user is warned instead
      if (changedUploads.some(upload => !upload.isAuthorising() || !upload.isFailedAuthorisation())) {
        // Such situation is not expected. It means that in some reason upload id was completely lost.
        logger.error('UnhandledError');
      } else if (changedUploads.some(upload => upload.isAuthorising())) {
        logger.warning('SaveWhileAuthorising');
      }
      return Promise.resolve(null);
    }

    self.disableActionButtons(true);
    self.formSaved(true);

    const requestData = ko.toJS(self.request);

    return Promise.all([
      requestRepository.updateRequest(requestData),
      // failed authorisation uploads don't need to be saved
      uploadRepository.updateUploads(self.uploads().filter(u => !u.isFailedAuthorisation()))
    ])
        .then(function () {
          logger.success('RequestUpdatedSummary');

          self.disableActionButtons(false);
          self.formSaved(false);
        })
        .catch(function (jqXhr) {

          self.disableActionButtons(false);
          self.formSaved(false);

          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
          } else if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnexpectedErrorWhileUpdatingRequest', null, jqXhr);
          }

          return Promise.reject(jqXhr);
        });
  };

  self.confirmSubmit = function () {
    if (self.selectedRequestAssignee().assigneeType === constants.requestAssigneeTypes.group &&
      !self.selectedRequestAssignee().isGroupContactActive) {
      logger.warning('GroupNeedsRegisteredContact');
      return;
    }

    self.serverErrors([]);

    if (self.request.commentsClientErrors().length > 0) {
      self.request.commentsClientErrors.showAllMessages(true);
      return;
    }

    const statusValidationFailureResourceInfo = requestUploadValidation.getStatusValidationResourceInfo(self.uploads());

    if (statusValidationFailureResourceInfo) {
      self.uploadClientError(statusValidationFailureResourceInfo);
      return;
    }

    self.uploadClientError(null);
    self.showSubmitPrompt(true);
  };

  self.submit = function () {

    self.showSubmitPrompt(false);
    self.disableActionButtons(true);
    self.formSubmitted(true);

    const requestData = getRequestDataWithuploadIds(self.uploads(), self.request);

    return uploadRepository.updateUploads(self.uploads())
        .then(() => {
          requestRepository.updateRequestAndSubmitResponse(requestData)
              .then(() => {
                logger.success('ResponseSubmittedSummary');
                redirectHelper.redirectToHash('#requests');
              });
        })
        .catch(function (jqXhr) {

          self.disableActionButtons(false);
          self.formSubmitted(false);

          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
          } else if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnexpectedErrorWhileSubmittingRequest', null, jqXhr);
          }
          return Promise.reject(jqXhr);
        });
  };

  self.confirmReject = function () {

    const statusValidationFailureResourceInfo = requestUploadValidation.getStatusValidationResourceInfo(self.uploads());

    if (statusValidationFailureResourceInfo && statusValidationFailureResourceInfo.key === 'UploadsStillInProgress') {
      self.uploadClientError({ key: 'UploadStillInProgressReject' });
      return;
    }

    self.showRejectPrompt(true);
  };

  self.setReasonForRejectionFocus = function () {
    self.reasonForRejectionHasFocus(true);
  };

  self.reject = function () {

    if (self.request.rejectionClientErrors().length > 0) {
      self.request.rejectionClientErrors.showAllMessages(true);
      return;
    }

    self.showRejectPrompt(false);
    self.disableActionButtons(true);
    self.formRejected(true);
    self.request.rejectionReason = self.request.reasonForRejection();

    uploadRepository.deleteUploads(self.uploads())
        .then(() => {
          self.request.requestStatus = constants.requestStatuses.rejected;
          const requestData = ko.toJS(self.request);

          requestRepository.updateRequestAndSubmitResponse(requestData)
              .then(() => {
                logger.success('ResponseSubmittedSummary');
                redirectHelper.redirectToHash('#requests');
              });
        })
        .catch(jqXhr => {
          self.disableActionButtons(false);
          self.formRejected(false);

          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
          } else if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnexpectedErrorWhileSubmittingRequest', null, jqXhr);
          }

          return Promise.reject(jqXhr);
        });
  };

  self.removeUpload = function (upload) {
    if (Array.isArray(upload)) {
      return uploadRepository.deleteUploads(upload)
          .then(() => {
            ko.utils.arrayForEach(upload, function (item) {
              self.uploads.remove(item);
            });
          })
          .catch(function (jqXhr) {

            if (!jqXhr.errorHasBeenLogged) {
              logger.error('FailedToDeleteFileUpload', null, jqXhr);
            }

            throw jqXhr;
          });
    } else {
      return uploadRepository.deleteUpload(upload)
          .then(() => {
            self.uploads.remove(upload);
          })
          .catch(function (jqXhr) {

            if (!jqXhr.errorHasBeenLogged) {
              logger.error('FailedToDeleteFileUpload', null, jqXhr);
            }

            throw jqXhr;
          });

    }
  };

  /**
   * Convert view model to a standard JS object and include upload Ids property as required for submit
   * @param {Array} uploadsArray
   * @param {Object} request
   * @return{Object}
   */
  function getRequestDataWithuploadIds(uploadsArray, request) {

    let uploadIds = uploadsArray.map(function (upload) {
      return upload.uploadId;
    });

    // Remove the upload Ids where they are null due to an upload failing authorisation
    uploadIds = uploadIds.filter(function (uploadId) {
      return uploadId !== null;
    });

    const requestData = ko.toJS(request);
    requestData.submittedUploadIds = uploadIds;
    return requestData;
  }

  self.renderObject = {
    option: (data, escape) => {
      const container = document.createElement('div');
      const templateParameters =
      {
        optionText: escape(data.name),
        iconClass: RequestAssigneeFormatter.getRequestAssigneeIconClass(data),
        title: RequestAssigneeFormatter.getRequestAssigneeTitle(data)
      };
      ko.renderTemplate('option-template', templateParameters, null, container, 'replaceChildren');
      return container.innerHTML;
    }
  };

  self.getUploadLimit = fileList => {
    return requestUploadValidation.getUploadLimit(UploadParentItemType.businessRequest, fileList, self.uploads().length);
  };

  self.getDuplicatedFiles = fileList => {
    return requestUploadValidation.getFileDuplicates(fileList, self.uploads(), UploadParentItemType.businessRequest);
  };

  function getSelectedRequestAssignee() {
    if (self.request.assignedPersonaId) {
      return self.requestAssignees.find(item => {
        return item.assigneeType === constants.requestAssigneeTypes.user && item.id === self.request.assignedPersonaId;
      }) || {};
    }

    if (self.request.assignedGroupId) {
      return self.requestAssignees.find(item => {
        return item.assigneeType === constants.requestAssigneeTypes.group && item.id === self.request.assignedGroupId;
      }) || {};
    }

    return {};
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: RequestDetailsViewModel, template: template };

