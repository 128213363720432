<template>
  <div>
    <UploadFolderHeader
      :response="response"
      :status="responseStatus"
      :discussion-messaging-allowed="discussionMessagingAllowed"
      :is-saving="isSaving"
      :is-deleting="isDeleting"
      :save-response-func="saveResponseFunc"
      :delete-response-func="showDeleteModal"
      :report-func="showReportModal"
      :is-submitting="isSubmitting"
      :submit-response-func="submitResponseFunc"
      :disable-action-buttons="disableActionButtons"
      :hide-action-buttons="!isResponseActive"
      :discussion-message-count="discussionMessageCount"
    />
    <RequestDisclaimer
      v-if="showResponseDisclaimer"
      :main-label="reponseDisclaimerMainLabel"
      :additional-options="responseDisclaimerOptions"
    />
    <div class="request-details__container">
      <div
        class="request-details__main-content request-details__main-content--discussion-allowed"
      >
        <div class="content-container">
          <h3>{{ $localize('ResponseDetails') }}</h3>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label
                  for="assignee"
                  class="control-label"
                >{{ $localize('ResponseAssignee') }}</label>
                <AutoComplete
                  id="assignee"
                  v-model="selectedAssignee"
                  :options="filteredAssignees"
                  option-label="name"
                  :disabled="!isResponseActive"
                  :option-disabled="'isDisabled'"
                  :dropdown="true"
                  :placeholder="$localize('UploadFolderAssignedToPlaceholder')"
                  :overlay-z-index="constants.dropdownZIndex"
                  @update:model-value="onAssigneeChange"
                  @search="searchAssignee"
                >
                  <template
                    #option="{ option }"
                  >
                    <div
                      class="option row dropdown-option"
                      :title="RequestAssigneeFormatter.getRequestAssigneeTitle(option)"
                    >
                      <div class="col-xs-1">
                        <i
                          :class="RequestAssigneeFormatter.getRequestAssigneeIconClass(option)"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="col-xs-11">
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </template>
                </AutoComplete>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">{{ $localize('Priority') }}</label>
                <select
                  v-model="priority"
                  class="form-control"
                  :class="{'disabled': !isResponseActive}"
                  :disabled="!isResponseActive"
                >
                  <option :value="SelfResponsePriority.none">
                    {{ $localize('SelfResponsePriorityNone') }}
                  </option>
                  <option
                    :value="SelfResponsePriority.medium"
                  >
                    {{ $localize('SelfResponsePriorityMedium') }}
                  </option>
                  <option :value="SelfResponsePriority.high">
                    {{ $localize('SelfResponsePriorityHigh') }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">{{ $localize('InternalReferenceNumber') }}</label>
                <input
                  v-model="internalReferenceNumber"
                  class="form-control"
                  :class="{
                    'disabled': !isResponseActive,
                    'has-error': isInternalReferenceNumberExceedsMaxLength
                  }"
                  :disabled="!isResponseActive"
                  :placeholder="$localize('InternalReferencePlaceholder')"
                  :aria-invalid="isInternalReferenceNumberExceedsMaxLength"
                >
                <div
                  v-if="isInternalReferenceNumberExceedsMaxLength"
                  class="help-block error-text"
                >
                  {{ $localize('InternalReferenceNumberExceedsMaxLength') }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-6">
              <div class="form-group">
                <label class="control-label">{{ $localize('RmsId') }}</label>
                <RmsIdSelector
                  ref="rmsIdComponent"
                  v-model="selectedRmsId"
                  :disabled="!isResponseActive"
                  :overlay-z-index="constants.dropdownZIndex"
                />
              </div>
            </div>
            <div class="col-xs-6">
              <div class="form-group">
                <label class="control-label">{{ $localize('CadIds') }}</label>
                <CadIdsSelector
                  ref="cadIdsComponent"
                  v-model="selectedCadIds"
                  :disabled="!isResponseActive"
                  :overlay-z-index="constants.dropdownZIndex"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <DynamicForm
                v-model="dynamicResponseFormData"
                class="dynamic-form"
                :form-definition="dynamicResponseFormDefinition"
                :is-read-only="!isResponseActive"
                :disable-validation="false"
                @update:model-value="onFormChange"
              />
            </div>
          </div>
          <RequestUploads
            ref="requestUploads"
            :upload-parent-item-type="UploadParentItemType.selfResponse"
            :upload-parent-item-is-completed="isResponseCompleted"
            :upload-parent-item-is-discarding="isDeleting"
            :upload-parent-item-is-completing="isSubmitting"
            :allow-to-manage-metadata="showMetadataButton"
            :upload-guidance="response.dynamicResponseUploadGuidance"
            @uploads-updated="uploadsUpdated"
            @upload-failed-to-add="handleFailedUpload"
          />

          <div>
            <div
              v-if="showSubmitValidationErrors"
              id="errorValidationMessages"
              class="validation-summary"
              aria-live="assertive"
            >
              <h5><strong>{{ $localize('UploadValidationFailed') }}</strong></h5>
              <ul>
                <li>{{ submitErrorMessage }}</li>
              </ul>
              <br>
            </div>
          </div>
        </div>
      </div>

      <div
        class="request-details__vertical-divider request-details__vertical-divider--discussion-allowed"
      />

      <aside
        class="request-details__discussion request-details__discussion--discussion-allowed"
      >
        <div class="content-container">
          <DiscussionMessaging
            :discussion-messages="discussionMessages"
            :local-message-icon-class-names="'fas fa-building'"
            :external-message-icon-class-names="'fas fa-user-shield'"
            :discussion-messaging-allowed="discussionMessagingAllowed"
            :disable-validation="false"
            :item-type="DiscussionItemType.selfResponse"
            :item-id="responseId"
            @message-sent="onMessageSent"
          />
        </div>
      </aside>
    </div>
  </div>

  <DeleteUploadFolderModal
    v-if="deleteModalVisible"
    :is-danger="true"
    @on-delete="deleteResponseFunc"
    @close="deleteModalCloseFunc"
  />
  <CreateReportModal
    v-if="reportModalVisible"
    :is-danger="false"
    :report-item-reference-number="response.referenceNumber"
    :report-item-id="response.id"
    :item-type="CreateReportModalTypes.uploadFolder"
    @close="createReportModalCloseFunc"
  />
  <ConfirmSubmittingUploadFolderModal
    v-if="confirmSubmittingModalVisible"
    :confirmation-text="submitConfirmationText"
    :number-of-upload-files="uploads.length"
    @close="closeSubmittingModalDialog"
    @continue="onContinueSubmitting"
  />
</template>

<script setup lang="ts">
import SelfResponseModel from '@/Models/selfResponseModel';
import UploadFolderHeader from '@/VueComponents/UploadFolders/UploadFolderHeader.vue';
import RequestUploads from '@/VueComponents/Uploads/RequestUploads.vue';
import RequestDisclaimer from '@/VueComponents/RequestDisclaimer/RequestDisclaimer.vue';
import { computed, Ref, ref } from 'vue';
import DynamicForm from '@/VueComponents/DynamicForm/DynamicForm.vue';
import SelfResponseRepository from '@/Repositories/selfResponseRepository';
import constants from '@/constants';
import redirectHelper from '@/Utils/redirectHelper';
import logger from '@/Utils/logger';
import DeleteUploadFolderModal from '@/VueComponents/Modals/DeleteUploadFolderModal.vue';
import CreateReportModal from '@/VueComponents/Modals/CreateReportModal.vue';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';
import UploadModel from '@/Models/uploadModel';
import ConfirmSubmittingUploadFolderModal from '@/VueComponents/Modals/ConfirmSubmittingUploadFolderModal.vue';
import AutoComplete from '@/VueComponents/SharedComponents/AutoComplete.vue';
import RmsIdSelector from '@/VueComponents/RmsIdSelector/RmsIdSelector.vue';
import CadIdsSelector from '@/VueComponents/CadIdsSelector/CadIdsSelector.vue';
import RequestAssigneeFormatter from '@/Utils/requestAssigneeFormatter';
import { SelfResponsePriority } from '@/Types/Enums/selfResponsePriority';
import { CreateReportModalTypes } from '@/Types/Enums/createReportModalTypes';

import resourceHelper from '@/Utils/resourceHelper';
import ConfigurableFieldRepository from '@/Repositories/configurableFieldRepository';
import ConfigurableFieldsModel from '@/Models/configurableFieldsModel';
import DiscussionMessaging from '@/VueComponents/DiscussionMessaging/DiscussionMessaging.vue';
import { DiscussionItemType } from '@/Types/Enums/discussionItemType';
import RequestAssigneeSummaryModel from '@/Models/requestAssigneeSummaryModel';

const props = defineProps<{
        response: SelfResponseModel,
        responseId: string,
        responseAssignees: RequestAssigneeSummaryModel[],
        discussionMessages: Array<DiscussionMessaging>
}>();

const requestUploads = ref(null);
const responseStatus = ref(props.response.selfResponseStatusForDisplay);
const uploads: Ref<UploadModel[]> = ref([]);
const discussionMessageCount = ref(props.discussionMessages.length);
const selectedAssignee = ref(props.responseAssignees
    .find(x => x.id === (props.response.assignedPersonaId || props.response.assignedGroupId)));

const filteredAssignees = ref([]);
const dynamicResponseFormData = ref(JSON.parse(props.response.dynamicResponseFormData));
const dynamicResponseFormDefinition = JSON.parse(props.response.dynamicResponseFormDefinition);

const isResponseActive = computed(() => {
  return props.response.responseStatus == constants.selfResponseStatuses.inProgress;
});
const isResponseCompleted = computed(() => {
  return props.response.responseStatus === constants.selfResponseStatuses.completed ||
      props.response.responseStatus === constants.selfResponseStatuses.failed ||
      props.response.responseStatus === constants.selfResponseStatuses.deleted ||
      props.response.responseStatus === constants.selfResponseStatuses.sending;
});

const discussionMessagingAllowed = computed(() => {
  return props.response.responseStatus == constants.selfResponseStatuses.completed;
});

const disableActionButtons = computed(() => {
  return isSaving.value || isDeleting.value || isSubmitting.value;
});
const isSaving = ref(false);
const isDeleting = ref(false);
const isSubmitting = ref(false);
const deleteModalVisible = ref(false);
const reportModalVisible = ref(false);
const confirmSubmittingModalVisible = ref(false);
const submitConfirmationText = ref('');
const isFormValid = ref(true);
const showSubmitValidationErrors = ref(false);
const submitErrorMessage = ref('');
const internalReferenceNumber = ref(props.response.internalReferenceNumber);
const priority = ref(props.response.responsePriority);
const assignedPersonaId = ref(props.response.assignedPersonaId);
const assignedGroupId = ref(props.response.assignedGroupId);
const showMetadataButton = ref(false);
const showResponseDisclaimer = ref(false);
const reponseDisclaimerMainLabel = ref('');
const responseDisclaimerOptions = ref(['']);
const selectedCadIds: Ref<string[]> = ref(props.response.cadIds);
const selectedRmsId = ref(props.response.rmsId);
const rmsIdComponent = ref(null);
const cadIdsComponent = ref(null);

const isInternalReferenceNumberExceedsMaxLength = computed(() => {
  return internalReferenceNumber.value?.trim().length > 50;
});

if (isResponseActive.value) {
  ConfigurableFieldRepository.getUploadParentConfigurableFields(props.responseId, UploadParentItemType.selfResponse)
      .then((fields: ConfigurableFieldsModel) => {
        const options = fields.newlyAddedConfigurableFieldNames.map(fieldName => {
          return resourceHelper.getString('ConfigurableFieldWasAdded', { '0': fieldName });
        });
        options.push(...fields.deletedConfigurableFieldNames.map(fieldName => {
          return resourceHelper.getString('ConfigurableFieldWasRemoved', { '0': fieldName });
        }));

        if (options.length > 0) {
          reponseDisclaimerMainLabel.value = resourceHelper.getString('SelfResponseMetadataDisclaimer', { '0': options.length });
          responseDisclaimerOptions.value = options;
          showResponseDisclaimer.value = true;
        }

        showMetadataButton.value = fields.availableConfigurableFieldIds.length > 0;
      });
} else {
  SelfResponseRepository.selfResponseHasUploadMetadata(props.responseId)
      .then(response =>{
        showMetadataButton.value = response;
      });
}

function onAssigneeChange() {
  assignedPersonaId.value = selectedAssignee.value?.assigneeType === constants.requestAssigneeTypes.user ? selectedAssignee.value.id : null;
  assignedGroupId.value = selectedAssignee.value?.assigneeType === constants.requestAssigneeTypes.group ? selectedAssignee.value.id : null;
}
function onMessageSent() {
  discussionMessageCount.value++;
}

function searchAssignee(event) {
  if (!event.query.trim().length) {
    filteredAssignees.value = [...props.responseAssignees];
  } else {
    filteredAssignees.value = props.responseAssignees.filter(a => {
      return a.name.toLowerCase().includes(event.query.toLowerCase());
    });
  }
}

function getUpdatedResponseModel(): SelfResponseModel {
  const response = props.response;
  response.assignedPersonaId = assignedPersonaId.value;
  response.assignedGroupId = assignedGroupId.value;
  response.dynamicResponseFormData = JSON.stringify(dynamicResponseFormData.value) || null;
  response.internalReferenceNumber = internalReferenceNumber.value;
  response.responsePriority = priority.value;
  response.rmsId = selectedRmsId.value;
  response.cadIds = selectedCadIds.value;
  return response;
}

async function saveResponseFunc() {
  if (!validateSelectedAssignee()) {
    return;
  }

  isSaving.value = true;
  try {
    const updatedResponse = getUpdatedResponseModel();
    await SelfResponseRepository.updateResponse(updatedResponse);
    isSaving.value = false;
    cadIdsComponent.value.clearNotMatchedItems();
    rmsIdComponent.value.clearNotMatchedItem();
    logger.success('UploadFolderSaved');
  } catch (err) {
    logger.error('UploadFolderSaveFailed');
  } finally {
    isSaving.value = false;
  }
}

function showDeleteModal() {
  if (!validateInProgressUploads()) {
    return;
  }
  deleteModalVisible.value = true;
}

function showReportModal() {
  if (!validateInProgressUploads()) {
    return;
  }
  reportModalVisible.value = true;
}

async function deleteResponseFunc() {
  deleteModalVisible.value = false;
  isDeleting.value = true;
  try {
    await SelfResponseRepository.deleteResponse(props.response.id);
    redirectHelper.redirectToHash('#uploadfolders');
    logger.success('UploadFolderDeleted');
  } catch (err) {
    logger.error('UploadFolderDeletionFailed');
  } finally {
    isDeleting.value = false;
  }

  return;
}

function onFormChange(data, isValid) {
  isFormValid.value = isValid;
  dynamicResponseFormData.value = data;
}

async function deleteModalCloseFunc() {
  deleteModalVisible.value = false;
}

async function createReportModalCloseFunc() {
  reportModalVisible.value = false;
}

function closeSubmittingModalDialog() {
  confirmSubmittingModalVisible.value = false;
}

async function onContinueSubmitting() {
  confirmSubmittingModalVisible.value = false;
  isSubmitting.value = true;

  try {
    const uploadIds = uploads.value.map(u => u.uploadId);
    const updatedResponse = getUpdatedResponseModel();
    updatedResponse.submittedUploadIds = uploadIds;
    const dto = await SelfResponseRepository.submitSelfResponse(props.responseId, updatedResponse);

    responseStatus.value = dto.selfResponseStatusForDisplay;
    isSubmitting.value = false;
    logger.success('UploadFolderSubmitted');
    redirectHelper.redirectToHash('#uploadfolders');
  } catch (e: any) {
    if (e.responseJSON?.errorMessages && e.responseJSON.errorMessages.length && e.responseJSON.isUserRecoverable) {
      showSubmitValidationErrors.value = true;
      submitErrorMessage.value = e.responseJSON.errorMessages[0];
    } else {
      logger.error('FailedToSubmitUploadFolder');
    }
  } finally {
    isSubmitting.value = false;
  }
}

async function submitResponseFunc() {
  showSubmitValidationErrors.value = false;
  submitErrorMessage.value = '';

  if (!validateSelectedAssignee()) {
    return;
  }

  if (!validateFailedUploads()) {
    return;
  }

  if (!validateEmptyUploads()) {
    return;
  }

  if (!validateInProgressUploads()) {
    return;
  }

  if (!await validateDynamicForm()) {
    return;
  }

  if (!await validateCadIds()) {
    return;
  }

  if (!await validateRmsId()) {
    return;
  }

  if (!await validateInternalReferenceNumber()) {
    return;
  }

  submitConfirmationText.value = props.response.dynamicResponseConfirmationText ?
    props.response.dynamicResponseConfirmationText : resourceHelper.getString('ConfirmSelfResponseModalTandCs');
  confirmSubmittingModalVisible.value = true;
}

function uploadsUpdated(updatedUploads: UploadModel[]) {
  uploads.value = updatedUploads;
  if (showSubmitValidationErrors.value) {
    showSubmitValidationErrors.value = false;
    submitErrorMessage.value = '';
  }
}

function validateEmptyUploads() {
  if (uploads.value.length === 0) {
    setSubmitError('NoFilesUploadedToUploadFolder');
    return false;
  }
  return true;
}

function validateSelectedAssignee() {
  if (selectedAssignee.value?.assigneeType === constants.requestAssigneeTypes.group &&
    !selectedAssignee.value?.isGroupContactActive) {
    logger.warning('GroupNeedsRegisteredContact');
    return false;
  }

  return true;
}

function validateFailedUploads() {
  const hasFailedStatuses = uploads.value.some(u => u.statusName() === constants.uploadStatuses.failed ||
    u.statusName() === constants.uploadStatuses.failedAuthorisation);
  if (hasFailedStatuses) {
    setSubmitError('FailedUploadsInUploadFolderExist');
    return false;
  }
  return true;
}

function validateInProgressUploads() {
  const hasInProgressStatuses = uploads.value.some(u => u.statusName() === constants.uploadStatuses.authorising ||
    u.statusName() === constants.uploadStatuses.pending ||
    u.statusName() === constants.uploadStatuses.uploading);

  if (hasInProgressStatuses) {
    setSubmitError('UploadFolderUploadsStillInProgress');
    return false;
  }
  return true;
}

function validateDynamicForm() {
  if (!isFormValid.value) {
    setSubmitError('DynamicFormHasErrors');
    return false;
  }
  return true;
}

function validateCadIds() {
  const isValid = cadIdsComponent.value.isValid();
  if (!isValid) {
    setSubmitError('CadIdExceedsMaxLength');
  }
  return isValid;
}

function validateRmsId() {
  const isValid = rmsIdComponent.value.isValid();
  if (!isValid) {
    setSubmitError('RmsIdExceedsMaxLength');
  }
  return isValid;
}

function validateInternalReferenceNumber() {
  if (isInternalReferenceNumberExceedsMaxLength.value) {
    setSubmitError('InternalReferenceNumberExceedsMaxLength');
    return false;
  }
  return true;
}

function setSubmitError(error: string) {
  showSubmitValidationErrors.value = true;
  submitErrorMessage.value = resourceHelper.getString(error);
}

function handleFailedUpload(resourceKey: string) {
  showSubmitValidationErrors.value = true;
  submitErrorMessage.value = resourceHelper.getString(resourceKey);
}
</script>

<style lang="scss" scoped>
@import "../../../../../Sass/site/colours";

.discussion-messaging__no-messages {
  margin-top: 40px;
}

.form-control.disabled{
  opacity: .5;
  background-color: #fff;
}

.form-control.has-error{
  border-color: $validation-error-color;
  &:focus {
    border-color: $validation-error-color;
    box-shadow: $input-error-box-shadow;
  }
}

.error-text {
  color: $validation-error-color;
  margin-bottom: 0px;
}
</style>
