<template>
  <div
    id="requestDisclaimer"
  >
    <div
      class="request-header-disclaimer"
      role="alert"
    >
      <div
        class="first-row"
        tabindex="0"
        :aria-describedby="describedByIds"
        @click="isExpanded = !isExpanded"
      >
        <label
          id="mainLabel"
          class="main-label"
        >
          {{ mainLabel }}
        </label>
        <i
          v-if="!isExpanded && additionalOptions.length"
          class="far fa-circle-chevron-left fa-lg expand-icon"
          aria-hidden="true"
        />
        <i
          v-if="isExpanded && additionalOptions.length"
          class="far fa-circle-chevron-down fa-lg expand-icon"
          aria-hidden="true"
        />
      </div>
      <div
        v-if="isExpanded && additionalOptions.length"
        class="options-container"
      >
        <ul
          class="list-container"
        >
          <li
            v-for="(option, index) in additionalOptions"
            :id="'optionId_'+index"
            :key="option"
          >
            {{ option }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps({
  mainLabel: { type: String, default: '' },
  additionalOptions: { type: Array, default: () => [] }
});

const isExpanded: boolean = ref(false);
let describedByIds = 'mainLabel ';
const optionIds = props.additionalOptions.map((option, index) => {
  return 'optionId_' + index;
});
if (optionIds && optionIds.length > 0) {
  describedByIds += optionIds.join(' ');
}
</script>

<style lang="scss" scoped>
@import "sass/site/_colours.scss";

.request-header-disclaimer {
  border: 1px solid transparent;
  color: $alert-warning-text-color;
  background-color: $alert-warning-background-color;
  border-color: $alert-warning-border-color;
  border-radius: 5px;
  padding: 10px;
}
.first-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.main-label {
  flex: auto;
  text-align: center;
  margin: 0;
  cursor: pointer;
}
.expand-icon{
  cursor: pointer;
}
.options-container{
  text-align: center;
}
.list-container{
  display: inline-block;
  text-align: left;
  margin-bottom: 0;
  margin-top: 5px;
}
</style>