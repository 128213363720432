<template>
  <AutoComplete
    v-model="state.selectedValue"
    :options="state.serverRmsIds"
    option-label="text"
    :dropdown="true"
    :placeholder="$localize('CadRmsIdPlaceholder')"
    :input-props="{ 'aria-invalid': inputValueExceedsMaxLength ? 'true' : 'false' }"
    :disabled="disabled"
    :complete-on-focus="false"
    :class="inputValueExceedsMaxLength ? 'has-error' : ''"
    :overlay-z-index="overlayZIndex"
    :force-selection="false"
    @search="searchRmsId"
    @item-select="onItemSelect"
    @blur="onBlur"
  />
  <div
    v-if="inputValueExceedsMaxLength"
    class="help-block error-text"
  >
    {{ $localize('RmsIdExceedsMaxLength') }}
  </div>
  <div
    v-if="state.showDisclaimer && !inputValueExceedsMaxLength"
    class="disclaimer-container"
  >
    <span>{{ $localize('NoMatchesFound') }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, reactive } from 'vue';
import { AutoCompleteItemSelectEvent } from 'primevue/autocomplete';
import AutoComplete from '@/VueComponents/SharedComponents/AutoComplete.vue';
import { AutoCompleteCompleteEvent } from 'primevue/autocomplete';
import LinkingReferenceRepository from '@/Repositories/linkingReferenceRepository';

defineProps<{
    overlayZIndex?: string,
    disabled: boolean
}>();
defineExpose({
  clearNotMatchedItem,
  isValid
});

function clearNotMatchedItem() {
  if (state.selectedValue) {
    state.serverRmsIds.push(state.selectedValue!);
    state.showDisclaimer = false;
  }
}

function isValid() {
  return !inputValueExceedsMaxLength.value;
}

const model = defineModel<string>();
watch(model, () => {
  setSelectedValue(model.value, true);
  updateDisclaimer();
});

function updateDisclaimer() {
  if (model.value) {
    if (state.serverRmsIds.length == 0) {
      state.showDisclaimer = true;
    } else {
      state.showDisclaimer = !state.serverRmsIds.some(id => id.text === model.value && id.existing);
    }
  } else {
    state.showDisclaimer = false;
  }
}

const state: { serverRmsIds: { text: string, existing: boolean }[], showDisclaimer: boolean,
  selectedValue: { text: string, existing: boolean } | null } =
        reactive({
          serverRmsIds: [],
          showDisclaimer: false,
          selectedValue: null
        });
setSelectedValue(model.value, true);

const inputValueExceedsMaxLength = computed(() => {
  return model.value?.length > 1000;
});

function searchRmsId(event: AutoCompleteCompleteEvent) {
  const query = event.query.trim();
  if (!query.length) {
    state.serverRmsIds = [];
  } else {
    LinkingReferenceRepository.getAvailableRmsIds(query)
        .then((rmsIds: string[]) => {
          state.serverRmsIds = rmsIds.map(id => {
            return { text: id, existing: true };
          });
          if (!rmsIds.includes(query)) {
            state.serverRmsIds.unshift({ text: query, existing: false });
          }
          updateDisclaimer();
        });
  }
}

function onItemSelect(event: AutoCompleteItemSelectEvent) {
  const value = event.value?.text.trim() ?? '';
  model.value = value;
}

// mimic force selection behavior
// fix bug 221156
// when user blur input need to clear value if it wasn't selected before
function onBlur(event: Event) {
  if (model.value !== state.selectedValue?.text) {
    model.value = '';
    setSelectedValue(null);
  }
}

function setSelectedValue(value: string | null | undefined, existing: boolean = false) {
  if (!value) {
    state.selectedValue = null;
    return;
  }

  state.selectedValue = { text: value!, existing: existing };
}
</script>

<style lang="scss" scoped>
@import "sass/site/_colours.scss";

.disclaimer-container {
    color: $alert-warning-text-color;
    font-size: 12px;
}
.error-text {
    color: $validation-error-color;
    margin-bottom: 0px;
}
</style>