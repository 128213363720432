import ko from 'knockout';
import { UploadType } from '@/Types/Enums/uploadType';

import template from './cctvFolderUploadsListItem.html';

export function CctvFolderUploadListItemViewModel(params) {

  const self = this;

  self.folderUpload = params.folderUpload;
  self.isReadOnly = params.isReadOnly;

  self.isFilesListVisible = ko.observable(false);
  self.toggleFilesListVisibility = function () {
    self.isFilesListVisible(!self.isFilesListVisible());
  };

  self.removing = ko.observable(false);
  self.removeUploadCallback = params.removeUploadCallback;
  self.removeUploads = function () {
    self.removing(true);
    self.removeUploadCallback(self.folderUpload.uploads).catch(() => {
      self.removing(false);
    });
  };
  self.uploadTypes = UploadType;
}

// The default export returns the component details object to register with KO
export default { viewModel: CctvFolderUploadListItemViewModel, template: template };
