<template>
  <div
    class="form-group"
    :class="{ 'has-error': !!validationMessage }"
  >
    <label
      v-if="label"
      :for="id"
      class="control-label"
      :class="{ 'required': required }"
    >{{ label }}</label>
    <span
      v-if="tooltip"
      class="sr-only"
    >{{ tooltip }}</span>
    <div
      v-if="tooltip"
      class="input-group"
    >
      <slot />
      <div
        v-tooltip.bottom="{
          class: 'filter-panel__tooltip',
          value: tooltip,
          pt: {
            text: 'filter-panel__tooltip-text',
            arrow: 'filter-panel__tooltip-arrow'
          }
        }"
        aria-hidden="true"
        class="input-group-addon"
      >
        <i class="fas fa-circle-info" />
      </div>
    </div>
    <slot
      v-else
    />
    <span
      :id="validationSpanId"
      class="help-block"
    >{{ validationMessage }}</span>
  </div>
</template>

<script lang="ts" setup>
import InputFieldElementIdGenerator from '@/VueComponents/SharedComponents/InputFields/utils/inputFieldElementIdGenerator';

const props = withDefaults(defineProps<{
  id: string,
  label?: string,
  validationMessage?: string,
  tooltip?: string,
  required?: boolean
}>(), { required: false, label: undefined, validationMessage: undefined, tooltip: undefined });

const validationSpanId = InputFieldElementIdGenerator.getValidationMessageId(props.id);
</script>
