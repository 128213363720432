import ko from 'knockout';
import logger from '../../Utils/logger';
import template from './progressBar.html';

export function ProgressBarViewModel(params) {

  if (!ko.isObservable(params.progress)) {
    logger.error('UnhandledError', 'The parameter \'progress\' is required and must be an observable.');
  }

  if (!ko.isObservable(params.statusName)) {
    logger.error('UnhandledError', 'The parameter \'statusName\' is required and must be an observable.');
  }

  if (!params.uploadStatuses) {
    logger.error('UnhandledError', 'The parameter \'uploadStatuses\' is required');
  }

  const self = this;

  self.statusName = params.statusName;
  self.uploadStatuses = params.uploadStatuses;
  self.progress = params.progress;
  self.label = params.label;

  self.isUploaded = ko.pureComputed(function () {
    return self.statusName() === self.uploadStatuses.uploaded;
  });

  self.isUploading = ko.pureComputed(function () {
    return self.statusName() === self.uploadStatuses.uploading;
  });

  self.widthStyle = ko.pureComputed(function () {
    if (self.statusName() === self.uploadStatuses.uploaded) {
      return '100%';
    }

    return self.progress() + '%';
  });
}

// The default export returns the component details object to register with KO
export default { viewModel: ProgressBarViewModel, template: template };


