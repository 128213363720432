import { ObservableArray, PureComputed } from 'knockout';
import template from './textFilter.html';
import { FilterItemValueModel } from '../Models/filterItemValueModel';

class TextFilter {
  public filterItemValues: ObservableArray<FilterItemValueModel>;
  public name: string;
  public isExpandedString: PureComputed<'true' | 'false'>;
  public collapseCss: string;
  constructor(options: any) {
    this.filterItemValues = options.filterItemValues;
    this.name = options.name;
    this.isExpandedString = options.isExpandedString;
    this.collapseCss = options.collapseCss;
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: TextFilter, template: template };
