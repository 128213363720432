<template>
  <em v-if="optionsList.length === 0">{{ noItemsString ? noItemsString : $localize('CollapsibleListNoItems') }}</em>
  <ul
    v-for="option in optionsList"
    :key="option"
    class="no-bullet-list"
  >
    <li role="listitem">
      {{ option }}
    </li>
  </ul>
  <a
    v-if="showLink"
    href="#"
    aria-expanded="false"
    @click="expandList"
  >
    {{ $localize('DefaultCollapsibleLinkStringKey', {'number': expandCount}) }}
  </a>
</template>

<script setup lang="ts">
import { ref, Ref, watch } from 'vue';

const props = defineProps<{
  options: string[],
  maxCollapsedItemsCount: number,
  noItemsString?: string
}>();

const expandCount = ref(0);
const showLink = ref(false);
const optionsList: Ref<string[]> = ref([]);

watch(() => props.options, newList => {
  init(newList);
});

function expandList(event: MouseEvent) {
  event.preventDefault();
  optionsList.value = props.options;
  showLink.value = false;
}

function init(options: string[]) {
  expandCount.value = options.length - props.maxCollapsedItemsCount + 1;
  showLink.value = options.length > props.maxCollapsedItemsCount;
  optionsList.value = options.length === props.maxCollapsedItemsCount ?
    options : options.slice(0, props.maxCollapsedItemsCount - 1);
}

init(props.options);
</script>

<style>
</style>