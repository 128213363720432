<template>
  <div
    class="request-details__uploads-loading-error-container"
  >
    <i
      aria-hidden="true"
      class="far fa-exclamation-circle text-danger"
    />
    <div>
      <slot />
    </div>
  </div>
</template>
