<template>
  <div
    :class="{ 'text-danger': isTextDanger }"
  >
    {{ summaryString }}
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import resourceHelper from '@/Utils/resourceHelper';

const props = defineProps<{
  requiredBy: Date
}>();

const dueInDays = computed(() => {
  return getDaysBetween(new Date(), props.requiredBy);
});
const summaryString = computed(() => {
  if (dueInDays.value === 1) {
    return resourceHelper.getString('DueTomorrow');
  }

  if (dueInDays.value === -1) {
    return resourceHelper.getString('DueYesterday');
  }

  if (dueInDays.value > 0) {
    return resourceHelper.getString('DueInXDays', { days: dueInDays.value });
  }

  if (dueInDays.value < 0) {
    return resourceHelper.getString('OverdueByXDays', {
      days: dueInDays.value < -100 ? '100+' : Math.abs(dueInDays.value)
    });
  }

  return '';
});

const isTextDanger = computed(() => {
  return dueInDays.value < 0;
});

function getDaysBetween(date1, date2) {

  // Ensure dates are treated as UTC when being compared
  const utcDate1 = Date.UTC(date1.getUTCFullYear(), date1.getUTCMonth(), date1.getUTCDate());
  const utcDate2 = Date.UTC(date2.getUTCFullYear(), date2.getUTCMonth(), date2.getUTCDate());
  const milliseconds = utcDate2 - utcDate1;

  // Floor should be unnecessary as stripped time aspect but will play it safe!
  return Math.floor(milliseconds / 1000 / 60 / 60 / 24);
}
</script>