<template>
  <div class="row">
    <div class="col-md-6">
      <h3>{{ $localize('Details') }}</h3>
      <TextInputField
        id="cameraName"
        v-model="cameraName"
        :label="$localize('CameraName')"
        :validation-rules="validationRules.cameraName"
      />

      <TextInputField
        id="internalName"
        v-model="internalName"
        :tooltip="$localize('InternalNameTooltip')"
        :label="$localize('InternalName')"
        :validation-rules="validationRules.internalName"
      />
      <TextInputField
        id="cameraMake"
        v-model="cameraMake"
        :label="$localize('CameraMake')"
        :validation-rules="validationRules.cameraMake"
      />
      <TextInputField
        id="cameraModel"
        v-model="cameraModel"
        :label="$localize('CameraModel')"
        :validation-rules="validationRules.cameraModel"
      />
      <text-area-field
        id="cameraDescription"
        v-model="cameraNotes"
        :label="$localize('CameraDescription')"
        :validation-rules="validationRules.description"
        rows="7"
      />
    </div>
    <div class="col-md-6">
      <h3>{{ $localize('PlaceOnMap') }}</h3>
      <AddressLookupField
        v-model="cameraCoordinate"
        :label="$localize('AddressSearchTerm')"
        @change="onAddressChanged"
      />

      <label>{{ $localize('CameraMarkerInstructions') }}</label>
      <CameraDetailsMap
        v-model:coordinate="coordinate"
        v-model:camera-coordinate="cameraCoordinate"
        v-model:zoom="zoom"
      />
    </div>
  </div>

  <div
    v-if="serverErrors?.length"
    id="serverErrorValidationMessages"
    class="validation-summary"
    aria-live="assertive"
  >
    <hr>

    <h5><strong>{{ $localize('ServerSideValidationFailed') }}</strong></h5>

    <ul
      v-for="(error, index) in serverErrors"
      :key="index"
    >
      <li>{{ error }}</li>
    </ul>

    <hr>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import CameraDetailsMap from '@/VueComponents/Cameras/CameraDetails/CameraDetailsMap.vue';
import AddressLookupField from '@/VueComponents/SharedComponents/InputFields/AddressLookupField.vue';
import TextInputField from '@/VueComponents/SharedComponents/InputFields/TextInputField.vue';
import TextAreaField from '@/VueComponents/SharedComponents/InputFields/TextAreaField.vue';
import MapCoordinate from '@/VueComponents/SharedComponents/Map/Models/mapCoordinate';
import contextData from '@/contextData';
import CameraEditFormValidationRules from '@/VueComponents/Cameras/Models/cameraEditFormValidationRules';
import { useForm } from 'vee-validate';

defineProps<{
  serverErrors?: string[],
  validationRules: CameraEditFormValidationRules
}>();


const cameraName = defineModel<string>('cameraName');
const internalName = defineModel<string>('internalName');
const cameraMake = defineModel<string>('cameraMake');
const cameraModel = defineModel<string>('cameraModel');
const cameraNotes = defineModel<string>('cameraNotes');
const cameraCoordinate = defineModel<MapCoordinate>('cameraCoordinate');


const form = useForm({
  initialValues: {
    'cameraName': cameraName.value,
    'cameraModel': cameraModel.value,
    'cameraMake': cameraMake.value,
    'internalName': internalName.value,
    'cameraDescription': cameraNotes.value
  }
});

defineExpose({
  isValid
});

const coordinate = ref(getDefaultMapCoordinate());
if (cameraCoordinate.value) {
  coordinate.value = {
    longitude: cameraCoordinate.value.longitude,
    latitude: cameraCoordinate.value.latitude
  };
}

const defaultZoomValue = 17;
const zoom = ref(defaultZoomValue);

function onAddressChanged(coord: MapCoordinate) {
  coordinate.value = coord;
  zoom.value = defaultZoomValue;
}

function getDefaultMapCoordinate() {
  if (contextData.userData.business.coordinate) {
    return contextData.userData.business.coordinate;
  }

  return contextData.portalSettings.defaultMapView.coordinate;
}

async function isValid() {
  return (await form.validate()).valid;
}
</script>