<template>
  <div class="content-container">
    <div class="request-details__discussion-heading-container">
      <div class="request-details__discussion-heading-text">
        <h3>
          <span>{{ $localize('Messages') }}</span>
          <span
            v-if="discussionMessages.length"
            class="font-size-smaller"
          > (<span>{{ discussionMessages.length }}</span>)</span>
        </h3>
      </div>
      <div
        v-if="discussionMessagingAllowed"
        class="text-right"
      >
        <button
          class="request-details__discussion-heading-button btn btn-sm btn-default"
          @click="reloadDiscussionMessages"
        >
          <i
            v-if="!isReloadingDiscussionMessages"
            class="fas fa-redo"
            aria-hidden="true"
          />
          <i
            v-if="isReloadingDiscussionMessages"
            class="far fa-circle-notch fa-spin"
            aria-hidden="true"
          />
          &nbsp;<span> {{ $localize('Reload') }}</span>
        </button>
      </div>
    </div>
    <div>
      <NewDiscussionMessage
        v-if="discussionMessagingAllowed"
        :send-message-func="sendDiscussionMessage"
      />

      <div v-if="discussionMessages.length && discussionMessagingAllowed">
        <div
          v-for="item in discussionMessages"
          :key="item.messageId"
        >
          <DiscussionMessage
            :discussion-message="item"
            :local-message-icon-class-names="localMessageIconClassNames"
            :external-message-icon-class-names="externalMessageIconClassNames"
          />
        </div>
      </div>

      <div v-if="!discussionMessages.length">
        <div v-if="discussionMessagingAllowed">
          <div class="discussion-messaging__no-messages">
            {{ $localize('NoDiscussionMessages') }}
          </div>
        </div>

        <div v-if="itemType === DiscussionItemType.selfResponse">
          <div v-if="!discussionMessagingAllowed">
            <div class="discussion-messaging__no-messages">
              {{ $localize('MessagingUnavailable') }}
            </div>

            <div class="discussion-messaging__description">
              {{ $localize('ResponseMessagingPlaceholder') }}
            </div>
          </div>
          <div
            v-if="discussionMessagingAllowed"
            class="discussion-messaging__description"
          >
            {{ $localize('ResponseMessagingPlaceholderNoMessages') }}
          </div>
        </div>

        <div v-if="itemType === DiscussionItemType.business || itemType === DiscussionItemType.partner">
          <div v-if="!discussionMessagingAllowed">
            <div class="discussion-messaging__no-messages">
              {{ $localize('MessagingUnavailable') }}
            </div>
          </div>

          <div
            v-if="discussionMessagingAllowed"
            class="discussion-messaging__description"
          >
            {{ $localize('DiscussionMessagesDescription') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import DiscussionMessageModel from '@/Models/discussionMessageModel';
import DiscussionMessage from '@/VueComponents/DiscussionMessaging/DiscussionMessage';
import NewDiscussionMessage from '@/VueComponents/DiscussionMessaging/NewDiscussionMessage';
import discussionRepository from '@/Repositories/discussionRepository';
import { DiscussionItemType } from '@/Types/Enums/discussionItemType';
import logger from '@/Utils/logger';
import { ref } from 'vue';

const props = defineProps<{
  discussionMessages: Array<DiscussionMessageModel>,
  localMessageIconClassNames: string,
  externalMessageIconClassNames: string,
  discussionMessagingAllowed: boolean,
  itemType: DiscussionItemType,
  itemId: string
    }>();

const emit = defineEmits<{(e: 'messageSent'): void}>();

const discussionMessages = ref(props.discussionMessages);
const isReloadingDiscussionMessages = ref(false);

function sendDiscussionMessage(message:string) {
  if (!props.discussionMessagingAllowed) {
    switch (props.itemType) {
      case DiscussionItemType.business:
      case DiscussionItemType.partner:
        logger.error('DiscussionMessagingIsNotAllowedForRequest');
        break;
      case DiscussionItemType.selfResponse:
        logger.error('DiscussionMessagingIsNotAllowedForUploadFolder');
        break;
    }
    return Promise.reject(new Error('Discussion messaging is not allowed'));
  }

  return sendMessage(props.itemType, props.itemId, message)
      .then(updatedDiscussionMessages => {
        discussionMessages.value = updatedDiscussionMessages;
        emit('messageSent');
      })
      .catch(jqXhr => {
        logger.error('UnableToSendDiscussionMessage', null, jqXhr);
        throw jqXhr;
      });
}

function sendMessage(itemType:DiscussionItemType, itemId:string, message:string) {
  switch (itemType) {
    case DiscussionItemType.business:
    case DiscussionItemType.partner:
      return discussionRepository.sendMessageForRequest(itemId, message);
    case DiscussionItemType.selfResponse:
      return discussionRepository.sendMessageForResponse(itemId, message);
    default:
      return Promise.reject(new Error('Unknown item type'));
  }
}

function reloadDiscussionMessages() {
  isReloadingDiscussionMessages.value = true;

  return loadMessages(props.itemType, props.itemId)
      .then(newDiscussionMessages => {
        discussionMessages.value = newDiscussionMessages;
      })
      .catch(jqXhr => {
        logger.error('UnableToRetrieveLatestDiscussion', null, jqXhr);
      })
      .finally(() => {
        isReloadingDiscussionMessages.value = false;
      });
}

function loadMessages(itemType:DiscussionItemType, itemId:string) {
  switch (itemType) {
    case DiscussionItemType.business:
    case DiscussionItemType.partner:
      return discussionRepository.getMessagesByRequestId(itemId);
    case DiscussionItemType.selfResponse:
      return discussionRepository.getMessagesByResponseId(itemId);
    default:
      return Promise.reject(new Error('Unknown item type'));
  }
}

</script>

<style lang="scss" scoped>
</style>