import ko from 'knockout';
import redirectHelper from '../../../Utils/redirectHelper';
import ValidationRulesService from '../../../Validation/validationRulesService';
import registrationRepository from '../../../Repositories/registrationRepository';
import contextData from '../../../contextData';
import { RegistrationChecksStatus } from '@/Types/Enums/registrationChecksStatus';
import template from './registration.html';

export function RegistrationViewModel() {

  const self = this;
  const registrationData = contextData.registrationData;
  const contentSections = {
    registrationUnavailable: 'registrationUnavailable',
    registrationError: 'registrationError',
    registrationEmailValidationError: 'registrationEmailValidationError',
    registrationComplete: 'registrationComplete',
    registrationForm: 'registrationForm'
  };

  let validationRulesService;

  // These property names all need to match the RegistrationDto
  self.registrationId = ko.observable(registrationData.registrationId);
  self.languageCode = contextData.languageCode;
  self.givenName = ko.observable(registrationData.givenName);
  self.familyName = ko.observable(registrationData.familyName);
  self.phoneNumber = ko.observable(registrationData.phoneNumber);

  self.businessId = registrationData.businessId;
  self.businessIsFullyRegistered = registrationData.businessIsFullyRegistered;
  self.businessName = ko.observable(registrationData.businessName);
  self.businessAddress = ko.observable(registrationData.businessAddress);

  self.acceptedTandCs = ko.observable(false);
  self.emailAddress = ko.observable(registrationData.emailAddress);

  // UI specific observables
  self.modalVisible = ko.observable(false);
  self.formSubmitted = ko.observable(false);
  self.contentToDisplay = ko.observable();

  self.clientErrors = ko.validation.group(self, { deep: true });
  self.serverErrors = ko.observableArray();
  if (contextData.registrationData.registrationChecksStatus === RegistrationChecksStatus.RegistrationAllowed) {
    self.contentToDisplay(contentSections.registrationForm);

    validationRulesService = new ValidationRulesService(self);
    validationRulesService.applyValidation(contextData.registrationData.validationRules);
  } else if (contextData.registrationData.registrationChecksStatus === RegistrationChecksStatus.IdentityEmailMismatch) {
    self.contentToDisplay(contentSections.registrationEmailValidationError);
  } else {
    self.contentToDisplay(contentSections.registrationUnavailable);
  }

  self.register = function () {

    if (self.clientErrors().length > 0) {
      self.clientErrors.showAllMessages(true);
      return;
    }

    self.formSubmitted(true);
    self.serverErrors([]);

    const registrationData = ko.toJS(self);

    registrationRepository.submitRegistration(self.registrationId(), registrationData)
        .then(function () {
          self.formSubmitted(false);
          self.contentToDisplay(contentSections.registrationComplete);
        })
        .catch(function (jqXhr) {
          self.formSubmitted(false);

          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
            return;
          }

          self.contentToDisplay(contentSections.registrationError);
        });
  };

  self.logout = function () {
    redirectHelper.logoutRedirect();
  };

  /**
   * The redirect helper function reference is used to allow a login
   */
  self.loginRedirect = redirectHelper.loginRedirect;

  self.showModal = function () {
    self.modalVisible(true);
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: RegistrationViewModel, template: template };
