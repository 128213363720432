<script setup lang="ts">
import { ref, Ref, watch, onMounted, nextTick } from 'vue';
import ConfigurableField from '@/Types/configurableField';
import ValidationResultManager from '@/VueComponents/ConfigurableFields/validationResultManager';
import { Field } from 'vee-validate';
import ConfigurableFieldOption from '@/Types/configurableFieldOption';

const props = defineProps<{
        configurableField: ConfigurableField,
        fieldValue: string,
        fieldSubValue: string,
        disabled: boolean,
        showMultiplePlaceholder: boolean,
        showMultipleSubPlaceholder: boolean,
        isParentFieldReadonly: boolean
    }>();
const emit = defineEmits<{(e: 'updated', fieldId: string, value: string, isValid: boolean, invalidReason: string, subValue: string): void}>();

const multipleOption = 'multiple';
const allParentOptions = props.configurableField.options?.map(opt => opt.value);
const sortedParentOptions = [...new Set(allParentOptions)].toSorted();
const internalParentFieldValue = ref(getInternalParentFieldValue(props.fieldValue));

const subOptions: Ref<string[]> = ref([]);
setSubOptions(internalParentFieldValue.value);

const internalFieldSubValue = ref(getInternalSubFieldValue(props.fieldSubValue));
verifyDefaultSubValue();

watch(() => props.fieldValue, newValue => {
  internalParentFieldValue.value = getInternalParentFieldValue(newValue);
  setSubOptions(internalParentFieldValue.value);
});

watch(() => props.fieldSubValue, newValue => {
  internalFieldSubValue.value = getInternalSubFieldValue(newValue);
  verifyDefaultSubValue();
});

watch(() => props.showMultiplePlaceholder, newValue => {
  if (newValue) {
    internalParentFieldValue.value = multipleOption;
  } else {
    // Multiple option and empty option come from parent as empty value
    // but internal value differs. To cover case: when value changed
    // from multiple to empty
    if (internalParentFieldValue.value === multipleOption) {
      internalParentFieldValue.value = '';
    }
  }
});

watch(() => props.showMultipleSubPlaceholder, newValue => {
  if (newValue) {
    internalFieldSubValue.value = multipleOption;
  } else {
    if (internalFieldSubValue.value === multipleOption) {
      internalFieldSubValue.value = '';
    }
  }
});

const parentSelect = ref(null);
const subSelect = ref(null);

const parentList = ref(null);
const subList = ref(null);

async function validateParentField(): Promise<Object> {
  return await parentList?.value?.validate();
}

async function validateSubField(): Promise<Object> {
  return await subList?.value?.validate();
}

async function emitParentValueUpdatedEvent() {
  const selectValue = parentSelect?.value?.value ?? '';
  const validationResult = await validateParentField();
  let { isValid, error } = ValidationResultManager.parseValidationResult(validationResult);
  // parent value is changed -> need to clear(set default) sub value
  internalFieldSubValue.value = getDefaultSubValue(getSubOptions(selectValue));
  await nextTick();
  if (isValid) {
    const validationResult = await validateSubField();
    const result = ValidationResultManager.parseValidationResult(validationResult);
    isValid = result.isValid;
    error = result.error;
  }
  emit('updated', props.configurableField.id, selectValue, isValid, error, internalFieldSubValue.value);
}

async function emitSubValueUpdatedEvent() {
  const subValue = subSelect?.value?.value ?? '';
  const validationResult = await validateSubField();
  const { isValid, error } = ValidationResultManager.parseValidationResult(validationResult);
  emit('updated', props.configurableField.id, internalParentFieldValue.value, isValid, error, subValue);
}

// value should be one of list options, multiple or empty
function getInternalParentFieldValue(value: string): string {
  if (props.showMultiplePlaceholder) {
    return multipleOption;
  }

  return allParentOptions?.includes(value) ? value : '';
}

function getInternalSubFieldValue(subValue: string): string {
  if (props.showMultipleSubPlaceholder) {
    return multipleOption;
  }

  return subOptions.value?.includes(subValue) ? subValue : '';
}

function getSubOptions(parentValue: string | null): ConfigurableFieldOption[] {
  if (!parentValue) {
    return [];
  }
  const options = props.configurableField.options;
  if (!options) {
    return [];
  }

  return options.filter((option: ConfigurableFieldOption) => option.value === parentValue && option.subGroupValue);
}

function setSubOptions(newParentValue: string | null) {
  if (!newParentValue || props.showMultiplePlaceholder) {
    subOptions.value = [];
    return;
  }

  const options = getSubOptions(newParentValue);
  subOptions.value = options.map((option: ConfigurableFieldOption) => option.subGroupValue ?? '').toSorted();
}

function verifyDefaultSubValue() {
  if (!internalFieldSubValue.value && !props.showMultipleSubPlaceholder) {
    const options = getSubOptions(internalParentFieldValue.value);
    selectDefaultSubValue(options);
  }
}

function selectDefaultSubValue(options: ConfigurableFieldOption[]) {
  internalFieldSubValue.value = getDefaultSubValue(options);
  if (internalFieldSubValue.value) {
    nextTick(() => {
      emitSubValueUpdatedEvent();
    });
  }
}

function getDefaultSubValue(options: ConfigurableFieldOption[]): string {
  const defaultSubOption = options.find(option => option.isDefaultSubGroupValue);
  return defaultSubOption?.subGroupValue ?? '';
}

onMounted(async () => {
  await validateParentField();
  await validateSubField();
});
</script>

<template>
  <Field
    ref="parentList"
    v-slot="{ errors }"
    v-model="internalParentFieldValue"
    :name="'label-' + configurableField.name"
    :rules="{ required: configurableField.isMandatory}"
    :validate-on-change="true"
    :validate-on-input="false"
    :validate-on-blur="true"
    :validate-on-model-update="true"
  >
    <div
      :class="{ 'has-error': errors.length > 0 && !showMultiplePlaceholder}"
    >
      <label
        :for="'list-' + configurableField.id"
        :class="{'required': configurableField.isMandatory}"
      >
        {{ configurableField.displayName }}:
      </label>
      <div>
        <select
          :id="'hierarchicalParentType-' + configurableField.id"
          ref="parentSelect"
          v-model="internalParentFieldValue"
          class="form-control form-select"
          :disabled="disabled || isParentFieldReadonly"
          :aria-invalid="errors.length > 0 && !showMultiplePlaceholder"
          :aria-describedby="'errorText_'+configurableField.name"
          @change="emitParentValueUpdatedEvent"
        >
          <option
            v-if="showMultiplePlaceholder"
            :value="multipleOption"
            disabled
            hidden
          >
            {{ $localize('MultipleValue') }}
          </option>
          <option
            v-for="option in sortedParentOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <div class="help-block">
          <small :id="'errorText_'+configurableField.name">{{ showMultiplePlaceholder ? '' : errors[0] }}</small>
        </div>
      </div>
    </div>
  </Field>
  <Field
    ref="subList"
    v-slot="{ errors }"
    v-model="internalFieldSubValue"
    :name="'label-' + configurableField.subGroupName"
    :rules="{ required: configurableField.isMandatory}"
    :validate-on-change="true"
    :validate-on-input="false"
    :validate-on-blur="true"
    :validate-on-model-update="true"
  >
    <div
      :class="{ 'has-error': errors.length > 0 && !showMultipleSubPlaceholder}"
    >
      <label
        :for="'list-' + configurableField.id"
        :class="{'required': configurableField.isMandatory}"
      >
        {{ configurableField.subGroupDisplayName }}:
      </label>
      <div>
        <select
          :id="'hierarchicalSubType-' + configurableField.id"
          ref="subSelect"
          v-model="internalFieldSubValue"
          class="form-control form-select"
          :disabled="disabled || showMultiplePlaceholder || !internalParentFieldValue"
          :aria-invalid="errors.length > 0 && !showMultipleSubPlaceholder"
          :aria-describedby="'errorText_'+configurableField.name"
          @change="emitSubValueUpdatedEvent"
        >
          <option
            v-if="showMultipleSubPlaceholder"
            :value="multipleOption"
            disabled
            hidden
          >
            {{ $localize('MultipleValue') }}
          </option>
          <option
            v-for="option in subOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <div class="help-block">
          <small :id="'errorText_'+configurableField.name">{{ showMultipleSubPlaceholder ? '' : errors[0] }}</small>
        </div>
      </div>
    </div>
  </Field>
</template>