<template>
  <div
    class="display-inline"
    :class="$attrs.class"
  >
    <input
      type="checkbox"
      class="select-all-checkbox"
      :class="{ 'marked': selectedCount > 0 && !selectedAll }"
      :checked="selectedCount > 0"
      :aria-label="$localize('SelectAll')"
      @click.prevent="onSelectedCheckboxClicked"
    >
    <Dropdown
      ref="dropdownSelect"
      class="dropdown-btn-sm-default"
      panel-class="dropdown-sm-panel"
      :auto-option-focus="false"
      :options="dropDownOptions"
      option-label="name"
      @change="onDropdownChange"
      @show="onShow"
    >
      <template #value>
        <span>{{ $localize('SelectedNumber', { number: selectedCount }) }}</span>
      </template>
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { DropdownChangeEvent } from 'primevue/dropdown';
import resourceHelper from '@/Utils/resourceHelper';
import Dropdown from 'primevue/dropdown';

const props = defineProps({
  availableForSelectionCount: { type: Number, default: 0 },
  failedCount: { type: Number, default: 0 },
  selectedCount: { type: Number, default: 0 },
  selectAllLabel: { type: String, default: null, required: false },
  selectedFailedLabel: { type: String, default: null, required: false },
  overlayZIndex: { type: String, default: null, required: false }
});
const emit = defineEmits(['selectAll', 'selectFailed', 'deselectAll']);

const selectedAll = computed(() => {
  return props.availableForSelectionCount === props.selectedCount && props.availableForSelectionCount !== 0;
});

const dropdownSelect = ref();

const dropDownOptionCodes = {
  all: 'all',
  failed: 'failed'
};

const dropDownOptions = computed(() => [
  {
    name: `${resourceHelper.getString(props.selectAllLabel ?? 'SelectAll')} (${props.availableForSelectionCount})`,
    code: dropDownOptionCodes.all
  },
  {
    name: `${resourceHelper.getString(props.selectedFailedLabel ?? 'SelectFailed')} (${props.failedCount})`,
    code: dropDownOptionCodes.failed
  }
]);

function selectAll() {
  emit('selectAll');
}

function selectFailed() {
  emit('selectFailed');
}

function deselectAll() {
  emit('deselectAll');
}

function onShow() {
  if (props.overlayZIndex) {
    (dropdownSelect.value.overlay as HTMLElement).style.zIndex = props.overlayZIndex;
  }
}
function onDropdownChange(event: DropdownChangeEvent) {
  switch (event.value.code) {
    case dropDownOptionCodes.all:
      selectAll();
      break;
    case dropDownOptionCodes.failed:
      selectFailed();
      break;
  }
}

function onSelectedCheckboxClicked(event: Event) {
  // A browser resets checkbox status after clicking which can cause artifacts in some cases
  // that is why the setTimeout in combination with the .prevent (or preventDefault()) is used to override the default browser's
  // checkbox click behaviour with the computed vue.js value.
  setTimeout(() => {
    selectedAll.value ? deselectAll() : selectAll();
  }, 0);
}
</script>

<style lang="scss" scoped>
 .dropdown-btn-sm-default {
   padding-left: 25px;
 }
 .display-inline {
   display: inline;
 }
 .select-all-checkbox {
   position: absolute;
   margin-top: 0.65em;
   margin-left: 10px;
   z-index: 1;

   &.marked {
     opacity: .6;
   }
 }
</style>