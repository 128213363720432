<template>
  <div class="container-fluid no-padding">
    <div class="generic-details-form min-height-350">
      <div class="row">
        <div class="form-group required">
          <label
            class="control-label required"
            for="communityRequestType"
          >
            <span>{{ $localize(labelResourceKey) }}</span>
          </label>
          <div class="row">
            <div class="col-md-9">
              <select
                id="communityResponseType"
                v-model="selectedResponseTypeId"
                class="form-control"
                :disabled="isSelectDisabled"
              >
                <option
                  v-for="(item, index) in availableResponseTypes"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-md-3">
              <button
                role="button"
                type="button"
                class="btn btn-default pull-left"
                :disabled="isSelectDisabled"
                @click="selectResponseForm(selectedResponseTypeId)"
              >
                <i
                  v-if="loadingForm"
                  class="far fa-circle-notch fa-spin mr-1"
                />
                <span>{{ $localize('Select') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps < {
        selectedItem: any,
        availableResponseTypes: any,
        selectItem: any,
        labelResourceKey: string,
        disabled: boolean
    }>();

const selectedResponseTypeId = ref(props.selectedItem?.id ?? props.availableResponseTypes[0].id);

const loadingForm = ref(false);
const isSelectDisabled = ref(props.availableResponseTypes.length === 1 || loadingForm.value === true || props.disabled);

watch(() => props.disabled, newValue => {
  isSelectDisabled.value = newValue;
});

function selectResponseForm(typeId: string) {
  loadingForm.value = true;
  props.selectItem(typeId).finally(() => {
    loadingForm.value = false;
  });
}
</script>

<style lang="scss" scoped>
</style>