<template>
  <div class="new-discussion-message">
    <Textarea
      v-model="newMessage"
      maxlength="4000"
      rows="3"
      cols="25"
      class="new-discussion-message__input form-control"
      :aria-label="$localize('NewMessagePlaceholder')"
      :placeholder="$localize('NewMessagePlaceholder')"
    />

    <button
      :disabled="isSending || newMessage.length === 0"
      :class="newMessage.length > 0 ? 'btn-primary' : 'btn-default'"
      :aria-label="$localize('SendMessage')"
      class="btn new-discussion-message__button"
      @click="onSendClicked"
    >
      <i
        v-if="!isSending"
        class="fas fa-paper-plane"
        aria-hidden="true"
      />
      <i
        v-if="isSending"
        class="far fa-circle-notch fa-spin"
        aria-hidden="true"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import Textarea from 'primevue/textarea';
import { ref } from 'vue';
import logger from '@/Utils/logger';

const props = defineProps<{
    sendMessageFunc:() => void,
      }>();
const newMessage = ref('');
const isSending = ref(false);
function onSendClicked(): Promise<void> {

  if (newMessage.value.trim() === '') {
    logger.warning('NewDiscussionMessageIsEmpty');
    return Promise.resolve();
  }

  isSending.value = true;

  return props.sendMessageFunc(newMessage.value)
      .then(() => {
        newMessage.value = '';
      })
      .catch(e => {
        console.warn('The Promise to save the new message was rejected');
      })
      .finally(() => {
        isSending.value = false;
      });
}
</script>

<style lang="scss" scoped>
@import "sass/site/_colours.scss";

    .new-discussion-message__input {
        background-color: $background-light-grey;
    }

    .p-inputtext:enabled:focus {
        box-shadow: $input-focus-box-shadow;
    }
</style>