<template>
  <ModalDialog
    :title="$localize('CreateNewCaseUploadFolder')"
    :is-wide="true"
    :is-danger="false"
    @close="onModalClose"
  >
    <template #default>
      <ItemsLoadingSpinner v-if="responseTypesLoading" />
      <div
        v-if="!responseTypesLoading"
        class="px-3 py-3"
      >
        <div class="row">
          <div class="col-md-12">
            <FormTypeSelector
              v-if="showFormSelector"
              :label-resource-key="'SelfResponseType'"
              :selected-item="selectedResponseType"
              :available-response-types="availableResponseTypes"
              :select-item="onResponseTypeSelected"
              :disabled="showFormContent"
            />
          </div>
        </div>
        <div v-if="showFormContent">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label
                  for="assignee"
                  class="control-label"
                >{{ $localize('RequestAssignee') }}</label>
                <AutoComplete
                  id="assignee"
                  v-model="selectedAssignee"
                  :options="filteredAssignees"
                  option-label="name"
                  :disabled="false"
                  :option-disabled="'isDisabled'"
                  :dropdown="true"
                  :placeholder="$localize('UploadFolderAssignedToPlaceholder')"
                  @search="searchAssignee"
                >
                  <template #option="{ option }">
                    <div
                      class="option row dropdown-option"
                      :title="RequestAssigneeFormatter.getRequestAssigneeTitle(option)"
                    >
                      <div class="col-xs-1">
                        <i
                          :class="RequestAssigneeFormatter.getRequestAssigneeIconClass(option)"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="col-xs-11">
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </template>
                </AutoComplete>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label">{{ $localize('Priority') }}</label>
                <select
                  v-model="selectedPriority"
                  class="form-control"
                >
                  <option :value="SelfResponsePriority.none">
                    {{ $localize('SelfResponsePriorityNone') }}
                  </option>
                  <option
                    :value="SelfResponsePriority.medium"
                    selected
                  >
                    {{ $localize('SelfResponsePriorityMedium') }}
                  </option>
                  <option :value="SelfResponsePriority.high">
                    {{ $localize('SelfResponsePriorityHigh') }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label">{{ $localize('InternalReferenceNumber') }}</label>
                <input
                  v-model="internalReferenceNumber"
                  class="form-control"
                  :class="{'has-error': isInternalReferenceNumberExceedsMaxLength}"
                  :placeholder="$localize('InternalReferencePlaceholder')"
                  :aria-invalid="isInternalReferenceNumberExceedsMaxLength"
                >
                <div
                  v-if="isInternalReferenceNumberExceedsMaxLength"
                  class="help-block error-text"
                >
                  {{ $localize('InternalReferenceNumberExceedsMaxLength') }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6">
              <div class="form-group">
                <label class="control-label">{{ $localize('RmsId') }}</label>
                <RmsIdSelector
                  ref="rmsIdComponent"
                  v-model="selectedRmsId"
                  :disabled="false"
                />
              </div>
            </div>
            <div class="col-xs-6">
              <div class="form-group">
                <label class="control-label">{{ $localize('CadIds') }}</label>
                <CadIdsSelector
                  ref="cadIdsComponent"
                  v-model="selectedCadIds"
                  :disabled="false"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <DynamicForm
                v-model="dynamicResponseFormData"
                class="dynamic-form"
                :form-definition="dynamicResponseFormDefinition"
                :is-read-only="false"
                :disable-validation="true"
                @update:model-value="onFormChange"
              />
            </div>
          </div>
        </div>
        <div
          v-if="showValidationErrors"
          id="errorValidationMessages"
          class="validation-summary"
          aria-live="assertive"
        >
          <h5><strong>{{ $localize('UploadValidationFailed') }}</strong></h5>
          <ul>
            <li>{{ createErrorMessage }}</li>
          </ul>
        </div>
      </div>
    </template>
    <template #footer>
      <button
        ref="closeBtn"
        class="btn btn-default"
        @click="onModalClose"
      >
        {{ $localize('Cancel') }}
      </button>
      <button
        ref="createBtn"
        class="btn btn-primary"
        :disabled="isCreating || responseTypesLoading"
        @click="onCreateResponse"
      >
        <i
          v-if="isCreating || responseTypesLoading"
          class="far fa-circle-notch fa-spin mr-1"
        />
        {{ $localize('Create') }}
      </button>
    </template>
  </ModalDialog>
</template>

<script setup lang="ts">
import SelfResponseModel from '@/Models/selfResponseModel';
import { onMounted, ref, Ref, computed } from 'vue';
import DynamicForm from '@/VueComponents/DynamicForm/DynamicForm';
import ModalDialog from '@/VueComponents/Modals/ModalDialog';
import RmsIdSelector from '@/VueComponents/RmsIdSelector/RmsIdSelector';
import CadIdsSelector from '@/VueComponents/CadIdsSelector/CadIdsSelector';
import businessRepository from '@/Repositories/businessRepository';
import SelfResponseRepository from '@/Repositories/selfResponseRepository';
import contextData from '@/contextData';
import logger from '@/Utils/logger';
import redirectHelper from '@/Utils/redirectHelper';
import constants from '@/constants';
import FormTypeSelector from '@/VueComponents/DynamicForm/FormTypeSelector';
import ResponseFormDefinitionRepository from '@/Repositories/responseFormDefinitionRepository';
import ItemsLoadingSpinner from '@/VueComponents/Loading/ItemsLoadingSpinner.vue';
import AutoComplete from '@/VueComponents/SharedComponents/AutoComplete.vue';
import RequestAssigneeFormatter from '@/Utils/requestAssigneeFormatter';
import { SelfResponsePriority } from '@/Types/Enums/selfResponsePriority';
import resourceHelper from '@/Utils/resourceHelper';
import localStorageHelper, { LocalStorageKeyType } from '@/Utils/localStorageHelper';
import { SelfResponseDefinitionSummaryModel } from '@/Models/selfResponseDefinitionSummaryModel';


const emit = defineEmits<{ 'close' }>();
const showFormSelector = ref(false);
const showFormContent = ref(false);
const selectedResponseType: Ref<SelfResponseDefinitionSummaryModel | undefined> = ref(undefined);
const availableResponseTypes: Ref<SelfResponseDefinitionSummaryModel[]> = ref([]);
// form variables
const selectedAssignee = ref(null);
const selectedPriority = ref(SelfResponsePriority.medium);
const responseTypesLoading = ref(true);
const internalReferenceNumber = ref(null);
const isCreating = ref(false);
const assignees = ref(null);
const dynamicResponseFormDefinition = ref(null);
const dynamicResponseFormData = ref(null);
const dynamicResponseFormId = ref(null);
const dynamicResponseFormVersion = ref(null);
const dynamicResponseUploadGuidance = ref('');
const dynamicResponseConfirmationText = ref('');
const filteredAssignees = ref([]);
const selectedCadIds: Ref<string[]> = ref([]);
const selectedRmsId = ref('');
const rmsIdComponent = ref(null);
const cadIdsComponent = ref(null);
const showValidationErrors = ref(false);
const createErrorMessage = ref('');

let isFormValid = true;
onMounted(async () => {
  await getResponseFormsTypes();
});

function searchAssignee(event) {
  setTimeout(() => {
    if (!event.query.trim().length) {
      filteredAssignees.value = [...assignees.value];
    } else {
      filteredAssignees.value = assignees.value.filter(a => {
        return a.name.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }
  }, 250);
}

function getResponseModel(): SelfResponseModel {
  const assignedGroupId = selectedAssignee.value?.assigneeType === constants.requestAssigneeTypes.group ? selectedAssignee.value.id : null;
  const assignedPersonaId = selectedAssignee.value?.assigneeType === constants.requestAssigneeTypes.user ? selectedAssignee.value.id : null;

  return {
    assignedGroupId: assignedGroupId,
    assignedPersonaId: assignedPersonaId,
    businessId: contextData.userData.business.businessId,
    dynamicResponseFormDefinition: JSON.stringify(dynamicResponseFormDefinition.value),
    dynamicResponseFormData: dynamicResponseFormData.value ? JSON.stringify(dynamicResponseFormData.value) : null,
    internalReferenceNumber: internalReferenceNumber.value,
    referenceNumber: internalReferenceNumber.value,
    creatorPersonaId: contextData.userData.personaId,
    dynamicResponseFormId: dynamicResponseFormId.value,
    dynamicResponseFormVersion: dynamicResponseFormVersion.value,
    dynamicResponseUploadGuidance: dynamicResponseUploadGuidance.value,
    dynamicResponseConfirmationText: dynamicResponseConfirmationText.value,
    responsePriority: selectedPriority.value,
    rmsId: selectedRmsId.value,
    cadIds: selectedCadIds.value
  };
}

const isInternalReferenceNumberExceedsMaxLength = computed(() => {
  return internalReferenceNumber.value?.trim().length > 50;
});

function isModalValid() {
  return isFormValid && cadIdsComponent.value.isValid() && rmsIdComponent.value.isValid() && !isInternalReferenceNumberExceedsMaxLength.value;
}

function validateAssignee() {
  if (!selectedAssignee.value) {
    return true;
  }

  if (selectedAssignee.value?.assigneeType === constants.requestAssigneeTypes.group &&
    !selectedAssignee.value?.isGroupContactActive) {
    logger.warning('GroupNeedsRegisteredContact');
    return false;
  }

  return true;
}

function onFormChange(data, isValid) {
  isFormValid = isValid;
}

async function onCreateResponse() {

  if (!selectedResponseType.value) {
    createErrorMessage.value = resourceHelper.getString('UploadFolderFormNotSelected');
    showValidationErrors.value = true;
    return;
  }

  if (!isModalValid()) {
    return;
  }

  if (!validateAssignee()) {
    return;
  }

  showValidationErrors.value = false;
  isCreating.value = true;

  try {
    const response = await SelfResponseRepository.createResponse(getResponseModel());
    localStorageHelper.setSessionValue(LocalStorageKeyType.UploadFoldersPage, String(1));
    redirectHelper.redirectToPath(`#uploadfolderdetails/${response.id}`);
    logger.success('UploadFolderCreated');
  } catch (error) {
    logger.error('UploadFolderCreationFailed');
  } finally {
    isCreating.value = false;
  }
}

async function onResponseTypeSelected(typeId: string) {
  selectedResponseType.value = availableResponseTypes.value.find(x => x.id === typeId);
  await getCustomResponseForm();
  showFormContent.value = true;
  showValidationErrors.value = false;
}

async function getResponseFormsTypes() {
  const definitions = await ResponseFormDefinitionRepository.getResponseDefinitions();
  availableResponseTypes.value = definitions;
  if (definitions.length == 1) {
    selectedResponseType.value = definitions[0];
    onResponseTypeSelected(definitions[0].id);
  }
  showFormSelector.value = definitions.length > 1;
  responseTypesLoading.value = false;
}

async function getCustomResponseForm() {
  if (selectedResponseType.value) {
    const form = await ResponseFormDefinitionRepository.getResponseFormById(selectedResponseType.value.id);
    dynamicResponseFormDefinition.value = JSON.parse(form.definition);
    dynamicResponseFormId.value = form.formId;
    dynamicResponseFormVersion.value = form.version;
    dynamicResponseUploadGuidance.value = selectedResponseType.value.uploadGuidance;
    dynamicResponseConfirmationText.value = selectedResponseType.value.confirmationText;
  }
}

function onModalClose() {
  isCreating.value = false;
  emit('close');
}

onMounted(async () => {
  assignees.value = await businessRepository.getRequestAssigneeSummaries();
});

</script>

<style lang="scss" scoped>
@import "sass/site/_colours.scss";

.results-status {
  border: none;
}

.form-control.has-error{
  border-color: $validation-error-color;
  &:focus {
    border-color: $validation-error-color;
    box-shadow: $input-error-box-shadow;
  }
}

.error-text {
    color: $validation-error-color;
    margin-bottom: 0px;
}
</style>
