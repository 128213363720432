<template>
  <!--Wrapper around PrimeVue AutoComplete component https://primevue.org/autocomplete/ -->
  <AutoComplete
    ref="autocompleteRef"
    v-model="model"
    :force-selection="forceSelection"
    :dropdown="dropdown ? true : false "
    :suggestions="options"
    :option-label="optionLabel"
    :class="{'disabled': disabled}"
    :disabled="disabled"
    :option-disabled="optionDisabled"
    :placeholder="placeholder"
    :input-props="inputProps"
    :complete-on-focus="completeOnFocus"
    :multiple="multiple"
    :panel-style="{ width: overlayPanelWidth }"
    @complete="search"
    @click="click"
    @item-select="itemSelectHandler"
    @item-unselect="itemUnselectHandler"
    @before-show="setOverlayWidth"
    @blur="onBlur"
    @show="onShow"
    @hide="onHide"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
    <template #dropdownicon>
      <i
        class="fas fa-chevron-down dropdown-icon"
        :class="{rotate: autocompleteRef?.overlayVisible}"
      />
    </template>

    <template #loadingicon>
      <i
        class="fas fa-circle-notch fa-spin dropdown-spinner"
        aria-hidden="true"
      />
    </template>
  </AutoComplete>
</template>

<script setup lang="ts">
import { ref, InputHTMLAttributes } from 'vue';
import AutoComplete from 'primevue/autocomplete';
import { AutoCompleteCompleteEvent } from 'primevue/autocomplete';

const autocompleteRef = ref(null);
const props = withDefaults(defineProps<{
        options: any[],
        optionLabel?: string | null,
        dropdown: boolean,
        disabled: boolean,
        placeholder: string,
        optionDisabled?: string | null,
        completeOnFocus?: boolean,
        inputProps?: InputHTMLAttributes | null,
        multiple?: boolean,
        overlayZIndex?: string | null,
        forceSelection?: boolean
}>(), { forceSelection: true, optionLabel: null, optionDisabled: null, inputProps: null, overlayZIndex: null });
const emit = defineEmits<{ 'search', 'item-select', 'item-unselect', 'blur', 'show', 'hide', 'change' }>();

const model = defineModel<any>(); // TODO: Review if this type can be made more specific
const overlayPanelWidth = ref('');

const search = event => {
  emit('search', event);
};

const click = () => {
  // avoid triggering dropdown click event twice
  if (autocompleteRef?.value.overlayVisible) {
    return;
  }
  autocompleteRef.value.onDropdownClick();

};

function itemSelectHandler(event: AutoCompleteCompleteEvent) {
  emit('item-select', event);
}

function itemUnselectHandler(event: AutoCompleteCompleteEvent) {
  emit('item-unselect', event);
}

function onBlur(event) {
  emit('blur', event);
}

function onHide(event) {
  emit('hide', event);
}

function setOverlayWidth() {
  const inputElementWidth = (autocompleteRef.value.$el as HTMLElement).clientWidth;
  overlayPanelWidth.value = `${inputElementWidth}px`;
}

function onShow() {
  if (props.overlayZIndex) {
    (autocompleteRef.value.overlay as HTMLElement).style.zIndex = props.overlayZIndex;
  }
  emit('show', event);
}
</script>

<style>
</style>