import ko from 'knockout';
import router from '../../Utils/router';
import template from './routeInfo.html';

export function ViewModelInfoViewModel(params) {

  const self = this;

  self.expanded = params.expanded;
  self.json = ko.pureComputed(() => ko.toJSON(router.currentRoute, null, 2));
}

// The default export returns the component details object to register with KO
export default { viewModel: ViewModelInfoViewModel, template: template };
