<template>
  <div class="content-container">
    <h2>
      <span data-bind="restext: 'Requests'">{{ $localize('Requests') }}</span>
      <span
        v-if="paginationOptions.total"
        class="font-size-smaller"
      >
        ({{ paginationOptions.total }})
      </span>
    </h2>
    <p class="lead">
      {{ $localize('RequestsLeadText') }}
    </p>
    <div class="requests">
      <!-- Left area for the filter panel -->
      <div class="requests__filter-panel">
        <div class="filter-panel__heading-container">
          <div class="filter-panel__heading">
            {{ $localize('RequestFilterTitle') }}
          </div>
          <a
            href="#"
            @click.prevent.stop="resetFilterCriteriaToDefault"
          >{{ $localize('ResetAll') }}</a>
        </div>
        <vue-filter-panel
          ref="filter"
          :initial-filter="initialFilter"
          :local-storage-key="LocalStorageKeyType.RequestsFilterCriteria"
          :refine-by-tooltip="$localize('RefineByTooltip')"
          @filter-change-handler="filterChangeHandler"
        />
      </div>

      <div class="requests__main-content">
        <RequestList
          v-model:selected-page="paginationOptions.currentPage"
          v-model:page-size="paginationOptions.pageSize"
          :page-size-options="paginationOptions.pageSizeOptions"
          :requests="requests"
          :loading-error-message="$localize('FailedToRetrieveRequests')"
          :loading="loading"
          :display-loading-error="displayLoadingError"
          :pagination-total-items="paginationOptions.total"
          :sorting-options="sortingOptions.options"
          :sorting-default-order="sortingOptions.sortOrder"
          :sorting-default-value="sortingOptions.sortField"
          :last-accessed-request-id="previousRequestId"
          @on-sort="sortingOptions.onSort($event)"
          @page-changed="paginationOptions.onPageChanged()"
          @page-size-changed="paginationOptions.onPageSizeChanged()"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { nextTick, reactive, ref } from 'vue';
import contextData from '@/contextData';
import { LocalStorageKeyType } from '@/Utils/localStorageHelper';
import requestSummaryRepository from '@/Repositories/requestSummaryRepository';
import logger from '@/Utils/logger';
import resourceHelper from '@/Utils/resourceHelper';
import VueFilterPanel from '@/VueComponents/Filter/VueFilterPanel.vue';
import { InitialFilterCriteriaDto } from '@/Components/filterPanel/Models/initialFilterCriteriaDto';
import RequestList from '@/VueComponents/Requests/RequestList/RequestList';
import SortOptionsManager from '@/VueComponents/SharedComponents/SortDropdown/utils/sortOptionsManager';
import PaginationOptionsManager
  from '@/VueComponents/PaginatedTable/TablePageNavigation/utils/paginationOptionsManager';

const props = defineProps<{
  initialFilter: InitialFilterCriteriaDto,
  previousRoute,
  usePreviousViewSettings: boolean
}>();
const previousRequestId = ref(props.previousRoute?.id);

const requests = ref([]);

const loading = ref(false);
const displayLoadingError = ref(false);

const sortingOptions = reactive(new SortOptionsManager(
    [
      { field: 'RequestTimestamp', label: resourceHelper.getString('DateCreated') },
      { field: 'RequiredByTimestamp', label: resourceHelper.getString('RequiredByDate') },
      { field: 'RequestPriority', label: resourceHelper.getString('RequestPriority') },
      { field: 'InvestigatorName', label: resourceHelper.getString('RequestorName') }
    ],
    LocalStorageKeyType.RequestsSorting,
    () => {
      paginationOptions.currentPage = 1;
      loadData();
    },
    'Desc'
));

const paginationOptions = reactive(new PaginationOptionsManager(
    contextData.portalSettings.paginationPageSizeOptions,
    LocalStorageKeyType.RequestsPage,
    LocalStorageKeyType.PaginationRequestsPageSize,
    () => {
      loadData();
    },
    () => {
      if (paginationOptions.pageSize > paginationOptions.total && requests.value.length === paginationOptions.total) {
        return;
      }

      loadData();
    },
    { page: props.usePreviousViewSettings, pageSize: true }
));

const filter = ref(null);

nextTick(() => {
  loadData();
});


function loadData() {
  const filterCriteria = filter.value.getSelectedFilterCriteria();

  loading.value = true;

  requestSummaryRepository.getRequests(
      filterCriteria,
      sortingOptions.sortField,
      sortingOptions.sortOrder,
      paginationOptions.currentPage,
      previousRequestId.value,
      paginationOptions.pageSize)
      .then(function (pageResult) {
        loading.value = false;
        requests.value = pageResult.results;

        paginationOptions.pageSize = pageResult.pageSize;
        paginationOptions.total = pageResult.total;

        filter.value?.updateFilterCriteria(pageResult.filterCriteria);
      })
      .catch(function (jqXhr) {
        loading.value = false;
        displayLoadingError.value = true;

        if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnexpectedErrorWhileRetrievingRequests', null, jqXhr);
        }
      });
}

function resetFilterCriteriaToDefault() {
  filter.value.applyDefaultFilterCriteria();
  filterChangeHandler();
}

function filterChangeHandler() {
  paginationOptions.currentPage = 1;

  loadData();
}
</script>