import inactivityHelper from '../../Utils/inactivityHelper';
import template from './inactivityInfo.html';

export function InactivityInfoViewModel() {

  const self = this;

  self.idlePeriodInMinutes = inactivityHelper.idlePeriodInMinutes;
  self.showInactiveWarning = inactivityHelper.showInactiveWarning;
  self.isInactive = inactivityHelper.isInactive;
}

// The default export returns the component details object to register with KO
export default { viewModel: InactivityInfoViewModel, template: template };
