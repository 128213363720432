import ko from 'knockout';
import logger from '../../Utils/logger';
import redirectHelper from '../../Utils/redirectHelper';
import ValidationRulesService from '../../Validation/validationRulesService';
import usersRepository from '../../Repositories/usersRepository';
import groupsRepository from '../../Repositories/groupsRepository';
import contextData from '../../contextData';
import constants from '../../constants';
import AccountSpecificLabelsProvider from '@/Utils/accountSpecificLabelsProvider';
import { defineReactiveProps } from '@/VueCore/utils/vueAppBuilder';

import template from './businessUserDetails.html';

export function BusinessUserDetailsViewModel(params) {
  const self = this;

  // Editable form fields:
  self.givenName = ko.observable();
  self.familyName = ko.observable();
  self.emailAddress = ko.observable();
  self.phoneNumber = ko.observable();
  self.isBusinessAdminUser = ko.observable();
  self.isBusinessPrimaryContact = ko.observable();
  self.businessUserGroupSelections = ko.observable();
  self.businessHasGroups = ko.pureComputed(() => {
    return this.businessUserGroupSelections() && this.businessUserGroupSelections().length === 0;
  });

  // Init validation rules, errors, flags:
  self.isAddNewUserMode = params.pageMode === constants.pageMode.add;
  self.showDeletePrompt = ko.observable(false);
  self.showAdminSelfRemovePrompt = ko.observable(false);
  self.adminSelfRemoveMessage = ko.pureComputed(() => {
    return this.isAuthenticatedUserSelected() ? 'BusinessUserSelfDeleteModalMessage' : 'BusinessUserDeleteModalMessage';
  });

  self.isLoadingFormData = ko.observable(false);
  self.userEditSubmitted = ko.observable(false);
  self.userDeleteSubmitted = ko.observable(false);
  self.serverErrors = ko.observableArray();
  self.clientErrors = ko.validation.group(self, { deep: true });
  const validationRulesService = new ValidationRulesService(self);
  validationRulesService.applyValidation(params.validationRules);

  // Init business Id and persona Id, etc parameters, change events:
  self.selectedItemBusinessId = contextData.userData.business.businessId;
  self.selectedItemIdObservable = params.selectedItemId ? params.selectedItemId : ko.observable(null);
  self.updateUserFunc = params.updateUserFunc;
  self.deleteUserFunc = params.deleteUserFunc;

  self.businessUserDetailsPrimaryContactLabel = AccountSpecificLabelsProvider.getAccountLabelKey('BusinessUserDetailsPrimaryContactLabel');
  self.businessUserDetailsIsPrimaryContactCheckboxExplanatoryText =
    AccountSpecificLabelsProvider.getAccountLabelKey('BusinessUserDetailsIsPrimaryContactCheckboxExplanatoryText');
  self.businessUserDetailsIsAdminCheckboxExplanatoryText =
    AccountSpecificLabelsProvider.getAccountLabelKey('BusinessUserDetailsIsAdminCheckboxExplanatoryText');

  if (self.isAddNewUserMode !== true) {
    // Subscribing to changes in selected summary list item for edit existing user,
    // fetching init data for existing user
    self.selectedItemIdObservable.subscribe(function (newValue) {
      if (self.selectedItemIdObservable() !== null) {
        initUserDetails(self.selectedItemBusinessId, self.selectedItemIdObservable());
      }
    });
    if (self.selectedItemIdObservable() !== null) {
      initUserDetails(self.selectedItemBusinessId, self.selectedItemIdObservable());
    }
  } else {
    // Fetching init data for new user
    initUserGroups(self.selectedItemBusinessId);
  }

  self.isAuthenticatedUserSelected = ko.pureComputed(() => {
    return self.selectedItemIdObservable() === contextData.userData.personaId;
  });

  // Returns a flag indicating whether selected user is the one that is currently logged in and admin rights is unchecked
  self.isAuthenticatedUserAdminRightsUncheckedSelected = function () {
    return self.isAuthenticatedUserSelected() && self.isBusinessAdminUser() === false;
  };

  self.showGroupSaveWarningModal = ko.observable(false);

  self.isPreregistered = ko.observable();

  self.groupSaveWarningModalProps = defineReactiveProps({
    preRegisteredUsersAssignedCount: 1,
    onClose: () => {
      self.showGroupSaveWarningModal(false);
    },
    onSave: () => {
      self.showGroupSaveWarningModal(false);
      if (self.isAuthenticatedUserAdminRightsUncheckedSelected()) {
        self.showAdminSelfRemovePrompt(true);
        return;
      }

      self.updateUserConfirmed();
    }
  });

  self.updateClickHandler = () => {

    if (self.businessUserGroupSelections()) {
      const assignedToEmptyGroup = self.businessUserGroupSelections()
          .some(x => x.groupIsSelected() && x.groupIsEmpty);
      if (assignedToEmptyGroup && self.isPreregistered()) {
        self.showGroupSaveWarningModal(true);
        return;
      }
    }

    if (self.isAuthenticatedUserAdminRightsUncheckedSelected()) {
      self.showAdminSelfRemovePrompt(true);
      return;
    }

    self.updateUserConfirmed();
  };

  self.updateUserConfirmed = function () {
    self.serverErrors([]);
    self.showAdminSelfRemovePrompt(false);
    if (self.clientErrors().length > 0) {
      self.clientErrors.showAllMessages(true);
      return;
    }

    self.userEditSubmitted(true);

    const itemIdToUpdate = self.selectedItemIdObservable();
    usersRepository.updateBusinessUserDetails(self.selectedItemBusinessId, itemIdToUpdate, buildUserUpdateDto())
        .then(function (serverUserDetailsDto) {
        // Unset submit wait state:
          self.userEditSubmitted(false);

          // Update data after operation according to newly confirmed server data:
          mapUserDetails(serverUserDetailsDto);
          updateCurrentUserContextData(serverUserDetailsDto);
          if (self.updateUserFunc) {
            self.updateUserFunc(serverUserDetailsDto);
          }

          // Show success toaster:
          logger.success('BusinessUserUpdatedSummary');

          // Refresh and reset the page if current user is not admin anymore:
          if (itemIdToUpdate === contextData.userData.personaId && !self.isBusinessAdminUser()) {
            redirectHelper.redirectToHash('#requests');
          }
        })
        .catch(function (jqXhr) {
        // Unset submit wait state:
          self.userEditSubmitted(false);

          // Show specific server errors if any:
          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
            return;
          }

          // Log generic error:
          if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnexpectedErrorWhileUpdatingUserDetails', null, jqXhr);
          }
        });
  };

  self.createUser = function () {
    self.serverErrors([]);
    if (self.clientErrors().length > 0) {
      self.clientErrors.showAllMessages(true);
      return;
    }

    self.userEditSubmitted(true);

    usersRepository.createBusinessUser(self.selectedItemBusinessId, buildUserCreateDto())
        .then(function (serverResponseDto) {
        // Unset submit wait state:
          self.userEditSubmitted(false);

          // Show success toaster:
          logger.success('BusinessUserCreatedSummary');

          // Redirect to users after user is created:
          redirectHelper.redirectToHash('#users');
        })
        .catch(function (jqXhr) {
        // Unset submit wait state:
          self.userEditSubmitted(false);

          // Show specific server errors if any:
          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
            return;
          }

          // Log generic error:
          if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnexpectedErrorWhileCreatingNewUser', null, jqXhr);
          }
        });
  };

  self.deleteUser = function () {
    self.showDeletePrompt(true);
  };

  self.deleteUserConfirmed = function () {
    self.serverErrors([]);
    self.showDeletePrompt(false);
    self.userDeleteSubmitted(true);

    const itemIdToDelete = self.selectedItemIdObservable();
    usersRepository.deleteBusinessUser(self.selectedItemBusinessId, itemIdToDelete)
        .then(function (serverUserDetailsDto) {
        // Unset submit wait state:
          self.userDeleteSubmitted(false);

          // Update data after operation:
          if (self.deleteUserFunc) {
            self.deleteUserFunc(itemIdToDelete);
          }

          // Show success toaster:
          logger.success('BusinessUserDeletedSummary');

          // Logout if current user is removed:
          if (itemIdToDelete === contextData.userData.personaId) {
            redirectHelper.logoutRedirect();
          }
        })
        .catch(function (jqXhr) {
        // Unset submit wait state:
          self.userDeleteSubmitted(false);

          // Show specific server errors if any:
          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
            return;
          }

          // Log generic error:
          if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnexpectedErrorWhileDeletingUser', null, jqXhr);
          }
        });
  };

  // Inits existing user details form data from server
  function initUserDetails(businessId, personaId) {
    self.serverErrors([]);
    self.isLoadingFormData(true);

    usersRepository.getUserDetails(businessId, personaId)
        .then(serverUserDetailsDto => {
          if (self.selectedItemIdObservable() !== personaId) {
          // Dto from server is outdated as selection already changed, ignore this dto
            return;
          }

          // Unset loading wait state:
          self.isLoadingFormData(false);

          // Update data after operation:
          mapUserDetails(serverUserDetailsDto);
        })
        .catch(function (jqXhr) {
        // Unset submit wait state:
          self.userDeleteSubmitted(false);

          // Log generic error:
          if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnexpectedErrorWhileGettingUserDetails', null, jqXhr);
          }
        });
  }

  // Inits user groups data for new user
  function initUserGroups(businessId) {
    self.serverErrors([]);
    groupsRepository.getAllGroupNames(businessId)
        .then(serverUserGroupsDto => {
          mapUserGroups(serverUserGroupsDto);
        })
        .catch(function (jqXhr) {
        // Log generic error:
          if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnexpectedErrorWhileGettingUserGroups', null, jqXhr);
          }
        });
  }

  // Maps server user details dto to viewmodel
  function mapUserDetails(serverUserDetailsDto) {
    self.givenName(serverUserDetailsDto.givenName);
    self.familyName(serverUserDetailsDto.familyName);
    self.emailAddress(serverUserDetailsDto.emailAddress);
    self.phoneNumber(serverUserDetailsDto.phoneNumber);
    self.isBusinessAdminUser(serverUserDetailsDto.isBusinessAdminUser);
    self.isBusinessPrimaryContact(serverUserDetailsDto.isBusinessPrimaryContact);
    self.isPreregistered(serverUserDetailsDto.isPreregistered);
    self.businessUserGroupSelections(serverUserDetailsDto.businessUserGroupSelections
        .map(x => {
          return {
            groupId: x.groupId,
            name: x.name,
            groupIsSelected: ko.observable(x.groupIsSelected),
            groupIsEmpty: x.groupIsEmpty
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)));
  }

  // Maps server user group dto collection to viewmodel object
  function mapUserGroups(serverGroupsDto) {
    self.businessUserGroupSelections(serverGroupsDto
        .map(x => {
          return {
            groupId: x.groupId,
            name: x.name,
            groupIsSelected: ko.observable(false),
            groupIsEmpty: x.groupIsEmpty
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name)));
  }

  // Builds update user details dto to submit to server
  function buildUserUpdateDto() {
    return {
      givenName: self.givenName(),
      familyName: self.familyName(),
      phoneNumber: self.phoneNumber(),
      isBusinessAdminUser: self.isBusinessAdminUser(),
      isBusinessPrimaryContact: self.isBusinessPrimaryContact(),
      businessUserGroupSelections: self.businessUserGroupSelections()
          .filter(x => x.groupIsSelected())
          .map(x => {
            return {
              groupId: x.groupId,
              groupIsSelected: x.groupIsSelected()
            };
          })
    };
  }

  // Builds create new user dto to submit to server
  function buildUserCreateDto() {
    return {
      ...buildUserUpdateDto(),
      emailAddress: self.emailAddress()
    };
  }

  // Updates context data of currently logged in user is their data is changed
  function updateCurrentUserContextData(serverUserDetailsDto) {
    if (contextData.userData.personaId !== serverUserDetailsDto.personaId) {
      return;
    }

    contextData.userData.givenName(serverUserDetailsDto.givenName);
    contextData.userData.familyName(serverUserDetailsDto.familyName);
    contextData.userData.displayName(serverUserDetailsDto.givenName + ' ' + serverUserDetailsDto.familyName);
    contextData.userData.phoneNumber(serverUserDetailsDto.phoneNumber);
    contextData.userData.isBusinessAdminUser = serverUserDetailsDto.isBusinessAdminUser;
  }
}

export default { viewModel: BusinessUserDetailsViewModel, template: template };
