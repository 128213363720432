import ko from 'knockout';
import contextData from '@/contextData';
import fileSizeFormatter from '@/Utils/fileSizeFormatter';
import uploadFileLimitResolver from '@/Utils/uploadFileLimitResolver';
import logger from '@/Utils/logger';
import FileListConverter from '@/Utils/fileListConverter';

import template from './fileUploadSelector.html';
import { defineReactiveProps } from '@/VueCore/utils/vueAppBuilder';

export function FileUploadSelectorViewModel(params) {

  const self = this;
  const getDuplicatedFilesCallback = params.getDuplicatedFilesCallback;
  const validateFileUploadsCallback = params.validateFileUploadsCallback;
  const addUploadsToRepositoryCallback = params.addUploadsToRepositoryCallback;
  const getUploadLimitCallback = params.getUploadLimitCallback;
  const uploadParentItemType = params.uploadParentItemType;
  let cachedCctvFolderFileList = [];

  self.maximumUploadSize = contextData.portalSettings.maximumUploadSize;

  self.formattedMaximumUploadSize =
    fileSizeFormatter.getFormattedFileSize(contextData.portalSettings.maximumUploadSize);

  self.maximumCctvFolderUploadSize = contextData.portalSettings.maximumCctvFolderUploadSize;

  self.formattedMaximumCctvFolderUploadSize =
    fileSizeFormatter.getFormattedFileSize(contextData.portalSettings.maximumCctvFolderUploadSize);

  self.dynamicCctvFolderExportExplanatoryText = params.dynamicCctvFolderExportExplanatoryText;
  self.isDirectorySupported = isDirectorySupported();

  self.isCctvUploadModalVisible = ko.observable(false);

  self.limitNumberOfUploads = uploadFileLimitResolver.getUploadFileLimitByRequestType(uploadParentItemType);

  self.showDuplicatedUploadsModal = ko.observable(false);
  self.duplicatedUploadsModalProps = defineReactiveProps({
    duplicatedFiles: [],
    onClose() {
      self.showDuplicatedUploadsModal(false);
    },
    onContinue() {

    },
    onSkipDuplicates() {

    }
  });

  self.showUploadLimitModal = ko.observable(false);
  self.uploadLimitModalProps = defineReactiveProps({
    limitNumberOfUploads: 0,
    existedUploads: 0,
    newUploads: 0,
    totalUploads: 0,
    uploadParentItemType: uploadParentItemType,
    onClose() {
      self.showUploadLimitModal(false);
    }
  });

  self.addFileUploads = fileList => {
    const isValid = validateFileUploadsCallback(fileList);
    if (!isValid) {
      return;
    }

    const limitInfo = getUploadLimitCallback(fileList);
    if (self.isLimitExceeded(limitInfo)) {
      self.uploadLimitModalDisplay(limitInfo);
    } else {
      const files = FileListConverter.fileListToArray(fileList);
      const sortedFiles = getDuplicatedFilesCallback(files);
      if (sortedFiles.duplicates.length) {
        self.showDuplicatedUploadsModal(true);

        setDuplicatedUploadsModalOptions(files, sortedFiles);
      } else {
        addUploadsToRepositoryCallback(fileList);
      }
    }
  };

  self.confirmCctvFolderUploads = fileList => {
    const isCctvFolderUploadValid = validateFileUploadsCallback(fileList);
    if (!isCctvFolderUploadValid) {
      return;
    }
    const limitInfo = getUploadLimitCallback(fileList);
    if (self.isLimitExceeded(limitInfo)) {
      self.uploadLimitModalDisplay(limitInfo);
    } else {
      cacheCctvFolderFiles(fileList);
      self.isCctvUploadModalVisible(true);
    }
  };

  self.processCctvFolderUpload = () => {
    addUploadsToRepositoryCallback(cachedCctvFolderFileList);
    self.isCctvUploadModalVisible(false);
  };

  self.uploadLimitModalDisplay = limitInfo => {
    self.showUploadLimitModal(true);
    setUploadLimitModalOptions(limitInfo);
  };

  self.isLimitExceeded = limitInfo => {
    return limitInfo.totalUploads > limitInfo.uploadFileLimitByRequestType;
  };

  function cacheCctvFolderFiles(fileList) {
    cachedCctvFolderFileList = [];
    Array.from(fileList)
        .forEach(file => {
          cachedCctvFolderFileList.push(file);
        });
  }
  function isDirectorySupported() {
    const tmpInput = document.createElement('input');
    if ('webkitdirectory' in tmpInput ||
      'mozdirectory' in tmpInput ||
      'odirectory' in tmpInput ||
      'msdirectory' in tmpInput ||
      'directory' in tmpInput) {
      return true;
    }

    return false;
  }

  function setDuplicatedUploadsModalOptions(files, sortedFiles) {
    self.duplicatedUploadsModalProps.duplicatedFiles = sortedFiles.duplicates;
    self.duplicatedUploadsModalProps.onContinue = () => {
      self.showDuplicatedUploadsModal(false);
      addUploadsToRepositoryCallback(files);
    };
    self.duplicatedUploadsModalProps.onSkipDuplicates = () => {
      self.showDuplicatedUploadsModal(false);
      if (sortedFiles.notDuplicates.length) {
        addUploadsToRepositoryCallback(sortedFiles.notDuplicates);
      } else {
        logger.info('UploadsAreDuplicatesAndSkipped');
      }
    };
  }

  function setUploadLimitModalOptions(limitInfo) {
    self.uploadLimitModalProps.limitNumberOfUploads = limitInfo.uploadFileLimitByRequestType;
    self.uploadLimitModalProps.existedUploads = limitInfo.existedUploads;
    self.uploadLimitModalProps.newUploads = limitInfo.newUploads;
    self.uploadLimitModalProps.totalUploads = limitInfo.totalUploads;
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: FileUploadSelectorViewModel, template: template };
